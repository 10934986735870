/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { Form, Table } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars-2';
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { SALES_PERSON_PERFORMANCE_URL } from "../Shared/constant";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SalesPersonPerformanceNew = () => {
    const { websocket } = useContext(WebSocketContext);
    const { device_id, userSalesFranchiseAllList, AllDealerFranchiseTypeListAll, franchiseSchemeAllList, ClearFormSet } = useSelector((state) => state.adminReducers);
    const optionSaleFranchise = userSalesFranchiseAllList?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.full_name}));
    const optionSchemeList = franchiseSchemeAllList?.map((item) => ({...item, key: item.scheme_code, value: item.scheme_code, label: `${item.scheme_name} (${item.scheme_code})`}))
    const OptionFranchiseArray = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.parent_id === null)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.customer_name }));
    const [ UpdateArrayForCSVfile, setUpdateArrayForCSVfile ] = useState([]);
    const Navigate = useNavigate();
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const [ Franchise, setFranchise ] = useState("");

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }),
        multiValue: (provided) => ({
            ...provided,
            fontSize: '13px',  // Set your desired font size here
            maxWidth: 100,  // Adjust the width as needed
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontSize: '13px',  // Set the font size of the label inside each selected tag
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            fontSize: '13px',  // Set the font size of the remove icon if needed
        }),
        valueContainer: (base) => ({
            ...base,
            overflow: "auto",
            maxHeight: "62px"
        }),
    };

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_scheme_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : "",
                "start_date": "",
                "end_date": "",
                "order_by" :"-updated_at"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get category all
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "user_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "user_type" : "sale_franchise_person",
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select franchise
    const FranchiseName = (value) => {
        if(value !== null) {
            setFranchise(value);
        } else {
            setFranchise("");
        };
    };

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };
    
    let saleFranchiseUser = [];
    const AddselectSaleFranchise = () => {
        Franchise?.filter((elm) => {
            optionSaleFranchise?.filter((elmz) => {
                if(elm?.id === elmz?.related_id) {
                    saleFranchiseUser?.push({
                        ...elmz,
                        franchise_customer_name: elm?.customer_name,
                        id_uniq: generateRandomId(),
                        scheme_code: "",
                        target: "",
                    });
                };
            });
        });
        
        if(saleFranchiseUser?.length > 0) {
            setUpdateArrayForCSVfile(prevArray => [...prevArray, ...saleFranchiseUser]);
            setFranchise("");
        } else {
            toast.error("The selected franchise does not have a mapped sale franchise person. Please check the actual sale franchise person list.")
        };
    };
    
    // select scheme function
    const SelectSchemeOnchange = (value, url, id_uniq) => {
        const UpdateSaleFranchise = UpdateArrayForCSVfile?.map((elm) => {
            if(elm?.id_uniq === id_uniq) {
                if(value !== null) {
                    return{
                        ...elm,
                        [url]: value
                    }
                } else {
                    return{
                        ...elm,
                        [url]: ""
                    }
                };
            } else {
                return elm;
            }
        })
        setUpdateArrayForCSVfile(UpdateSaleFranchise);
    };

    const deleteTargetArray = [];
    // delete monthly target
    const DeleteMonthlyTarget = (id_uniq) => {
        UpdateArrayForCSVfile?.map((elm) => {
            if(elm?.id_uniq !== id_uniq) {
                return deleteTargetArray?.push(elm);
            };
        })
        setUpdateArrayForCSVfile(deleteTargetArray);
    };

    // same user wise scheme code hide and show
    const optionSchemeListUpdate = (data) => {
        const checkSameUser = UpdateArrayForCSVfile?.filter((elm) => elm?.id === data?.id && elm?.scheme_code !== null && elm?.scheme_code !== "")
        const schemeCodeExit = checkSameUser?.map((elm) => elm?.scheme_code?.id);
        const schemeList = optionSchemeList?.filter((elmz) => !schemeCodeExit.includes(elmz?.id));
        return schemeList;
    };
      
    // download csv file
    const downloadCSVFile = () => {
        const UpdateSaleFranchiseList = UpdateArrayForCSVfile?.map((elm) => {
            return {
                sales_name: elm?.full_name,
                sales_id: elm?.id,
                email: elm?.email,
                franchise_name: elm?.franchise_customer_name,
                scheme_name: elm?.scheme_code?.scheme_name,
                scheme_code: elm?.scheme_code?.scheme_code,
                target: elm?.target,
            };
        });

        // Convert the array to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(UpdateSaleFranchiseList);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SalesData");

        // Generate the Excel file and trigger a download in the browser
        XLSX.writeFile(workbook, "monthly_target.xlsx");
    };

    // clear data
    const PurchaseOrderClear = () => {
        setFranchise("");
        setUpdateArrayForCSVfile([]);
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "import_sales_scheme_target") {
            Navigate(SALES_PERSON_PERFORMANCE_URL);
        };
    }, [ClearFormSet]);
    
    const DownloadBtnDisable = UpdateArrayForCSVfile?.length === 0 || !UpdateArrayForCSVfile?.every((elm) => elm?.scheme_code !== "" && elm?.target !== "");

    const oldValues = UpdateArrayForCSVfile.map(obj => obj.related_id);
    const UpdateFranchiseOptions = OptionFranchiseArray?.filter(obj => !oldValues.includes(obj.id));

    return(<section className="Main_section sales_person_perfornamce_wrapper">
        <div className="filtr_Form Purchase_Order_Mendetory" style={{ borderBottom: "1px solid #eee" }}>
            <div className="row">
                <div className="col-md-4">
                    <Form.Group className="mb-3">
                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                            <Select
                                value={Franchise}
                                onChange={(e) => FranchiseName(e)}
                                isClearable
                                options={UpdateFranchiseOptions}
                                className="godown-select-container" 
                                classNamePrefix="godown-select"
                                placeholder="Select franchise"
                                styles={customStyles}
                                isMulti
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-5">
                    <button className="btn addSalerperformBtn" disabled={!Franchise || Franchise?.length === 0} onClick={() => AddselectSaleFranchise()}>
                        Add
                    </button>
                    <button className="btn addSalerperformBtn salespersionClearbtn" style={{ marginLeft: "10px" }} onClick={() => PurchaseOrderClear()}>
                        <i className="bi bi-x-circle-fill"></i>&nbsp;Clear
                    </button>
                </div> 
                <div className="col-md-3" style={{ textAlign: "right"}}>
                    <button className="btn addSalerperformBtn salespersionClearbtn" onClick={() => downloadCSVFile()} disabled={DownloadBtnDisable}>
                        <i className="bi bi-download"></i>&nbsp;Download
                    </button>
                    <Link to={SALES_PERSON_PERFORMANCE_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
                </div>
            </div>
        </div>
        {UpdateArrayForCSVfile?.length > 0  && (<div className="tableView">
            <Scrollbars 
                style={{ 
                    height: "calc(100vh - 168px)"
                }} 
                renderView={props => <div {...props} className="view"/>}
                
                className="ScrollbarsSidebar"
            >
                <div className="tableView">
                    <Table bordered>
                        <thead>
                            <tr>
                                <th style={{ width: "5%" }}>SNO</th>
                                <th style={{ width: "12%" }}>Sales Associate</th>
                                <th style={{ width: "15%" }}>Email</th>
                                <th style={{ width: "15%" }}>Franchise</th>
                                <th style={{ width: "25%" }}>Scheme</th>
                                <th style={{ width: "15%" }}>Target</th>
                                <th style={{ width: "5%" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {UpdateArrayForCSVfile?.map((elm, index) => {
                                return(<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{elm?.full_name}</td>
                                    <td>{elm?.email}</td>
                                    <td>{elm?.franchise_customer_name}</td>
                                    <td>
                                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                            <Select 
                                                value={elm?.scheme_code}
                                                onChange={(e) => SelectSchemeOnchange(e, "scheme_code", elm?.id_uniq)} 
                                                isClearable 
                                                options={optionSchemeListUpdate(elm)}
                                                className="godown-select-container" 
                                                classNamePrefix="godown-select"
                                                placeholder="Select scheme"
                                                styles={customStyles}
                                                menuPlacement="auto"
                                                menuPortalTarget={document.body}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Control 
                                            type="number"
                                            className="inputBoxs monthly_target_fields"
                                            value={elm?.target}
                                            onChange={(e) => SelectSchemeOnchange(e.target.value == 0 ? "" : e.target.value.replace(/^0/, ""), "target", elm?.id_uniq)} 
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            placeholder="Enter target"
                                        />
                                    </td>
                                    <td>
                                        <button className="btn edittable" style={{ marginLeft: "5px" }} onClick={() => DeleteMonthlyTarget(elm?.id_uniq)}>
                                            <i className="bi bi-trash3"></i>
                                        </button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                </div>
            </Scrollbars>
        </div>)}
    </section>)
}

export default SalesPersonPerformanceNew;
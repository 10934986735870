/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import { Button, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateAFSQuotationsFilter } from "../../redux/actions/adminActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";

const AFSQuotationsDataTableFilter = ({ tabName, filterType, searchType, ButtonSet, searchdisable, buttonvisible, quotationStatusAllow, ...props }) => {
    const { websocket } = useContext(WebSocketContext);
    const [ CommonSearchUpdate, setCommonSearchUpdate ] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const today = new Date();
    const { AFSQuotationsManagmentFilter, Regionlistall, device_id } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();

    // get all region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select limit
    const SelectLimitFct = (e, url) => {
        const filterKey = url;
        if (filterKey) {
            Dispatch(updateAFSQuotationsFilter({
                [filterKey]: {
                    UserLimit: e.target.value,
                    CurrentPage: 1,
                }
            }));
        };
    };

    // clear search box
    const ClearSearchFiled = (url) => {
        const filterKey = url;

        if (filterKey) {
            Dispatch(updateAFSQuotationsFilter({
                [filterKey]: {
                    UserSearch: "",
                }
            }));
        };
    };

    // clear after delete button hit
    document.addEventListener('keydown', function(event) {
        var keyPressed = event.key;
        if (keyPressed === 'Delete') {
            ClearSearchFiled(tabName);
        }
    });

    // common search update state
    const SearchMainOnchange = async (value, url) => {
        if(url === tabName) {
            await setCommonSearchUpdate(value);
        };
    };

    // on enter search 
    const onEnterSearch = (e, url) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            const filterKey = tabName;

            if (filterKey) {
                OnEnterSearch(url);
            };
        };
    };

    // search box
    const OnEnterSearch = (url) => {
        const filterKey = url;

        if (filterKey) {
            Dispatch(updateAFSQuotationsFilter({
                [filterKey]: {
                    UserSearch: CommonSearchUpdate,
                    CurrentPage: 1,
                }
            }));
        };
    };    

    useEffect(() => {
        const CurrentSearch = AFSQuotationsManagmentFilter[tabName]?.UserSearch === undefined ? "" : AFSQuotationsManagmentFilter[tabName]?.UserSearch;
        setCommonSearchUpdate(CurrentSearch);
    }, [ AFSQuotationsManagmentFilter[tabName] ]);


    const selectRegionFunction = (e, tabName) => {
        Dispatch(updateAFSQuotationsFilter({
            [tabName]: {
                SelectRegion: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // select start date
    const DateStartGet = (date, tabName) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            Dispatch(updateAFSQuotationsFilter({
                [tabName]: {
                    FromDate: "",
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateAFSQuotationsFilter({
                [tabName]: {
                    FromDate: date,
                }
            }));
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date, tabName) => {
        Dispatch(updateAFSQuotationsFilter({
            [tabName]: {
                CurrentPage: 1,
            }
        }));
        if (date === null) {
            setEndDate("")
            let selectedDate = new Date(date);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            Dispatch(updateAFSQuotationsFilter({
                [tabName]: {
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateAFSQuotationsFilter({
                [tabName]: {
                    ToDate: date,
                }
            }));
        }
    };

    // all order status change
    const OnChangeStatusOrder = (event, tabName) => {
        Dispatch(updateAFSQuotationsFilter({
            [tabName]: {
                Status: event?.target?.value,
                CurrentPage: 1,
            }
        }));
    };

    // quotations type
    const OnChangeQuotationType = (event, tabName) => {
        Dispatch(updateAFSQuotationsFilter({
            [tabName]: {
                Type: event?.target?.value,
                CurrentPage: 1,
            }
        }));
    };

    // get page limit from redux
    const GetuserLimit = () => {
        if(AFSQuotationsManagmentFilter[tabName]?.UserLimit === undefined) {
            return 10;
        } else {
            return AFSQuotationsManagmentFilter[tabName].UserLimit;
        };
    };

    return(<React.Fragment>
            <div className="filtr_Form quotations_filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee", marginBottom: "10px" }}>
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-3" style={{ width: "20%" }}>
                            <Form.Group>
                                <div className="datepickField min">
                                    <DatePicker
                                        selected={(AFSQuotationsManagmentFilter[tabName]?.FromDate === "" || AFSQuotationsManagmentFilter[tabName]?.FromDate === undefined) ? "" : new Date(AFSQuotationsManagmentFilter[tabName]?.FromDate)}
                                        startDate={(AFSQuotationsManagmentFilter[tabName]?.FromDate === "" || AFSQuotationsManagmentFilter[tabName]?.FromDate === undefined) ? "" : new Date(AFSQuotationsManagmentFilter[tabName]?.FromDate)}
                                        endDate={(AFSQuotationsManagmentFilter[tabName]?.ToDate === "" || AFSQuotationsManagmentFilter[tabName]?.ToDate === undefined) ? "" : new Date(AFSQuotationsManagmentFilter[tabName]?.ToDate)}
                                        onChange={(date) => DateStartGet(date, tabName)}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={today}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter From Date"
                                        isClearable={AFSQuotationsManagmentFilter[tabName]?.FromDate === undefined ? false : AFSQuotationsManagmentFilter[tabName]?.FromDate !== "" ? true : false}
                                        name="statDate"
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" style={{ width: "20%" }}>
                            <Form.Group>
                                <div className="datepickField">
                                    <DatePicker
                                        selected={(AFSQuotationsManagmentFilter[tabName]?.ToDate === "" || AFSQuotationsManagmentFilter[tabName]?.ToDate === undefined) ? "" : new Date(AFSQuotationsManagmentFilter[tabName]?.ToDate)}
                                        startDate={(AFSQuotationsManagmentFilter[tabName]?.FromDate === "" || AFSQuotationsManagmentFilter[tabName]?.FromDate === undefined) ? "" : new Date(AFSQuotationsManagmentFilter[tabName]?.FromDate)}
                                        endDate={(AFSQuotationsManagmentFilter[tabName]?.ToDate === "" || AFSQuotationsManagmentFilter[tabName]?.ToDate === undefined) ? "" : new Date(AFSQuotationsManagmentFilter[tabName]?.ToDate)}
                                        minDate={startDate}
                                        maxDate={endDate}
                                        onChange={(date) => DateEndGet(date, tabName)}
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter To Date"
                                        isClearable={AFSQuotationsManagmentFilter[tabName]?.ToDate === undefined ? false : AFSQuotationsManagmentFilter[tabName]?.ToDate !== "" ? true : false}
                                        name="ToDate"
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" style={{ width: "15%" }}>
                            <select 
                                className="form-control activestatusselet pe-4" 
                                id="columnfullwith" 
                                value={AFSQuotationsManagmentFilter[tabName]?.SelectRegion} 
                                onChange={(e) => selectRegionFunction(e, tabName)}
                            >
                                <option value="">Select Region</option>
                                {Regionlistall?.map((elm, index) => {
                                    return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                                })}
                            </select>
                        </div>
                        {quotationStatusAllow && (<div className="col-md-3 labelAddOnFields" style={{ width: "20%" }}>
                            <small>Quotation Status</small>
                            <select 
                                className="form-control activestatusselet pe-4" 
                                id="columnfullwith" 
                                value={AFSQuotationsManagmentFilter[tabName]?.Status} 
                                onChange={(e) => OnChangeStatusOrder(e, tabName)}
                            >
                                {tabName === "created_quotation" ? (<React.Fragment>
                                    <option value="CREATED,PAYMENT_PENDING">All Quotations</option>
                                    <option value="CREATED">Created</option>
                                    <option value="PAYMENT_PENDING">Payment Pending</option>
                                </React.Fragment>) : (<React.Fragment>
                                    <option value="CREATED,PAYMENT_APPROVAL_PENDING,PAYMENT_PENDING,PAID,EXPIRED,CANCELLED">All Quotations</option>
                                    <option value="CREATED">Created</option>
                                    <option value="PAYMENT_APPROVAL_PENDING">Payment Approval Pending</option>
                                    <option value="PAYMENT_PENDING">Payment Pending</option>
                                    <option value="PAID">Paid</option>
                                    <option value="EXPIRED">Expired</option>
                                    <option value="CANCELLED">Cancelled</option>
                                </React.Fragment>)}
                            </select>
                        </div>)}
                        <div className="col-md-3 labelAddOnFields" style={{ width: "16%" }}>
                            <small>Quotation Type</small>
                            <select 
                                className="form-control activestatusselet pe-4" 
                                id="columnfullwith" 
                                value={AFSQuotationsManagmentFilter[tabName]?.Type} 
                                onChange={(e) => OnChangeQuotationType(e, tabName)}
                            >
                                <option value="">All</option>
                                <option value="FO">Franchise Order</option>
                                <option value="CO">Customer Order</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-header-New">
                {searchdisable && (<div className={`searchBoxwithbtn ${CommonSearchUpdate !== ""?"search":""}`}>
                    <input 
                        type="text" 
                        className="form-control" 
                        value={CommonSearchUpdate} 
                        onKeyDown={(e) => {onEnterSearch(e, tabName); EmptySpaceFieldValid(e)}} 
                        onChange={(e) => SearchMainOnchange(e.target.value, tabName)} 
                        placeholder={`Search ${filterType} ...`}
                    />
                    <Button className="searchbtn" onClick={() => OnEnterSearch(tabName)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                            <path d="M21 21l-6 -6"></path>
                        </svg>
                    </Button>
                    {CommonSearchUpdate !== "" && (
                        <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                        Clear the search by using the delete key.
                    </Tooltip>}
                    >
                        <i className="bi bi-x-circle-fill searchclear" onClick={() => ClearSearchFiled(tabName)}></i>
                    </OverlayTrigger>)}
                    
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                        Search using {searchType ? `(${searchType})`:""}
                    </Tooltip>}
                    >
                        <i className="searchInfo bi bi-info-circle-fill"></i>
                    </OverlayTrigger>
                </div>)}
                <div className="card-header-right"> 
                    <select className="form-control userLimitselect" value={GetuserLimit()} onChange={(e) => SelectLimitFct(e, tabName)}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                    </select>
                    {buttonvisible && (<button className="btn head_cmn_btn" style={{ marginLeft: filterType === "Users" ? "10px" : "0px" }} onClick={ButtonSet?.function}>
                        <i className={ButtonSet?.buttontitle === "Return & Replace" ? "bi bi-arrow-return-right" : "bi bi-plus-circle"}></i>
                        {ButtonSet?.buttontitle}
                    </button>)}
                </div>
            </div>
        </React.Fragment>)
}

export default AFSQuotationsDataTableFilter;
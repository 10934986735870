/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { Table, Form } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { Link, useParams, useLocation } from "react-router-dom";
import { QUOTATION_REPORT_URL, PAYMENT_APPROVAL_PENDING_QUOTATION_URL } from "../../Shared/constant";
import { GetQuotationsReportDetails, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import CommonLoader from "../../Common/CommonLoader";

const QuotationsReportDetails = (props) => {
    const { roomId } = useParams();
    const Dispatch = useDispatch();
    const Location = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const { device_id, QuotationsReportDetails, commonMainLoader } = useSelector((state) => state.adminReducers);

    useEffect(() => {
        if(roomId) {
            Dispatch(StartMainCommonLoader({
                url: "quotation_detail",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "quotation_detail",
                "request" : { 
                    "quotation_id" : roomId,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ websocket, roomId ]);

    // get total balance and Credit Limit	
    const GetCurrentOrderRate = (current) => {
        const getCurrentFranchise = current?.order?.billing;
        const getCurrentFranchiseType = current?.franchise_type_detail;

        let creditlimit = 0;
        let wallet = 0
        if(getCurrentFranchiseType?.franchise_type_credit !== null && getCurrentFranchiseType?.franchise_type_credit !== "") {
            wallet = parseInt(getCurrentFranchiseType?.franchise_type_credit);
            creditlimit = parseInt(getCurrentFranchiseType?.franchise_type_credit);
        };
        if(getCurrentFranchise?.credit_addon !== null && getCurrentFranchise?.credit_addon !== "") {
            wallet = parseInt(getCurrentFranchise?.credit_addon) + wallet;
            creditlimit = parseInt(getCurrentFranchise?.credit_addon) + creditlimit;
        }; 
        if(getCurrentFranchise?.advance_amount !== null && getCurrentFranchise?.advance_amount !== "") {
            wallet = wallet + parseInt(getCurrentFranchise?.advance_amount);
        };

        return {
            Wallet_balance: (getCurrentFranchise?.advance_amount === null || getCurrentFranchise?.advance_amount === "") ? 0 :parseInt(getCurrentFranchise?.advance_amount),
            credit_limit: creditlimit,
            available_balance: wallet,
        };
    };

    const GetOrderStatus = (url) => {
        if(url === "CREATED" || url === "PAYMENT_PENDING") {
            return "Created";
        } else if(url === "PAYMENT_APPROVAL_PENDING") {
            return "Payment Approval Pending";
        } else if(url === "PAID") {
            return "Paid";
        } else if(url === "CANCELLED") {
            return "Cancelled";
        } else if(url === "EXPIRED") {
            return "Expired";
        };
    };

    return(<section className="Main_section">
        {QuotationsReportDetails !== undefined && Object.keys(QuotationsReportDetails)?.length > 0 ? (<>
            <div className="MainHeader AfterSalesHeaderSteps">
                <h4><i className="bi bi-backspace-reverse-fill"></i> {GetOrderStatus(QuotationsReportDetails?.quotation?.quotation_status)}</h4>
                <Link to={Location === "payment-approval-quotation-details" ? PAYMENT_APPROVAL_PENDING_QUOTATION_URL : QUOTATION_REPORT_URL} onClick={() => Dispatch(GetQuotationsReportDetails({}))} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
            </div>
            <Scrollbars 
                style={{ height: QuotationsReportDetails?.quotation?.order_type === "FO" ? "calc(100vh - 145px)" : "calc(100vh - 145px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
            <div className="filtr_Form"  style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
                <div className="formloginset formloginsetStaticData">
                    <div className="row">
                        {QuotationsReportDetails?.quotation?.order_type !== "CO" && (<div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Franchise Name</Form.Label>
                                <div className="defaultvalueField">
                                {QuotationsReportDetails?.quotation?.customer_name}
                                </div>
                            </Form.Group>
                        </div>)}
                        <div className="col-md-3">
                            <Form.Group className="mb-3 ">
                                <Form.Label>Request No</Form.Label>
                                <div className="defaultvalueField">
                                    {QuotationsReportDetails?.quotation?.quotation_code}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Request Date</Form.Label>
                                <div className="defaultvalueField">
                                    {CommonDateTime(QuotationsReportDetails?.quotation?.created_at, "LL", "show")}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Total Qty</Form.Label>
                                <div className="defaultvalueField">
                                {QuotationsReportDetails?.quotation?.qty}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group>
                                <Form.Label>State:</Form.Label>
                                <div className="defaultvalueField">
                                {QuotationsReportDetails?.quotation?.state?.state_name}
                                </div>
                            </Form.Group>
                        </div>
                        {QuotationsReportDetails?.quotation?.order_type === "FO" && (<>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Wallet Balance:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(QuotationsReportDetails)?.Wallet_balance)}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Credit Limit:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(QuotationsReportDetails)?.credit_limit)}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Available Balance:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(QuotationsReportDetails)?.available_balance)}
                                    </div>
                                </Form.Group>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
            <div className="tableView gstreporttableadded">
                <Table id="stock_level_so_approval" bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Code</th>
                            <th>Product Name</th>
                            <th>Amount</th>
                            <th>Unit Amount</th>
                            <th>Tax Amount</th>
                            <th>Total Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {QuotationsReportDetails?.quotation_items_list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>{index + 1}</td>
                                <td>{elm?.product_code}</td>
                                <td>{elm?.product_name}</td>
                                <td>{elm?.sub_total}</td>
                                <td>{parseInt(elm?.price).toFixed(2)}</td>
                                <td>{elm?.sub_gst}</td>
                                <td>{elm?.qty}</td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
            </div>
            </Scrollbars>
        </>) : (<>
            Please Wait...
        </>)}
        {(commonMainLoader?.url === "quotation_detail" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default QuotationsReportDetails;
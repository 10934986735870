/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { useNavigate } from "react-router-dom";
import { ADD_HELP_DESK_CATEGORY, EDIT_HELP_DESK_CATEGORY } from "../Shared/constant";
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonTable from "../Common/CommonTable";

const HelpDeskCategory = (props) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { HelpDeskcategoryList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DealerApp[3]?.Edit || PermissionsAccess?.DealerApp[3]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "help_desk_category_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "order_by": "-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create applications
    const cmnFnct = () => {
        Navigate(ADD_HELP_DESK_CATEGORY);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'category_name', label: 'Category Name', type: "label", sort: "", isCollapsable: false },
        { key: 'is_active', label: 'Status', type: "status_active", sort: "", isCollapsable: false },
    ];

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Category Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[3]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Category",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />

        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(HelpDeskcategoryList?.pagination?.total_records) === HelpDeskcategoryList?.list?.length ? "calc(100vh - 150px)" : parseInt(HelpDeskcategoryList?.pagination?.total_records) > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={HelpDeskcategoryList} // ORDER LIST
            Pagination={HelpDeskcategoryList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[3]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: (selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[3]?.Edit),
                label: "edit",
                naviagte: `${EDIT_HELP_DESK_CATEGORY}/`
            }}
        />
    </section>)
}

export default HelpDeskCategory;
/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSearchModulesObjects, SelectMainNavigation } from "../../redux/actions/adminActions";
import { useLocation } from "react-router-dom";
import { NavigationsLinks } from "../../AppModules";

const CommonSearchNavigationsButton = (props) => {
    const { showModalNew, setShowModalNew } = props;
    const [ search, setSearch ] = useState("");
    const [ showMore, setShowMore ] = useState(8);
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const { SearchModulesObj, selfInfo } = useSelector((state) => state.adminReducers);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const Location = useLocation();
    const inputRef = useRef(null);

    // search bar field
    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        Dispatch(getSearchModulesObjects(e.target.value));
        setDropdownOpen(e.target.value !== "");
    };

    // select navigations
    const SelectNavigations = (data) => {
        if(data?.module_name === "Logout") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Are you sure?",
                subtitle: "Logout",
                description: `Do you really want to logout ?</h5>`,
                modalType: "not form",
                button: "Yes"
            });
        } else {
            Navigate(data?.navigate_url);
            Dispatch(getSearchModulesObjects(data?.module_name));
            setSearch("");
            setDropdownOpen(false);
    
            if(data?.parent_module_name === "Product Masters" || data?.parent_module_name === "Stores" || data?.parent_module_name === "Warehouse") {
                Dispatch(SelectMainNavigation("Inventory"));
            } else if(data?.parent_module_name === "Admin Tools" || data?.parent_module_name === "Masters") {
                Dispatch(SelectMainNavigation("Admin-Tools"));
            } else if(data?.parent_module_name === "Order Management" || data?.parent_module_name === "AFS Order Management" || data?.parent_module_name === "Payment Manager") {
                Dispatch(SelectMainNavigation("Franchise"));
            } else if(data?.parent_module_name === "CRM" || data?.parent_module_name === "Re-Sale" || data?.parent_module_name === "Dealer App") {
                Dispatch(SelectMainNavigation("CRM-Tools"));
            };
        }
    };

    useEffect(() => {
        setSearch("");
        setDropdownOpen(false);
    }, [ Location ]);

    const NavigationsLinksWithPermissions = NavigationsLinks?.filter((elm) => {
        if(selfInfo?.user?.user_type !== "super_admin") {
            const CurrentPermissions = Object.assign({}, selfInfo?.user?.permissions_access);

            if (elm?.parent_module_name === "Product Masters") {
                const matchingModules = CurrentPermissions?.ProductMasters?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Stores") {
                const matchingModules = CurrentPermissions?.Store?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Warehouse") {
                const matchingModules = CurrentPermissions?.Warehouse?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Admin Tools") {
                const matchingModules = CurrentPermissions?.AdminTools?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Masters") {
                const matchingModules = CurrentPermissions?.Masters?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "CRM") {
                const matchingModules = CurrentPermissions?.CRMTools?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Re-Sale") {
                const matchingModules = CurrentPermissions?.ReSells?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Order Management") {
                const matchingModules = CurrentPermissions?.OrderManagement?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "AFS Order Management") {
                const matchingModules = CurrentPermissions?.AFSOrderManagement?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                if(matchingModules !== undefined) {
                    return matchingModules[0]?.View === true;
                };
            } else if (elm?.parent_module_name === "Payment Manager") {
                const matchingModules = CurrentPermissions?.PaymentManager?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Dealer App") {
                if(CurrentPermissions?.DealerApp) {
                    const matchingModules = CurrentPermissions?.DealerApp?.filter(modulesz => 
                        modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                    );
                    return matchingModules[0]?.View === true;
                };
            };
    
            return false;
        }
    });

    // navigations modules filter array
    const AllPermissions = selfInfo?.user?.user_type === "super_admin" ? NavigationsLinks : NavigationsLinksWithPermissions;
    const NavigationsFilter = AllPermissions?.filter((elms) => {
        if(search !== "") {
            if (
                elms?.module_name?.toLowerCase().includes(
                    search && search.toLowerCase(),
                )
            ) {
                return elms;
            };
        } else {
            return elms;
        };
    });

    // clear search
    const ClearSearch = () => {
        Dispatch(getSearchModulesObjects(""));
        setSearch("");
        setDropdownOpen(false);
    };

    // view more function
    const ViewMoreFunction = () => {
        if(showMore === 8) {
            setShowMore(NavigationsFilter?.length)
        } else {
            setShowMore(8)
        };
    };

    // Function to handle keypress
    const handleKeyPress = (e) => {
        if (e.ctrlKey && e.key === 'p') {
            e.preventDefault(); // Prevent default browser action (if needed)
            inputRef.current.focus(); // Focus on the input box
            inputRef.current.select(); // Focus on the input box
            setDropdownOpen(true);
        };
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        // Cleanup the event listener when the component is unmounted
        return () => {
        window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return(<div className="search_Navigations_wrapper">
        <Dropdown 
            // show={dropdownOpen && search} 
            show={dropdownOpen} 
            onToggle={(isOpen, event, metadata) => {
                setDropdownOpen(isOpen);
            }}
        >
            <Dropdown.Toggle id="dropdown-basic">
                <i className="bi bi-search"></i>
                <input 
                    type="text" 
                    ref={inputRef}
                    className="form-control"
                    value={search || SearchModulesObj}
                    onChange={(e) => onChangeSearch(e)}
                    placeholder="Search or type a command (Ctrl + P)"
                />
                {(search !== "" || SearchModulesObj !== "") && (<i className="bi bi-x-circle-fill" onClick={() => ClearSearch()}></i>)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {NavigationsFilter && NavigationsFilter?.length > 0 ? NavigationsFilter?.slice(0, showMore)?.map((elm, index) => {
                    const highlightSearchTerm = (text, searchTerm) => {
                        if (!searchTerm) return text;
                        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
                        return parts.map((part, i) =>
                          part.toLowerCase() === searchTerm.toLowerCase() ? (
                            <strong key={i}>{part}</strong>
                          ) : (
                            part
                          )
                        );
                    };

                    return(<Dropdown.Item key={index} onClick={() => SelectNavigations(elm)}>
                        {/* <div>{elm?.module_name}</div> */}
                        {highlightSearchTerm(elm?.module_name, search)}
                        <small>{elm?.parent_module_name}</small>
                        <i className="bi bi-bicycle"></i>
                    </Dropdown.Item>)
                }) : (<Dropdown.Item className="module_not_found">
                    Module not found
                </Dropdown.Item>)}
                {NavigationsFilter?.length > 8 && (<Button id="viewmore_btn" onClick={() => ViewMoreFunction()}>{showMore === 8 ? "View More" : "View Less"}</Button>)}
            </Dropdown.Menu>
        </Dropdown>
    </div>)
}

export default CommonSearchNavigationsButton;
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Badge, Spinner, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { DISPATCH_LIST_URL } from "../../Shared/constant";
import { Scrollbars } from 'react-custom-scrollbars-2';
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { StartCommonLoader, ErrorMessageFromRedux, SearchPincodeList, SearchCommonPincodeList, ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonToggle from "../../Common/CommonToggle";
import Select from 'react-select'
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";
import CommonAddressTextField from "../../Common/CommonAddressTextField";

const CreateDispatchList = (props) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { websocket } = useContext(WebSocketContext);
    const { ClearFormSet, searchPincodeList, device_id, commonLoader, searchCommonPincodeList, WarehousePincodeListAll, wareHouselistall, ErrorMessageFromReddux, DispatchLists } = useSelector((state) => state.adminReducers);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        DispatchName: "",
        PINCode: "",
        Email: "",
        Address: "",
        address2: "",
        GSTIN: "",
        Mobile: "",
        Status: true,
        is_warehouse: false,
    });
    const optionsWarehouse = WarehousePincodeListAll?.list?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:`${item?.warehouse_name} (${item?.warehouse_code})`}));
    const [ selectWarehouse, setselectWarehouse ] = useState("");
    const { roomId } = useParams();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const CurrentData_ = DispatchLists?.list?.filter((elm) => elm?.id === roomId)[0];
    const pocRegex = /^\d{10}$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    // error state
    const [ errorDispatchName, seterrorDispatchName ] = useState("");
    const [ errorPINCode, seterrorPINCode ] = useState("");
    const [ errorAddress, seterrorAddress ] = useState("");
    const [ errorAddress2, seterrorAddress2 ] = useState("");
    const [ errorGSTIN, seterrorGSTIN ] = useState("");
    const [ errorMobile, seterrorMobile ] = useState("");
    const [ errorEmail, seterrorEmail ] = useState("");
    const [ errorSelectWarehouse, setErrorSelectWarehouse ] = useState("");
    const [ errorsameState, seterrorsameState ] = useState("");

    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(() => {
        if(searchPincodeList) {
            setApplicationState({
                ...ApplicationState,
                PINCode: searchPincodeList?.pin?.pin_code === undefined ? "" : searchPincodeList?.pin?.pin_code,
            })
        };
    }, [ searchPincodeList ]);

    // get product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "state_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, [ ]);

    // get all warehouse list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "warehouse_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        if(LocationRoute === "edit-dispatch") {
            const CurrentPincode = DispatchLists?.pin_code.filter((elm) => elm?.id === CurrentData_?.pin_code_id)[0]?.pin_code;
            setTimeout(() => {
                setApplicationState({
                    ...ApplicationState,
                    DispatchName: CurrentData_?.dispatch_name,
                    Email: CurrentData_?.email,
                    Address: CurrentData_?.address,
                    address2: CurrentData_?.address2 || "",
                    GSTIN: CurrentData_?.gst_no === null ? "" : CurrentData_?.gst_no,
                    Mobile: CurrentData_?.mobile,
                    PINCode: CurrentPincode,
                    Status: CurrentData_?.is_active,
                    is_warehouse: CurrentData_?.is_warehouse,
                });
                
                const CurrentWarehouse = optionsWarehouse?.filter((elm) => elm?.value === CurrentData_?.warehouse_id)[0];
                setselectWarehouse(CurrentWarehouse === undefined ? "" : CurrentWarehouse);
                CheckValid(CurrentWarehouse === undefined ? "" : CurrentWarehouse, {type: 'Select Warehouse', error: setErrorSelectWarehouse});

                if(CurrentPincode?.length === 6) {
                    dispatch(StartCommonLoader(true));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "pin_code_search",
                        "request" : { 
                            "pin_code" : CurrentPincode, 
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    dispatch(StartCommonLoader(false));
                }
            }, 500);
        };
    }, [ CurrentData_ ]);

    // wareehouse select
    const WareHouseSelect = (value) => {
        if(value !== null) {
            setselectWarehouse(value);
            CheckValid(value, {type: 'Select Warehouse', error: setErrorSelectWarehouse});
        } else {
            setselectWarehouse("");
            CheckValid(value === null ? "" : value, {type: 'Select Warehouse', error: setErrorSelectWarehouse});
        }
    };

    // search pincode
    const SearchPincode = () => {
        if(ApplicationState?.PINCode?.length === 6) {
            dispatch(StartCommonLoader(true));
            let param = { 
                "transmit": "broadcast", 
                "url": "pin_code_search",
                "request" : { 
                    "pin_code" : ApplicationState?.PINCode, 
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            dispatch(StartCommonLoader(false));
        }
    };

    // check valid
    const GSTINvalid = (value) => {
        if(value !== "") {
            setApplicationState({...ApplicationState, GSTIN: value});
        } else {
            setApplicationState({...ApplicationState, GSTIN: ""});
            seterrorGSTIN("GSTIN cannot be empty");
        };
    };

    const GSTvalidations = (value) => {
        if(value !== "") {
            const GSTDigit = searchPincodeList?.state?.gst_code?.length === 1 ? 2 : searchPincodeList?.state?.gst_code?.length
            const GSTDigitValid = searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code;

            const Substring = value.trim().substring(0, GSTDigit);

            if(Substring.toString() === GSTDigitValid.toString()) {
                return true;
            } else {
                return false;
            }
        };
    };

    const GSTINvalidKeyUp = (value) => {
        if(value !== "") {
            if(searchPincodeList?.state?.gst_code === undefined) {
                seterrorGSTIN(`Before you enter the GST number, please enter the PIN code.`);
            } else {
                if(GSTvalidations(value) && value?.length === 15) {
                    seterrorGSTIN("");
                } else {
                    seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                };
            };
        } else {
            seterrorGSTIN("GSTIN cannot be empty");
        };
    };

    // search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchPincode();
        };
    };

    const PinCOdeSearchFunction = (e) => {
        setApplicationState({...ApplicationState, PINCode: e.target.value});
        if (e.target.value.length === 6) {
            dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "broadcast",
                "url": "pin_code_search",
                "request": {
                    "pin_code": e.target.value,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);

        } if (e.target.value === "") {
            dispatch(StartCommonLoader(false))
            dispatch(SearchPincodeList({}));
            dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        } else {
            dispatch(StartCommonLoader(false))
        }
    };
   
    // pin code error get from redux
    useEffect(() => {
        if(ErrorMessageFromReddux?.API === "pin_code_search" && ErrorMessageFromReddux?.Error !== "") {
            seterrorPINCode(ErrorMessageFromReddux?.Error);
        };
    }, [ ErrorMessageFromReddux ]);

    const PhoneCode = 91;
    const CountryStates = getRegionCodeForCountryCode(PhoneCode);
    const CheckMobileNumber = parsePhoneNumber(ApplicationState?.Mobile?.toString(), { regionCode: CountryStates });

    // Warehouse create and update
    const CreateWareHouseFct = () => {
        if(ApplicationState?.DispatchName.trim() !== "" && ApplicationState?.PINCode.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
           ApplicationState?.Email.trim() !== "" && ApplicationState?.Address.trim() !== "" && ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100 && ApplicationState?.GSTIN.trim() !== "" 
           && searchCommonPincodeList?.state?.id !== undefined && searchPincodeList?.state?.id !== undefined
        ) {
            if(EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true && (GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) && searchCommonPincodeList?.state?.id === searchPincodeList?.state?.id) {
                if(LocationRoute === "edit-dispatch") {
                    dispatch(ClearFormSetFutios({
                        url: "dispatch_edit",
                        action: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "dispatch_edit",
                        "request" : { 
                            "dispatch_id": CurrentData_?.id,
                            "dispatch_name" : ApplicationState?.DispatchName.trim(), 
                            "mobile": ApplicationState?.Mobile.trim(),
                            "email": ApplicationState?.Email.trim(),
                            "pin_code_id": searchPincodeList?.pin?.id,
                            "gst_no": ApplicationState?.GSTIN.trim(),
                            "address": ApplicationState?.Address.trim(),
                            "address2": !ApplicationState?.address2 ? null : ApplicationState?.address2?.trim(),
                            "warehouse_id": selectWarehouse?.value, 
                            is_warehouse: ApplicationState?.is_warehouse,
                            "is_active": ApplicationState?.Status,
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    dispatch(ClearFormSetFutios({
                        url: "dispatch_add",
                        action: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "dispatch_add",
                        "request" : { 
                            "dispatch_name" : ApplicationState?.DispatchName.trim(), 
                            "mobile": ApplicationState?.Mobile.trim(),
                            "email": ApplicationState?.Email.trim(),
                            "pin_code_id": searchPincodeList?.pin?.id,
                            "gst_no": ApplicationState?.GSTIN.trim(),
                            "address": ApplicationState?.Address.trim(),
                            "address2": !ApplicationState?.address2 ? null : ApplicationState?.address2?.trim(),
                            "warehouse_id": selectWarehouse?.value,
                            is_warehouse: ApplicationState?.is_warehouse,
                            "is_active": ApplicationState?.Status,
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                };
            } else {
                if(GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) {
                    seterrorGSTIN("");
                } else {
                    seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                };
                CheckValid(ApplicationState?.Mobile, {type: 'phone', error: seterrorMobile});
                CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail });
                if(searchCommonPincodeList?.state?.id !== undefined && searchPincodeList?.state?.id !== undefined) {
                    if(searchCommonPincodeList?.state?.id !== searchPincodeList?.state?.id) {
                        seterrorsameState(`Please ensure that the warehouse and dispatch states are the same. Enter a pincode that is within ${searchCommonPincodeList?.state?.state_name} state.`);
                    } else {
                        seterrorsameState("");
                    };
                };
            };
        } else{
            CheckValid(ApplicationState?.Mobile, {type: 'phone', error: seterrorMobile});
            CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail });
            CheckValid(ApplicationState?.DispatchName.trim(), {type: 'DispatchName', error: seterrorDispatchName});
            CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
            CheckValid(ApplicationState?.Address.trim(), {type: 'Address', error: seterrorAddress});
            CheckValid(ApplicationState?.address2?.trim(), {type: 'address2', error: seterrorAddress2});
            // if(!ApplicationState?.address2){
            //     seterrorAddress2("Address cannot be empty")
            // }
            CheckValid(selectWarehouse === null ? "" : selectWarehouse, {type: 'Select Warehouse', error: setErrorSelectWarehouse});
            if(ApplicationState?.GSTIN.trim() !== "") {
                if(searchPincodeList?.state?.gst_code === undefined) {
                    seterrorGSTIN(`Before you enter the GSTIN number, please enter the PIN code.`);
                } else {
                    if(GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) {
                        seterrorGSTIN("");
                    } else {
                        seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                    };
                };
            } else {
                seterrorGSTIN("GSTIN cannot be empty");
            };
        };
    };

    

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "dispatch_add" || ClearFormSet?.url === "dispatch_edit")) {
            Navigate(DISPATCH_LIST_URL);
            dispatch(SearchPincodeList({}));
            dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    }, [ ClearFormSet ]);

    // clear data
    const ClearData = () => {
        dispatch(SearchPincodeList({}));
        dispatch(SearchCommonPincodeList({}));
        dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
    };

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    useEffect(() => {
        if(searchCommonPincodeList?.state?.id !== undefined && searchPincodeList?.state?.id !== undefined) {
            if(searchCommonPincodeList?.state?.id !== searchPincodeList?.state?.id) {
                seterrorsameState(`Please ensure that the warehouse and dispatch states are the same. Enter a pincode that is within ${searchCommonPincodeList?.state?.state_name} state.`);
            } else {
                seterrorsameState("");
            };
        };
    }, [ searchCommonPincodeList, searchPincodeList ]);

    useEffect(() => {
        if(selectWarehouse !== "") {
            const GetCurrentPincode = WarehousePincodeListAll?.pin_code?.filter((elm) => elm?.id === selectWarehouse?.pin_code_id)[0];
            let param = {
                "transmit": "broadcast",
                "url": "pin_code_search",
                "WareHouseManualSearch": "WareHouseManualSearch",
                "request": {
                    "pin_code": GetCurrentPincode?.pin_code,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, [ selectWarehouse, WarehousePincodeListAll ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={DISPATCH_LIST_URL} className="btn btn-link backbtn" onClick={() => ClearData()}>
                <i className="bi bi-arrow-left-short"></i> Back
            </Link>
        </div>
        <div className="tabsWrappersmAN">
            <Scrollbars 
                style={{ height: "calc(100vh - 147px)" }} 
                renderView={props => <div {...props} className="view"/>}
                
                className="ScrollbarsSidebarHide"
            >
                <div className="Bigform_custom_scroll" style={{ height: "calc(100vh - 147px)" }} >
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3 " >
                                <Form.Label>Dispatch Name<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.DispatchName} 
                                    onChange={(e) => setApplicationState({...ApplicationState, DispatchName: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'DispatchName', error: seterrorDispatchName})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    disabled={UploadFileLoader}
                                    placeholder="Enter Dispatch Name" 
                                />
                                {errorDispatchName !== "" &&<span className="error_medotory">{errorDispatchName}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3 ">
                                <Form.Label>Select Warehouse<span className="mendatory_star">*</span></Form.Label>
                                <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                    <Select 
                                        value={selectWarehouse}
                                        onChange={(e) => WareHouseSelect(e)} 
                                        isClearable 
                                        options={optionsWarehouse}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="Select Warehouse"
                                        styles={customStyles}
                                    />
                                </div>
                                {errorSelectWarehouse !== "" &&<span className="error_medotory">{errorSelectWarehouse}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Email Address<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.Email} 
                                    onChange={(e) => setApplicationState({...ApplicationState, Email: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: seterrorEmail})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Email Address" 
                                    disabled={UploadFileLoader}
                                />
                                {errorEmail !== "" &&<span className="error_medotory">{errorEmail}</span>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    PIN Code
                                    <span className="mendatory_star">*</span>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 100 }}
                                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                        Search by either hitting the enter button or clicking on the search icon.
                                    </Tooltip>}
                                    >
                                        <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div className="serachBoxWIth">
                                    <Form.Control 
                                        type="text" 
                                        value={ApplicationState?.PINCode} 
                                        onChange={(e) => PinCOdeSearchFunction(e)}
                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'PINCode', error: seterrorPINCode})}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e);
                                            onEnterPincodeSearch(e)
                                        }}
                                        disabled={commonLoader}
                                        maxLength={6}
                                        placeholder="Enter PIN Code" 
                                    />
                                    <Button className="searchbtn" onClick={() => SearchPincode()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                            <path d="M21 21l-6 -6"></path>
                                        </svg>
                                    </Button>
                                    {ErrorMessageFromReddux?.API === "pin_code_search" && (<>
                                        <div className="pincode_error">
                                            {ErrorMessageFromReddux?.Error !== "" ? (
                                                <i className="bi bi-x-circle-fill" onClick={() => setApplicationState({...ApplicationState, PINCode: ""})} style={{ color: "red" }}></i>
                                            ) : (<i className="bi bi-check-circle-fill" style={{ color: "#10b068" }}></i>)}
                                        </div>
                                    </>)}
                                    {commonLoader && (<Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>)}
                                </div>
                                {errorPINCode !== "" &&<span className="error_medotory">{errorPINCode}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>City</Form.Label>
                                <div className="defaultvalueField">
                                    {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.city?.district_name : ""}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>State</Form.Label>
                                <div className="defaultvalueField">
                                    {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.state?.state_name : ""}
                                </div>
                                {errorsameState !== "" && (<span className="error_medotory">{errorsameState}</span>)}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>GSTIN<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.GSTIN} 
                                    onChange={(e) => GSTINvalid(e?.target?.value)}
                                    onKeyUp={(e) => GSTINvalidKeyUp(e?.target?.value)}
                                    onKeyDown={EmptySpaceFieldValid}
                                    disabled={UploadFileLoader}
                                    placeholder="Enter GSTIN" 
                                />
                                {errorGSTIN !== "" &&<span className="error_medotory">{errorGSTIN}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Mobile Number<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="number" 
                                    className="inputBoxs"
                                    value={ApplicationState?.Mobile} 
                                    onChange={(e) => setApplicationState({...ApplicationState, Mobile: e.target.value.replace(/^0/, "")})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'phone', error: seterrorMobile})}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Mobile Number" 
                                    disabled={UploadFileLoader}
                                />
                                {errorMobile !== "" &&<span className="error_medotory">{errorMobile}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Status</Form.Label>
                                <div className="ToggleMainWrap">
                                    <CommonToggle 
                                        valueToggle={ApplicationState?.Status}
                                        setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                                        name={""}
                                    />
                                    {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <CommonAddressTextField 
                                Label={"Address Line 1"}
                                MainState={ApplicationState}
                                setMainState={setApplicationState} 
                                Address={"Address"}
                                isMendatory={true}
                                ErrorAddress={errorAddress} 
                                setErrorAddress={seterrorAddress}
                                MaxLength={100}
                            />
                        </div>
                        <div className="col-md-4">
                            <CommonAddressTextField 
                                Label={"Address Line 2(Optional)"}
                                MainState={ApplicationState}
                                setMainState={setApplicationState} 
                                Address={"address2"}
                                isMendatory={false}
                                MaxLength={100}
                            />
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Same As Warehouse Address</Form.Label>
                                <div className="ToggleMainWrap">
                                    <CommonToggle 
                                        valueToggle={ApplicationState?.is_warehouse}
                                        setValueToggle={() => setApplicationState({...ApplicationState, is_warehouse: !ApplicationState?.is_warehouse})}
                                        name={""}
                                    />
                                    {ApplicationState?.is_warehouse ? <Badge bg="success">Yes</Badge> : <Badge bg="danger">No</Badge>}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{ textAlign: "right" }}>
                            <Button 
                                className="formbtn" 
                                disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "dispatch_add" || ClearFormSet?.url === "dispatch_edit")}
                                onClick={() => CreateWareHouseFct()} 
                                style={{ width: "auto" }} 
                                variant="primary" 
                            >
                                {LocationRoute === "edit-dispatch" ? "Update" : "Add"}
                            </Button>
                        </div>
                    </div>
                </div>
                </div>
            </Scrollbars>
        </div>
    </section>)
}

export default CreateDispatchList;
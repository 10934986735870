/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { Table, Badge, Button } from 'react-bootstrap';
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonDateTime from "../Common/CommonDateTime";
import CommonewModel from "../Common/CommonewModel";
import AmountNumberFormat from "../Common/AmountNumberFormat";
import GetViewFilesAPI from "../Common/GetViewFilesAPI";
import { FILE_AUTH_URL } from "../Shared/constant";
import CommonLoader from "../Common/CommonLoader";

const PaymentApprovalListing = (props) => {
    const Dispatch = useDispatch();
    const { PaymentReceiptListing, device_id, selfInfo, accessToken, commonMainLoader } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        subtitle: "",
        description: "",
        button: "",
        Data: {}
    });
    const { websocket } = useContext(WebSocketContext);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[7]?.Edit || PermissionsAccess?.PaymentManager[7]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "list_payment_receipt",
                "request" : { 
                    "status" : userType, 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "order_by": "-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // SHOW ANSWER OF FAQ
    const ApprovalPayment = (data) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Payment Approval",
            subtitle: "Payment Approval",
            description: `Are you sure you want to approve this payment?`,
            button: "Yes",
            Data: data
        });
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // donwload file
    const DonwloadFile = async (refurbished_pdf, file_srno) => {
        const authurl = FILE_AUTH_URL + refurbished_pdf?.split(".")[0];
        const url = await GetViewFilesAPI(authurl, accessToken);

        if(url) {
            var tempEl = document.createElement("a");
            tempEl.href = url;
            tempEl.download = "Receipt_" + file_srno + "." +refurbished_pdf?.split(".")[1];
            tempEl.click();
            window.URL.revokeObjectURL(url);
        };
    };

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"payment_approval_listing"}
            searchType={"Ticket No, Receipt No"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            ButtonSet={{
                buttontitle: "",
            }}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />

        <div className="tableView">
            <Scrollbars 
                style={{ height: PaymentReceiptListing?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Ticket No</th>
                            <th>Dealer Name</th>
                            <th>Payment Amount</th>
                            <th>Receipt No</th>
                            <th>Uploaded Receipt</th>
                            <th>Status</th>
                            <th>Updated Date</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.PaymentManager[7]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {PaymentReceiptListing?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, PaymentReceiptListing?.pagination?.current_page, PaymentReceiptListing?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.reference_number === null ? "-" : elm?.reference_number}</td>
                                <td>{elm?.franchise_details?.customer_name}</td>
                                <td>{AmountNumberFormat(elm?.payment_amt)}</td>
                                <td>{elm?.receipt_number}</td>
                                <td>
                                    <ul className="attachment_listin_payment">
                                        {elm?.receipt_details?.map((elmz, indexs) => {
                                            return(<li key={indexs} onClick={() => DonwloadFile(elmz?.view_file_url, indexs + 1)}>
                                                <i className="bi bi-download"></i> Receipt {indexs + 1}
                                            </li>)
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    {elm?.status === "approved" ? (<Badge bg="success">Approved</Badge>) : (<Badge bg="danger">Pending</Badge>)}
                                </td>
                                <td>{CommonDateTime(elm.updated_at, "LL", "show")}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.PaymentManager[7]?.Edit) && (<td>
                                    {elm?.status === "pending" ? (<Button bg="success" className="donwload_btn" onClick={() => ApprovalPayment(elm)}><i className="bi bi-check2-circle"></i> Approve</Button>) : ("-")}
                                </td>)}
                            </tr>)
                        })}
                        {PaymentReceiptListing?.list?.length === 0 && (<tr style={{ textAlign: "center" }}>
                            <td colSpan="9">Payment approval list not found</td>
                        </tr>)}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {PaymentReceiptListing?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={PaymentReceiptListing?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>     
        {showModalNew?.open && (<CommonewModel 
            setShowModalNew={setShowModalNew} 
            showModalNew={showModalNew}
            setModuleCurrentPage={setCurrentPage}
            APIcalling={APICall}
        />)}   

        {(commonMainLoader?.url === "update_payment_receipt" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default PaymentApprovalListing;
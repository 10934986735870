/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import moment from "moment";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonDateTime from "../Common/CommonDateTime";
import { ADD_SALES_PERSON_PERFORMANCE_URL } from "../Shared/constant";
import { useNavigate } from "react-router-dom";

const SalesPersonPerformance = (props) => {
    const { RouteName } = props;
    const { device_id, selfInfo, salesPersonPerformanceList, AllDealerFranchiseTypeListAll, franchiseSchemeAllList } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ Franchise, setFranchise ] = useState("");
    const [ scheme_code, setscheme_code ] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const OptionFranchiseArray = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.parent_id === null)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.customer_name }));
    const optionSchemeList = franchiseSchemeAllList?.map((item) => ({...item, key: item.scheme_code, value: item.scheme_code, label: item.scheme_code}))
    const today = new Date();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select franchise name
    const FranchiseName = (value) => {
        setFranchise(value);
        setCurrentPage(1);
    };

    // select schemecode
    const SelectSchemeOnchange = (value) => {
        setscheme_code(value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // get franchise_type_list all
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_scheme_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : "",
                "start_date": "",
                "end_date": "",
                "order_by" :"-updated_at"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // api calling
    const APICall = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "admin_scheme_report",
            "request" : { 
                "search" : userSearch.trim(),
                "franchise_id" : Franchise?.value,
                "scheme_code": scheme_code?.value,
                "from_date": null,
                "to_date": null,
                "limit" : userLimit,
                "page_no" : currentPage, 
                "order_by" :"-updated_at"
            },
            "DeviceId" : device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, currentPage, userLimit, Franchise, scheme_code, FromDate, ToDate ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // get sales person performance status
    function getStatus(data) {
        const currentDate = new Date();
        const startDate = new Date(data.start_date);
        const endDate = new Date(data.end_date);

        const resetTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const currentDateWithoutTime = resetTime(currentDate);
        const startDateWithoutTime = resetTime(startDate);
        const endDateWithoutTime = resetTime(endDate);
    
        if (currentDateWithoutTime < startDateWithoutTime) {
            return (<Badge bg="info" className="status_mark yet_to_start">Yet to start</Badge>);
        } else if (currentDateWithoutTime >= startDateWithoutTime && currentDateWithoutTime <= endDateWithoutTime) {
            if (parseInt(data.target_achived) >= parseInt(data.target)) {
                return (<Badge bg="success" className="status_mark">Completed</Badge>);
            };
            return (<Badge bg="warning" className="status_mark in_progress">In progress</Badge>);
        } else if (currentDateWithoutTime > endDateWithoutTime) {
            if (parseInt(data.target_achived === null ? 0 : data.target_achived) < parseInt(data.target)) {
                return (<Badge bg="danger" className="status_mark">Expired</Badge>);
            }
        };
    };

    // create applications
    const cmnFnct = () => {
        Navigate(ADD_SALES_PERSON_PERFORMANCE_URL);
    };

    return(<section className="Main_section">
        <div className="formloginset">
            <div className="row">
                <div className="col-md-3" style={{ width: "23%" }}>
                    <Form.Group>
                        <div className="datepickField min">
                            <DatePicker
                                selected={FromDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                onChange={(date) => DateStartGet(date)}
                                dateFormat="dd/MM/yyyy"
                                maxDate={today}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter From Date"
                                isClearable={FromDate !== "" ? true : false}
                                name="statDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3" style={{ width: "23%" }}>
                    <Form.Group>
                        <div className="datepickField">
                            <DatePicker
                                selected={ToDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                minDate={startDate}
                                maxDate={endDate}
                                onChange={(date) => DateEndGet(date)}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter To Date"
                                isClearable={ToDate !== "" ? true : false}
                                name="ToDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3">
                    <Form.Group className="mb-3">
                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                            <Select
                                value={Franchise}
                                onChange={(e) => FranchiseName(e)}
                                isClearable
                                options={OptionFranchiseArray}
                                className="godown-select-container"
                                classNamePrefix="godown-select"
                                placeholder="Select Franchise"
                                styles={customStyles}
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3">
                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                        <Select 
                            value={scheme_code}
                            onChange={(e) => SelectSchemeOnchange(e)} 
                            isClearable 
                            options={optionSchemeList}
                            className="godown-select-container" 
                            classNamePrefix="godown-select"
                            placeholder="Select scheme code"
                            styles={customStyles}
                        />
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter 
            filterType={"salesPersonPerformance"}
            searchType={"Email"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[2]?.Edit) ? true : false}
            searchdisable={true}
            ActiveInactiveFilter={false}
            ButtonSet={{
                buttontitle: "Create Monthly Target",
                function: cmnFnct
            }}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars 
                style={{ height: salesPersonPerformanceList?.pagination?.total_records > 10 ? "calc(100vh - 240px)" : "calc(100vh - 205px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered hidden id="salesPersonPerformance">
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>User</th>
                            <th>Email</th>
                            <th>Dealer</th>
                            <th>Scheme Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Target</th>
                            <th>Achvmnt</th>
                            <th>Inc. Earned</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesPersonPerformanceList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, salesPersonPerformanceList?.pagination?.current_page, salesPersonPerformanceList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.full_name}</td>
                                <td>{elm?.sales_email}</td>
                                <td>{elm?.franchise_data?.franchise_name}</td>
                                <td>{elm?.scheme_name}</td>
                                <td>{elm?.start_date}</td>
                                <td>{elm?.end_date}</td>
                                <td>{elm?.target}</td>
                                <td>{elm?.target_achived === null ? "-" : elm?.target_achived}</td>
                                <td>{elm?.incentive_earned === null ? "-" : elm?.incentive_earned}</td>
                                <td>{getStatus(elm)}</td>
                            </tr>)
                        })}
                        {salesPersonPerformanceList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Sales person performance list not found</td></tr>}
                    </tbody>
                </Table>

                <Table bordered>
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>User</th>
                            <th>Email</th>
                            <th>Dealer</th>
                            <th>Scheme Name</th>
                            <th>Scheme Month</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Target</th>
                            <th>Achvmnt</th>
                            <th>Inc. Earned</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesPersonPerformanceList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, salesPersonPerformanceList?.pagination?.current_page, salesPersonPerformanceList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.full_name}</td>
                                <td>{elm?.sales_email}</td>
                                <td>{elm?.franchise_data?.franchise_name}</td>
                                <td>{elm?.scheme_name}</td>
                                <td>{moment().month(elm?.scheme_date).format('MMMM')}</td>
                                <td>{elm?.start_date}</td>
                                <td>{elm?.end_date}</td>
                                <td>{elm?.target}</td>
                                <td>{elm?.target_achived === null ? "-" : elm?.target_achived}</td>
                                <td>{elm?.incentive_earned === null ? "-" : elm?.incentive_earned}</td>
                                <td>{getStatus(elm)}</td>
                            </tr>)
                        })}
                        {salesPersonPerformanceList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Sales person performance list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {salesPersonPerformanceList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={salesPersonPerformanceList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default SalesPersonPerformance;
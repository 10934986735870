/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { SCHEMES_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import CommonDateTime from "../../Common/CommonDateTime";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const AddSchemes = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ ApplicationState, setApplicationState ] = useState({
        SchemeName: "",
        ProductName: "",
        IncentiveCode: "",
        StartDate: "",
        EndDate: "",
        Status: true
    });
    const { ClearFormSet, device_id, productlistall, IncentiveSlabList, franchiseSchemeList } = useSelector((state) => state.adminReducers);
    const optionsProduct = productlistall?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name}));
    
    // error state
    const [errorSchemeName, seterrorSchemeName] = useState("");
    const [errorProductName, seterrorProductName] = useState("");
    const [errorIncentiveCode, seterrorIncentiveCode] = useState("");
    const [errorStartDate, seterrorStartDate] = useState("");
    const [errorEndDate, seterrorEndDate] = useState("");

    const { roomId } = useParams();
    const CurrentData_ = franchiseSchemeList?.list?.filter((elm) => elm?.id === roomId)[0];

    // update incentive groups into list
    const UpdateIncentiveSlabList = Object.values(
        IncentiveSlabList?.list?.reduce((acc, { title, incentive_code, slab, range_from, range_to, amount }) => {
            if (!acc[incentive_code]) {
                acc[incentive_code] = {
                    title,
                    incentive_code,
                    incentives: [] 
                };
            };
            
            // Push slab details to the incentives array
            acc[incentive_code].incentives.push({ slab, range_from, range_to, amount });
            
            return acc;
        }, {})
    );

    const optionsIncentiveSlabs = UpdateIncentiveSlabList?.map((item, index)=>({key:item.incentive_code, value:item?.incentive_code , label:item?.title}));

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get all product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "salesperson_incentive_slab",
            "request" : { 
                "status": true,
                "limit" : 5000,
                "search" : "",
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // datepicker selctions
    const DateGet = (value, url) => {
        if(value !== null) {
            if(url === "start_date") {
                setApplicationState({
                    ...ApplicationState,
                    StartDate: value,
                    EndDate: ""
                });
                seterrorStartDate("");
            } else {
                setApplicationState({
                    ...ApplicationState,
                    EndDate: value,
                });
                seterrorEndDate("");
            };
        } else {
            if(url === "start_date") {
                setApplicationState({
                    ...ApplicationState,
                    StartDate: "",
                    EndDate: ""
                });
                seterrorStartDate("Please select start date");
            } else {
                setApplicationState({
                    ...ApplicationState,
                    EndDate: "",
                });
                seterrorEndDate("Please select end date");
            };
        };
    };

    // Product select
    const ProductSelect = (value) => {
        if(value !== null) {
            setApplicationState({
                ...ApplicationState,
                ProductName: value,
            });
            CheckValid(value, { type: 'ProductSelect', error: seterrorProductName });
        } else {
            setApplicationState({
                ...ApplicationState,
                ProductName: "",
            });
            CheckValid(value === null ? "" : value, { type: 'ProductSelect', error: seterrorProductName });
        };
    };

    // Incentive Slab select
    const IncentiveSlabSelect = (value) => {
        if(value !== null) {
            setApplicationState({
                ...ApplicationState,
                IncentiveCode: value,
            });
            CheckValid(value, { type: 'IncentiveCode', error: seterrorIncentiveCode });
        } else {
            setApplicationState({
                ...ApplicationState,
                IncentiveCode: "",
            });
            CheckValid(value === null ? "" : value, { type: 'IncentiveCode', error: seterrorIncentiveCode });
        };
    };
    
    useEffect(() => {
        if(LocationRoute === "edit-schemes") {
            const CurrentProduct = optionsProduct?.filter((elm) => elm?.value === CurrentData_?.product_details?.id)[0];
            const CurrentIncentive = optionsIncentiveSlabs?.filter((elm) => elm?.value === CurrentData_?.incentive_code)[0];

            setApplicationState({
                ...ApplicationState,
                SchemeName: CurrentData_?.scheme_name,
                ProductName: CurrentProduct,
                IncentiveCode: CurrentIncentive,
                StartDate: new Date(CurrentData_?.start_date),
                EndDate: new Date(CurrentData_?.end_date),
                Status: true
            });
        };
    }, [ CurrentData_ ])

    // create Add Schemes
    const AddSchemesFct = () => {
        if(ApplicationState?.SchemeName !== "" && ApplicationState?.ProductName !== "" && ApplicationState?.IncentiveCode !== ""
          && ApplicationState?.StartDate !== "" && ApplicationState?.EndDate !== ""
        ) {
            Dispatch(ClearFormSetFutios({
                url: "product_scheme_add",
                action: true
            }));

            let param = { 
                "transmit": "broadcast", 
                "url": "product_scheme_add",
                "request" : { 
                    "scheme_name": ApplicationState?.SchemeName,
                    "product_id": ApplicationState?.ProductName?.value,
                    "incentive_code": ApplicationState?.IncentiveCode?.value,
                    "start_date": CommonDateTime(ApplicationState?.StartDate, "DD-MM-YYYY", "hide"),
                    "end_date": CommonDateTime(ApplicationState?.EndDate, "DD-MM-YYYY", "hide"),
                    "is_active" : ApplicationState?.Status
                },
                "DeviceId" : device_id
            };
            if(LocationRoute === "edit-schemes") {
                param.request.scheme_id = roomId;
            };

            wsSend_request(websocket, param);
        } else {
            CheckValid(ApplicationState?.SchemeName, { type: 'SchemeName', error: seterrorSchemeName })
            CheckValid(ApplicationState?.ProductName, { type: 'ProductSelect', error: seterrorProductName });
            CheckValid(ApplicationState?.IncentiveCode, { type: 'IncentiveCode', error: seterrorIncentiveCode });

            if(ApplicationState?.StartDate === "") {
                seterrorStartDate("Please select start date");
            };
            if(ApplicationState?.EndDate === "") {
                seterrorEndDate("Please select end date");
            };
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url === "product_scheme_add") {
            Navigate(SCHEMES_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper createScheme_wrapper">
        <div className="MainHeader">
            <Link to={SCHEMES_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Scheme Name <span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                value={ApplicationState?.SchemeName}
                                onChange={(e) => setApplicationState({ ...ApplicationState, SchemeName: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'SchemeName', error: seterrorSchemeName })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Scheme Name"
                            />
                            {errorSchemeName !== "" && <span className="error_medotory">{errorSchemeName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Products <span className="mendatory_star">*</span></Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    onChange={(e) => ProductSelect(e)} 
                                    value={ApplicationState?.ProductName}
                                    isClearable 
                                    options={optionsProduct}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    placeholder="Select Products"
                                    styles={customStyles}
                                />
                                {errorProductName !== "" && <span className="error_medotory">{errorProductName}</span>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Incentive Slab <span className="mendatory_star">*</span></Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    onChange={(e) => IncentiveSlabSelect(e)} 
                                    value={ApplicationState?.IncentiveCode}
                                    isClearable 
                                    options={optionsIncentiveSlabs}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    placeholder="Select Incentive Slab"
                                    styles={customStyles}
                                />
                                {errorIncentiveCode !== "" && <span className="error_medotory">{errorIncentiveCode}</span>}
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Start Date <span className="mendatory_star">*</span></Form.Label>
                            <div className="datepickField datepickFieldSchome">
                                <DatePicker
                                    selected={ApplicationState?.StartDate}
                                    startDate={ApplicationState?.StartDate}
                                    onChange={(date) => DateGet(date, "start_date")}
                                    dateFormat="yyyy/MM/dd"
                                    minDate={new Date()}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter Start Date"
                                    isClearable={ApplicationState?.StartDate !== "" ? true : false}
                                    autoComplete="off"
                                />
                                {errorStartDate !== "" && <span className="error_medotory">{errorStartDate}</span>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>End Date <span className="mendatory_star">*</span></Form.Label>
                            <div className="datepickField datepickFieldSchome">
                                <DatePicker
                                    selected={ApplicationState?.EndDate}
                                    startDate={ApplicationState?.StartDate}
                                    onChange={(date) => DateGet(date, "end_date")}
                                    dateFormat="yyyy/MM/dd"
                                    minDate={ApplicationState?.StartDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter Start Date"
                                    isClearable={ApplicationState?.EndDate !== "" ? true : false}
                                    disabled={ApplicationState?.StartDate === ""}
                                    autoComplete="off"
                                />
                                {errorEndDate !== "" && <span className="error_medotory">{errorEndDate}</span>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button 
                            className="formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true  && ClearFormSet?.url === "product_scheme_add"} 
                            onClick={() => AddSchemesFct()} variant="primary" 
                        >
                            {LocationRoute === "edit-schemes" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default AddSchemes;
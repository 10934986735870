/* eslint-disable */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AfsOrderSyncPaymentFailedReport from "./AfsOrderSyncPaymentFailedReport";
import AfsOrderSyncQuotationFailedReport from "./AfsOrderSyncQuotationFailedReport";
import CommonLoader from "../../Common/CommonLoader";
import AfsOrderSyncSaleOrderFailedReport from "./AfsOrderSyncSaleOrderFailedReport";

const AfsOrderSyncTabsWrapper = (props) => {
    const [ Tab, setTab ] = useState("quotation_failed");
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "",
        Data: {}
    });
    const [loader, setLoader] = useState(false);

    // error message modal close
    const CloseErrorMessageModal = () => {
        setOrderViewerModal({
            ...OrderViewerModal,
            open: false,
            Title: "",
            Data: {}
        });
    };

    // TABS HEADING
    const Tabs = [
        { url: "quotation_failed", label: "Quotation Failed" },
        { url: "payment_failed", label: "Payment Failed" },
        { url: "sales_order_failed", label: "Sales Order Failed" },
    ];

    return (<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                {Tabs?.map((elm, index) => {
                    return(<li 
                        key={index}
                        className={Tab === elm?.url ? "active" : ""} 
                        onClick={() => setTab(elm?.url)}
                    >{elm?.label}</li>)
                })}
            </ul>
        </div>

        {Tab === "quotation_failed" ? (<AfsOrderSyncQuotationFailedReport 
            tab_url={"quotation_failed"} 
            OrderViewerModal={OrderViewerModal} 
            setOrderViewerModal={setOrderViewerModal} 
            setLoader={setLoader}
        />) 
        : Tab === "payment_failed" ? (<AfsOrderSyncPaymentFailedReport 
            tab_url={"payment_failed"} 
            OrderViewerModal={OrderViewerModal} 
            setOrderViewerModal={setOrderViewerModal} 
            setLoader={setLoader}
        />) : (<AfsOrderSyncSaleOrderFailedReport 
            tab_url={"sales_order_failed"} 
            OrderViewerModal={OrderViewerModal} 
            setOrderViewerModal={setOrderViewerModal} 
            setLoader={setLoader}
        />)}

        {OrderViewerModal?.open && (
            <Modal show={OrderViewerModal?.open} className="errorDetailsModal" onHide={()=> CloseErrorMessageModal()}>
                <Modal.Header closeButton>
                <Modal.Title>{OrderViewerModal?.Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: "auto"}}>
                    <p>
                        {OrderViewerModal?.Data === undefined ? "No Error Found" : OrderViewerModal?.Data}
                    </p>
                </Modal.Body>
            </Modal>
        )}

        {loader && (<CommonLoader />)}
    </section>)
}

export default AfsOrderSyncTabsWrapper;
import React from "react";
import { Badge } from "react-bootstrap";

const QuotationsStatus = (status, url) => {
    if(status !== null) {
        if(status === "CREATED") {
            if(url === "badge") {
                return(<Badge bg="success">Created</Badge>);
            } else {
                return("Created");
            };
        } else if(status === "PAYMENT_PENDING") {
            if(url === "badge") {
                return(<Badge bg="danger">Payment Pending</Badge>);
            } else {
                return("Payment Pending");
            };
        } else if(status === "PAID") {
            if(url === "badge") {
                return(<Badge bg="success">Paid</Badge>);
            } else {
                return("Paid");
            };
        } else if(status === "CANCELLED") {
            if(url === "badge") {
                return(<Badge bg="danger">Cancelled</Badge>);
            } else {
                return("Cancelled");
            };
        } else if(status === "PAYMENT_APPROVAL_PENDING") {
            if(url === "badge") {
                return(<Badge bg="danger">Payment Approval Pending</Badge>);
            } else {
                return("Payment Approval Pending");
            };
        } else if(status === "EXPIRED") {
            if(url === "badge") {
                return(<Badge bg="danger">Expired</Badge>);
            } else {
                return("Expired");
            };
        };
    } else {
        return "-";
    };
};
 
export default QuotationsStatus;
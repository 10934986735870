/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClearFormSetFutios, AfterSalesQuotationsTabs } from "../../../redux/actions/adminActions";
import AllQuotationsReport from "./AllQuotationsReport";
import CreatedQuotationReport from "./CreatedQuotationReport";
import PaymentPaidQuotationReport from "./PaymentPaidQuotationReport";
import PaymentCancelledQuotationReport from "./PaymentCancelledQuotationReport";
import CommonLoader from "../../Common/CommonLoader";
import PaymentApprovalPendingQuotationReport from "./PaymentApprovalPendingQuotationReport";
import ExpiredQuotationReport from "./ExpiredQuotationReport";

const QuotationsReport = (props) => {
    const { ClearFormSet, AfsQuotationsTabs, commonMainLoader } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
        };
    }, [ ClearFormSet ]);

    // select tabs
    const SelectTabs = (url) => {
        Dispatch(AfterSalesQuotationsTabs(url));
    };

    // TABS HEADING
    const Tabs = [
        { url: "all_quotation", label: "All Quotations" },
        { url: "created_quotation", label: "Created" },
        { url: "payment_approval_pending", label: "Payment Approval Pending" },
        { url: "paid_quotation", label: "Paid" },
        { url: "expired_quotation", label: "Expired" },
        { url: "cancelled_quotation", label: "Cancelled" },
    ];

    return (<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                {Tabs?.map((elm, index) => {
                    return(<li 
                        key={index}
                        className={AfsQuotationsTabs === elm?.url ? "active" : ""} 
                        onClick={() => SelectTabs(elm?.url)}
                    >{elm?.label}</li>)
                })}
            </ul>
        </div>
        
        {(AfsQuotationsTabs === "all_quotation" || AfsQuotationsTabs === undefined) ? (
            <AllQuotationsReport tab_url={"all_quotation"}/>
        ) : AfsQuotationsTabs === "created_quotation" ? (
            <CreatedQuotationReport tab_url={"created_quotation"} />
        ) : AfsQuotationsTabs === "payment_approval_pending" ? (
            <PaymentApprovalPendingQuotationReport tab_url={"payment_approval_pending"} />
        ) : AfsQuotationsTabs === "paid_quotation" ? (
            <PaymentPaidQuotationReport tab_url={"paid_quotation"} />
        ) : AfsQuotationsTabs === "expired_quotation" ? (
            <ExpiredQuotationReport tab_url={"expired_quotation"} />
        ) : (
            <PaymentCancelledQuotationReport tab_url={"cancelled_quotation"} />
        )}

        {commonMainLoader?.url === "add_payment_detail" && commonMainLoader?.loader && (<CommonLoader />)}
    </section>)
}

export default QuotationsReport;
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Form, OverlayTrigger, Tooltip, Spinner, Modal } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import ImportDataAPI from "../../../Api/ImportDataAPI";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SampleFile from "../../../assets/file/sample.xlsx";
import SampleAccesseriesFile from "../../../assets/file/accesseries.xlsx";
import Select from 'react-select';
import { IMPORT_STORE_API_URL, IMPORT_ACCESSORIES_STORE_API_URL } from "../../Shared/constant";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import CommonLoader from "../../Common/CommonLoader";
import CheckValid from "../../Common/CheckValid";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";

const StoreDetails = (props) => {
    const { RouteName } = props;
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const { device_id, wareHouselistall, accessToken, productlistall, selfInfo } = useSelector((state) => state.adminReducers);
    const [ Tab, setTab ] = useState("bike");
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true && elm?.warehouse_type === "E-Bikes, Transportation Charges")?.map((item, index)=>({key:item.id, value:item?.id , label:`${item?.warehouse_name} (${item?.warehouse_code}) - (${item?.display_name})`}));
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: {},
        Data: {}
    });
    const [LoaderFile, setLoaderFile] = useState(false);
    // bike store details state
    const [ selectWarehouse, setselectWarehouse ] = useState(null);
    const [ LoaderStart, setLoaderStart ] = useState(false);
    const [ AddLoaderStart, setAddLoaderStart ] = useState(false);
    const [ StockDetailsList, setStockDetailsList ] = useState({
        existing: [],
        new_added: [],
        new_existing: [],
        invalid_frame_numbers: [],
    });
    const [ FileGetStore, setFileGetStore ] = useState({});
    const [ FileName, setFileName ] = useState("");
    
    // Accessories store details state
    const [ selectAccessoriesWarehouse, setselectAccessoriesWarehouse ] = useState(null);
    const [ AccessoriesStockDetailsList, setAccessoriesStockDetailsList ] = useState({
        existing: [],
        new_added: [],
        new_existing: [],
        invalid_frame_numbers: [],
    });
    const [ FileGetAccessoriesStore, setFileGetAccessoriesStore ] = useState({});
    const [ FileAccessoriesName, setFileAccessoriesName ] = useState("");
    const [formData, setFormData] = useState({
        billing_number: "",
        attachment_file: {
            file_name: "",
            file_id: "",
        },
    });
    const [errorBillingNumber, setErrorBillingNumber] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const exceptThisCostSymbols = ["e", "E", "+", "-"];
    
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get all warehouse list list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[2]?.Edit || PermissionsAccess?.Store[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "warehouse_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all warehouse list list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[2]?.Edit || PermissionsAccess?.Store[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_list",
                "request" : { 
                    "status" : true, 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // wareehouse select
    const WareHouseSelect = (value) => {
        if(value !== null) {
            setselectWarehouse(value);
        } else {
            setselectWarehouse(null);
        };
    };

    // store details wareehouse select
    const WareHouseAccessoriesSelect = (value) => {
        if(value !== null) {
            setselectAccessoriesWarehouse(value);
        } else {
            setselectAccessoriesWarehouse(value);
        }
    };

    // import file
    const ImportFile = async (e, status) => {
        setLoaderStart(true);
        if(e !== undefined) {
            if(Tab === "bike") {
                const files = e?.target?.files[0];
                setFileGetStore(files);
                const formData = new FormData();
                formData.append("file", files, files.name);
                formData.append("warehouse_id ", selectWarehouse?.value);
                formData.append("is_add", status);
            
                const fileresponse = await ImportDataAPI(IMPORT_STORE_API_URL, accessToken, formData);
                if(fileresponse?.status === 200) {
                    toast.success(fileresponse?.message);
                    setFileName(files.name);
                    setStockDetailsList({
                        ...StockDetailsList,
                        existing: fileresponse?.data?.existing,
                        new_added: fileresponse?.data?.new_added,
                        new_existing: fileresponse?.data?.new_existing,
                        invalid_frame_numbers: fileresponse?.data?.invalid_frame_numbers,
                    });
                    setLoaderStart(false);
                    handleClose();
                    document.getElementById('fileInput').value = null;
                } else {
                    setLoaderStart(false);
                    // setStockDetailsList({
                    //     existing: [],
                    //     new_added: [],
                    //     new_existing: [],
                    // });
                    // setFileName("");
                    setLoaderStart(false);
                    toast.error(fileresponse?.message);
                    document.getElementById('fileInput').value = null;

                    if(fileresponse?.message === "User is not authorized." && fileresponse?.status === 400) {
                        CommonLogout(accessToken, Dispatch);
                        localStorage?.removeItem("access_token");
                    };
                };
            } else {
                const files = e?.target?.files[0];
                setFileGetAccessoriesStore(files);
                const formData = new FormData();
                formData.append("file", files, files.name);
                formData.append("warehouse_id ", selectAccessoriesWarehouse?.value);
                formData.append("is_add", status);

                const fileresponse = await ImportDataAPI(IMPORT_ACCESSORIES_STORE_API_URL, accessToken, formData);
                if(fileresponse?.status === 200) {
                    toast.success(fileresponse?.message);
                    setFileAccessoriesName(files.name);
                    setAccessoriesStockDetailsList({
                        ...AccessoriesStockDetailsList,
                        existing: fileresponse?.data?.existing,
                        new_added: fileresponse?.data?.new_added,
                        new_existing: fileresponse?.data?.new_existing,
                        invalid_frame_numbers: fileresponse?.data?.invalid_frame_numbers,
                    });
                    setLoaderStart(false);
                    handleClose();
                    document.getElementById('fileInput').value = null;
                } else {
                    setLoaderStart(false);
                    // setStockDetailsList({
                    //     existing: [],
                    //     new_added: [],
                    //     new_existing: [],
                    // });
                    // setFileName("");
                    setLoaderStart(false);
                    toast.error(fileresponse?.message);
                    if(fileresponse?.message === "User is not authorized." && fileresponse?.status === 400) {
                        CommonLogout(accessToken, Dispatch);
                        localStorage?.removeItem("access_token");
                    };
                };
            }
        } else {
            if(Tab === "bike") {
                setAddLoaderStart(true);
                const files = FileGetStore;
                const formDatas = new FormData();
                formDatas.append("file", files, files.name);
                formDatas.append("warehouse_id ", selectWarehouse?.value);
                formDatas.append("is_add", status);
                formDatas.append("bill_no", formData?.billing_number);
                formDatas.append("bill_receipt", formData?.attachment_file?.file_name);
                formDatas.append("bill_receipt_id", formData?.attachment_file?.file_id);

                const fileresponse = await ImportDataAPI(IMPORT_STORE_API_URL, accessToken, formDatas);
                if(fileresponse?.status === 200) {
                    toast.success(fileresponse?.message);
                    setFileName("");
                    setStockDetailsList({
                        ...StockDetailsList,
                        existing: [],
                        new_added: [],
                        new_existing: [],
                        invalid_frame_numbers: [],
                    });
                    setFormData({
                        ...formData,
                        billing_number: "",
                        attachment_file: {
                            file_name: "",
                            file_id: "",
                        },
                    });
                    setselectWarehouse(null);
                    ResetAccessoriesDetails();
                    setLoaderStart(false);
                    setAddLoaderStart(false);
                    handleClose();
                    // document.getElementById('fileInput').value = null;
                } else {
                    setLoaderStart(false);
                    setAddLoaderStart(false);
                    toast.error(fileresponse?.message);
                    // setStockDetailsList({
                    //     existing: [],
                    //     new_added: [],
                    //     new_existing: [],
                    // });
                    // setFileName("");
                    if(fileresponse?.message === "User is not authorized." && fileresponse?.status === 400) {
                        CommonLogout(accessToken, Dispatch);
                        localStorage?.removeItem("access_token");
                    };
                };
            } else {
                const files = FileGetAccessoriesStore;
                const formData = new FormData();
                formData.append("file", files, files.name);
                formData.append("warehouse_id ", selectAccessoriesWarehouse?.value);
                formData.append("is_add", status);
                const fileresponse = await ImportDataAPI(IMPORT_ACCESSORIES_STORE_API_URL, accessToken, formData);
                if(fileresponse?.status === 200) {
                    toast.success(fileresponse?.message);
                    setFileAccessoriesName(files.name);
                    setAccessoriesStockDetailsList({
                        ...AccessoriesStockDetailsList,
                        existing: fileresponse?.data?.existing,
                        new_added: fileresponse?.data?.new_added,
                        new_existing: fileresponse?.data?.new_existing,
                        invalid_frame_numbers: fileresponse?.data?.invalid_frame_numbers,
                    });
                    setLoaderStart(false);
                    handleClose();
                    // document.getElementById('fileInput').value = null;
                } else {
                    setLoaderStart(false);
                    toast.error(fileresponse?.message);
                    // setStockDetailsList({
                    //     existing: [],
                    //     new_added: [],
                    //     new_existing: [],
                    // });
                    // setFileName("");
                    if(fileresponse?.message === "User is not authorized." && fileresponse?.status === 400) {
                        CommonLogout(accessToken, Dispatch);
                        localStorage?.removeItem("access_token");
                    };
                };
            }
        };
    };

    // get same uplaoded product
    function countSameValues(arrays) {
        const valueCount = {}; // Object to store counts of each value
    
        // Iterate through each array
        arrays.forEach(array => {
            // Iterate through each element in the array
            const ProductCodeArrayNew = [array[4]];

            ProductCodeArrayNew.forEach(element => {
                if (!valueCount[element]) {
                    // If the value is not already counted, initialize its count
                    valueCount[element] = { count: 1, arrays: [ProductCodeArrayNew] };
                } else {
                    // If the value is already counted, increment its count
                    valueCount[element].count++;
                    // Store the array where the value is found
                    if (!valueCount[element].arrays.includes(ProductCodeArrayNew)) {
                        valueCount[element].arrays.push(ProductCodeArrayNew);
                    }
                }
            });
        });
    
        // Filter values with count > 1 (meaning they appeared in more than one array)
        const sameValues = Object.entries(valueCount)
            .filter(([key, value]) => value.count > 0)
            .map(([key, value]) => ({
                value: key,
                count: value.count,
                arrays: value.arrays
            }));
    
        return sameValues;
    };

    const GetSameProduct = countSameValues(StockDetailsList?.new_added);



    // get same uplaoded product
    function countAccessoriesSameValues(arrays) {
        const valueCount = {}; // Object to store counts of each value
    
        // Iterate through each array
        arrays.forEach(array => {
            // Iterate through each element in the array
            const ProductCodeArrayNew = [array[1]];

            ProductCodeArrayNew.forEach(element => {
                if (!valueCount[element]) {
                    // If the value is not already counted, initialize its count
                    valueCount[element] = { count: 1, arrays: [ProductCodeArrayNew] };
                } else {
                    // If the value is already counted, increment its count
                    valueCount[element].count++;
                    // Store the array where the value is found
                    if (!valueCount[element].arrays.includes(ProductCodeArrayNew)) {
                        valueCount[element].arrays.push(ProductCodeArrayNew);
                    }
                }
            });
        });
    
        // Filter values with count > 1 (meaning they appeared in more than one array)
        const sameValues = Object.entries(valueCount)
            .filter(([key, value]) => value.count > 0)
            .map(([key, value]) => ({
                value: key,
                count: value.count,
                arrays: value.arrays
            }));
    
        return sameValues;
    };

    const GetSameAccesorriesProduct = countAccessoriesSameValues(AccessoriesStockDetailsList?.new_added);

    // add store details
    const AddDetails = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
        });
    };

    const AddProductDetails = (e) => {
        if(formData?.billing_number !== "") {
            ImportFile(e, 'yes');
        } else {
            CheckValid(formData?.billing_number, { type: 'billing_number', error: setErrorBillingNumber });
        };
    };

    // upload attachments
    const UploadImage = async (e) => {
        const files = e.target.files[0];
        if (files) {
            setLoaderFile(true);
            const formDatas = new FormData();
            formDatas.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formDatas);
            if (fileresponse?.status === 200) {
                setFormData({
                    ...formData,
                    attachment_file: {
                        ...formData?.attachment_file,
                        file_name: fileresponse?.name,
                        file_id: fileresponse?.id,
                    },
                });
                setTimeout(() => {
                    setLoaderFile(false);
                }, 1000);
                document.getElementById('fileInput').value = null;
            } else {
                setLoaderFile(false);
                setFormData({
                    ...formData,
                    attachment_file: {
                        ...formData?.attachment_file,
                        file_name: "",
                        file_id: "",
                    },
                });
            };
        };
    };

    // reset details
    const ResetDetails = () => {
        setStockDetailsList({
            existing: [],
            new_added: [],
            new_existing: [],
            invalid_frame_numbers: [],
        });
        setselectWarehouse(null);
        setFileGetStore({});
        setFileName("");
    };

    // reset details
    const ResetAccessoriesDetails = () => {
        setAccessoriesStockDetailsList({
            existing: [],
            new_added: [],
            new_existing: [],
            invalid_frame_numbers: [],
        });
        setselectAccessoriesWarehouse(null);
        setFileGetAccessoriesStore({});
        setFileAccessoriesName("");
    };

    // close modal
    const handleClose = () => {
        setShowModalNew({...showModalNew, open: false});
    };

    const GetProductName = (product_code) => {
        return productlistall?.filter((elm) => product_code === elm?.product_code)[0]?.product_name;
    };

    return(<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                <li  className={Tab === "bike" ? "active" : ""} onClick={() => setTab("bike")}>Bike</li>
                {/* <li className={Tab === "accessories" ? "active" : ""} onClick={() => setTab("accessories")}>Accessories</li> */}
            </ul>
        </div>
        {Tab === "bike" ? (<>
            <div className="card-header-New" >
                <div className="filtr_Form" style={{ marginBottom: "0px" }}>
                    <div className="formloginset">
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group  >
                                    {/* <Form.Label>Select Warehouse</Form.Label> */}
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select 
                                            value={selectWarehouse}
                                            onChange={(e) => WareHouseSelect(e)} 
                                            isClearable 
                                            options={optionsWarehouse}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            placeholder="Select Warehouse"
                                            styles={customStyles}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Store[2]?.Edit) && (<div className="col-md-3">
                                <Form.Group  >
                                    {/* <Form.Label>&nbsp;</Form.Label> */}
                                    {FileName === "" ? (<>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 100 }}
                                            overlay={<Tooltip style={{ position: "fixed", display: selectWarehouse === null ? "" : "none" }} id="button-tooltip" {...props}>
                                            Before upload sheet please select warehouse !
                                            </Tooltip>}
                                            disabled={selectWarehouse === null ? true : false}
                                        >
                                            <label id="UploadCmnBTN" className="btn head_cmn_btn UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                                <i className="bi bi-upload"></i>
                                                <input type="file" id="fileInput" disabled={selectWarehouse === null ? true : false} accept=".xlsx, .xls" onChange={(e) => ImportFile(e, 'no')} />
                                                Upload Sheet
                                            </label>
                                        </OverlayTrigger>
                                        {LoaderStart && (<div className="UploadLoader">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>)}
                                    </>) : (<div className="uplaodedFilename">
                                        <i className="bi bi-filetype-xlsx"></i>
                                        {FileName}
                                        <i className="bi bi-x-circle-fill" onClick={() => ResetDetails()}></i>
                                    </div>)}
                                </Form.Group>
                            </div>)}
                            <div className="col-md-3" style={{ width: "auto", float: "right", margin: "auto 0 auto auto" }}>
                                <Form.Group  >
                                    {/* <Form.Label>&nbsp;</Form.Label> */}
                                    <a href={SampleFile} className="btn exportbtn" style={{ width: "auto", marginTop: "0px" }}>
                                        <i className="bi bi-filetype-xlsx"></i>
                                        Download Format
                                    </a>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tableView">
                <Scrollbars 
                    style={{ height: "calc(100vh - 198px)" }} 
                    renderView={props => <div {...props} className="view"/>}
                    className="ScrollbarsSidebar"
                >
                    <Table bordered   >
                        <thead>
                            <tr>
                                <th>SNO</th>
                                <th>FrameNumber</th>
                                <th>Charger Number</th>
                                <th>Battery Number</th>
                                <th>Motor Number</th>
                                <th>Product Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {StockDetailsList?.new_added?.length === 0 ? (<tr>
                                    <td colSpan={7} style={{ textAlign: "center" }}>Store details not found</td>
                                </tr>) : StockDetailsList?.new_added?.map((elm, index) => {
                                return(<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{elm[0]}</td>
                                    <td>{elm[1]}</td>
                                    <td>{elm[2]}</td>
                                    <td>{elm[3]}</td>
                                    <td>{elm[4]}</td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                    <div className="btnGroupswraps" id="btnGroupswraps">
                        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Store[2]?.Edit) && (<Button className="Addbtn" disabled={StockDetailsList?.new_added?.length === 0} onClick={() => AddDetails()}>Add</Button>)}
                        <Button className="Resetbtn" onClick={() => ResetDetails()}>Reset</Button>
                    </div>

                    {StockDetailsList?.existing?.length > 0 && (<div className="exsting_newdetails">
                        <h4 style={{ color: "red" }}>The frame number already exists in the records.</h4>
                        <Table bordered   >
                            <thead>
                                <tr>
                                    <th>SNO</th>
                                    <th>FrameNumber</th>
                                    <th>Charger Number</th>
                                    <th>Battery Number</th>
                                    <th>Motor Number</th>
                                    <th>Product Code</th>
                                    {/* <th>WarehouseCode</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {StockDetailsList?.existing?.map((elm, index) => {
                                    return(<tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{elm[0]}</td>
                                        <td>{elm[1]}</td>
                                        <td>{elm[2]}</td>
                                        <td>{elm[3]}</td>
                                        <td>{elm[4]}</td>
                                        {/* <td>{elm[5]}</td> */}
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </div>)}

                    {StockDetailsList?.new_existing?.length > 0 && (<div className="exsting_newdetails">
                        <h4 style={{ color: "red" }}>Product code not found in the records.</h4>
                        <Table bordered   >
                            <thead>
                                <tr>
                                    <th>SNO</th>
                                    <th>FrameNumber</th>
                                    <th>Charger Number</th>
                                    <th>Battery Number</th>
                                    <th>Motor Number</th>
                                    <th>Product Code</th>
                                    {/* <th>WarehouseCode</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {StockDetailsList?.new_existing?.map((elm, index) => {
                                    return(<tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{elm[0]}</td>
                                        <td>{elm[1]}</td>
                                        <td>{elm[2]}</td>
                                        <td>{elm[3]}</td>
                                        <td>{elm[4]}</td>
                                        {/* <td>{elm[5]}</td> */}
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </div>)}

                    {StockDetailsList?.invalid_frame_numbers?.length > 0 && (<div className="exsting_newdetails">
                        <h4 style={{ color: "red" }}>Invalid frame numbers found in the records.</h4>
                        <Table bordered   >
                            <thead>
                                <tr>
                                    <th>SNO</th>
                                    <th>FrameNumber</th>
                                    <th>Charger Number</th>
                                    <th>Battery Number</th>
                                    <th>Motor Number</th>
                                    <th>Product Code</th>
                                    {/* <th>WarehouseCode</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {StockDetailsList?.invalid_frame_numbers?.map((elm, index) => {
                                    return(<tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{elm[0]}</td>
                                        <td>{elm[1]}</td>
                                        <td>{elm[2]}</td>
                                        <td>{elm[3]}</td>
                                        <td>{elm[4]}</td>
                                        {/* <td>{elm[5]}</td> */}
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </div>)}
                </Scrollbars>
            </div>
        </>) : (<>
            <div className="card-header-New" >
                <div className="filtr_Form" style={{ marginBottom: "0px" }}>
                    <div className="formloginset">
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group  >
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select 
                                            value={selectAccessoriesWarehouse}
                                            onChange={(e) => WareHouseAccessoriesSelect(e)} 
                                            isClearable 
                                            options={optionsWarehouse}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            placeholder="Select Warehouse"
                                            styles={customStyles}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group  >
                                    {FileAccessoriesName === "" ? (<>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 100 }}
                                            overlay={<Tooltip style={{ position: "fixed", display: selectAccessoriesWarehouse === null ? "" : "none" }} id="button-tooltip" {...props}>
                                            Before upload sheet please select warehouse !
                                            </Tooltip>}
                                            disabled={selectAccessoriesWarehouse === null ? true : false}
                                        >
                                            <label id="UploadCmnBTN" className="btn head_cmn_btn UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                                <i className="bi bi-upload"></i>
                                                <input type="file" id="fileInput" disabled={selectAccessoriesWarehouse === null ? true : false} accept=".xlsx, .xls" onChange={(e) => ImportFile(e, 'no')} />
                                                Upload Sheet
                                            </label>
                                        </OverlayTrigger>
                                        {LoaderStart && (<div className="UploadLoader">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>)}
                                    </>) : (<div className="uplaodedFilename">
                                        <i className="bi bi-filetype-xlsx"></i>
                                        {FileAccessoriesName}
                                        <i className="bi bi-x-circle-fill" onClick={() => ResetAccessoriesDetails()}></i>
                                    </div>)}
                                </Form.Group>
                            </div>
                            <div className="col-md-3" style={{ width: "auto", float: "right", margin: "auto 0 auto auto" }}>
                                <Form.Group  >
                                    {/* <Form.Label>&nbsp;</Form.Label> */}
                                    <a href={SampleAccesseriesFile} className="btn exportbtn" style={{ width: "auto", marginTop: "0px" }}>
                                        <i className="bi bi-filetype-xlsx"></i>
                                        Download Format
                                    </a>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tableView">
                <Scrollbars 
                    style={{ height: "calc(100vh - 198px)" }} 
                    renderView={props => <div {...props} className="view"/>}
                    className="ScrollbarsSidebar"
                >
                    <Table bordered   >
                        <thead>
                            <tr>
                                <th>SNO</th>
                                <th>Serial Number</th>
                                <th>Product Code</th>
                                <th>ExpMonth</th>
                                <th>ExpYear</th>
                                <th>BatchNumber</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AccessoriesStockDetailsList?.new_added?.length === 0 ? (<tr>
                                    <td colSpan={7} style={{ textAlign: "center" }}>Store details not found</td>
                                </tr>) : AccessoriesStockDetailsList?.new_added?.map((elm, index) => {
                                return(<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{elm[0]}</td>
                                    <td>{elm[1]}</td>
                                    <td>{elm[2]}</td>
                                    <td>{elm[3]}</td>
                                    <td>{elm[4] === "" ? "-" : elm[4]}</td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                    <div className="btnGroupswraps" id="btnGroupswraps">
                        <Button className="Addbtn" disabled={AccessoriesStockDetailsList?.new_added?.length === 0} onClick={() => AddDetails()}>Add</Button>
                        <Button className="Resetbtn" onClick={() => ResetAccessoriesDetails()}>Reset</Button>
                    </div>

                    {AccessoriesStockDetailsList?.existing?.length > 0 && (<div className="exsting_newdetails">
                        <h4 style={{ color: "red" }}>The frame number already exists in the records.</h4>
                        <Table bordered   >
                            <thead>
                                <tr>
                                    <th>SNO</th>
                                    <th>Serial Number</th>
                                    <th>Product Code</th>
                                    <th>ExpMonth</th>
                                    <th>ExpYear</th>
                                    <th>BatchNumber</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AccessoriesStockDetailsList?.existing?.map((elm, index) => {
                                    return(<tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{elm[0]}</td>
                                        <td>{elm[1]}</td>
                                        <td>{elm[2]}</td>
                                        <td>{elm[3]}</td>
                                        <td>{elm[4] === "" ? "-" : elm[4]}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </div>)}

                    {AccessoriesStockDetailsList?.new_existing?.length > 0 && (<div className="exsting_newdetails">
                        <h4 style={{ color: "red" }}>Product code not found in the records.</h4>
                        <Table bordered   >
                            <thead>
                                <tr>
                                    <th>SNO</th>
                                    <th>Serial Number</th>
                                    <th>Product Code</th>
                                    <th>ExpMonth</th>
                                    <th>ExpYear</th>
                                    <th>BatchNumber</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AccessoriesStockDetailsList?.new_existing?.map((elm, index) => {
                                    return(<tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{elm[0]}</td>
                                        <td>{elm[1]}</td>
                                        <td>{elm[2]}</td>
                                        <td>{elm[3]}</td>
                                        <td>{elm[4] === "" ? "-" : elm[4]}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </div>)}
                </Scrollbars>
            </div>
        </>)}
        

        {/* {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)} */}

        <Modal className="commonModal" show={showModalNew?.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{ width: "100%"}}>
                    Are you sure?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="notformsection">
                    <p>Do you really want to add the <b>product</b>?</p>
                    {Tab === "bike" ? (<ul>
                        {GetSameProduct?.map((elm, index) => {
                            return(<li key={index}><span>{elm?.count}</span> {GetProductName(elm?.value)}</li>)
                        })}
                    </ul>) : (<ul>
                        {GetSameAccesorriesProduct?.map((elm, index) => {
                            return(<li key={index}><span>{elm?.count}</span> {GetProductName(elm?.value)}</li>)
                        })}
                    </ul>)}
                    <div className="productbilladdform formloginset">
                        <Form.Group className="mb-3" >
                            <Form.Label>Billing Number<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                className="inputBoxs"
                                value={formData?.billing_number}
                                onChange={(e) => setFormData({ ...formData, billing_number: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'billing_number', error: setErrorBillingNumber })}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                }}
                                placeholder="Enter Billing Number"
                            />
                            {errorBillingNumber !== "" && <span className="error_medotory">{errorBillingNumber}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Attachment Proof</Form.Label>
                            {formData?.attachment_file?.file_id === "" ? (<label id="UploadCmnBTN" className="btn head_cmn_btn w-100 UploadCmnBTN UploadCmnAttchmentBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                <i className="bi bi-upload"></i>
                                <input type="file" name={"Attachments"} id="fileInput" onChange={(e) => UploadImage(e)} accept=".png, .jpg, .pdf" />
                                Upload Attachment Proof
                                {LoaderFile && (<Spinner />)}
                            </label>) : (<div className="uplaodedFilename w-100">
                                <i className="bi bi-file-earmark-text"></i>
                                {formData?.attachment_file?.file_name}
                                <i className="bi bi-x-circle-fill"
                                    onClick={() => {
                                        setFormData({
                                            ...formData,
                                            attachment_file: {
                                                ...formData?.attachment_file,
                                                file_name: "",
                                                file_id: "",
                                            },
                                        });
                                    }}
                                ></i>
                            </div>)}
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    No
                </Button>
                <Button variant="primary" className="addbtncmn" onClick={() => AddProductDetails()}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
        {AddLoaderStart && (<CommonLoader />)}
    </section>)
}

export default StoreDetails;
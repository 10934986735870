/* eslint-disable */
import React, { useState, useEffect } from "react";
import AfterSalesPendingInvoiceOrders from "./AfterSalesPendingInvoiceOrders";
import AfterSalesPendingDispatchOrders from "./AfterSalesPendingDispatchOrders";
import AfterSalesPendingDeliveryOrders from "./AfterSalesPendingDeliveryOrders";
import AfterSalesDeliveredOrders from "./AfterSalesDeliveredOrders";
import OrderStatusModel from "../../Common/OrderStatusModel";
import { useDispatch, useSelector } from "react-redux";
import { ClearFormSetFutios, AfterSalesOrderTabs } from "../../../redux/actions/adminActions";
import AfterSalesCancelledOrders from "./AfterSalesCancelledOrders";
import AfterSalesReturnedOrders from "./AfterSalesReturnedOrders";
import AllAfterSalesOrders from "./AllAfterSalesOrders";

const AfterSalesOrders = (props) => {
    const { ClearFormSet, AfsTabs } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    //Order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
        };
    }, [ ClearFormSet ]);

    // select tabs
    const SelectTabs = (url) => {
        Dispatch(AfterSalesOrderTabs(url));
    };

    // TABS HEADING
    const Tabs = [
        { url: "all_afs_orders", label: "All Order" },
        { url: "pending_invoicing", label: "Pending Invoicing" },
        { url: "pending_dispatch", label: "Pending Dispatch" },
        { url: "pending_delivery", label: "Pending Delivery" },
        { url: "delivered", label: "Delivered" },
        { url: "cancelled", label: "Cancelled" },
        { url: "returned", label: "Returned" }
    ];

    return (<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                {Tabs?.map((elm, index) => {
                    return(<li 
                        key={index}
                        className={AfsTabs === elm?.url ? "active" : ""} 
                        onClick={() => SelectTabs(elm?.url)}
                    >{elm?.label}</li>)
                })}
            </ul>
        </div>
        
        {(AfsTabs === "all_afs_orders" || AfsTabs === undefined) ? (
        <AllAfterSalesOrders 
            tab_url={"all_afs_orders"}
            OrderNewModel={OrderNewModel} 
            setOrderNewModel={setOrderNewModel}
        />) : AfsTabs === "pending_invoicing" ? (
        <AfterSalesPendingInvoiceOrders 
            tab_url={"pending_invoicing"}
            OrderNewModel={OrderNewModel} 
            setOrderNewModel={setOrderNewModel}
        />) : AfsTabs === "pending_dispatch" ? (
        <AfterSalesPendingDispatchOrders 
            tab_url={"pending_dispatch"}
            OrderNewModel={OrderNewModel} 
            setOrderNewModel={setOrderNewModel}
        />) : AfsTabs === "pending_delivery" ? (
        <AfterSalesPendingDeliveryOrders
            tab_url={"pending_delivery"} 
            OrderNewModel={OrderNewModel} 
            setOrderNewModel={setOrderNewModel}
        />) : AfsTabs === "delivered" ? (
        <AfterSalesDeliveredOrders 
            tab_url={"delivered"} 
            OrderNewModel={OrderNewModel} 
            setOrderNewModel={setOrderNewModel}
        />) : AfsTabs === "cancelled" ?
        (<AfterSalesCancelledOrders
            tab_url={"cancelled"}  
            OrderNewModel={OrderNewModel} 
            setOrderNewModel={setOrderNewModel}
        />) : (<AfterSalesReturnedOrders 
            tab_url={"returned"}  
            OrderNewModel={OrderNewModel} 
            setOrderNewModel={setOrderNewModel}
        />)}

        {/* ORDER LOG MODAL */}
        {OrderNewModel?.open && (<OrderStatusModel 
            setOrderNewModel={setOrderNewModel} 
            OrderNewModel={OrderNewModel} 
        />)}
    </section>)
}

export default AfterSalesOrders;
/* eslint-disable */
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";

const CommonAddressTextField = (props) => {
    const { Label, isMendatory, MainState, setMainState, Address, ErrorAddress, setErrorAddress, MaxLength } = props;
    const [ addressLimit, setAddressLimit ] = React.useState(0);

    // UPDATE ADDRESS LIMIT
    useEffect(() => {
        setAddressLimit((MainState?.[Address] === "" || MainState?.[Address] === null) ? 0 : MainState?.[Address]?.length);
    }, [ MainState?.[Address] ]);

    // ONCHNAGE ADDRESS FUNCTION
    const UpdateAddress = (value) => {
        const AddressTrim = value?.slice(0, MaxLength);
        setMainState((prevState) => ({
            ...prevState,
            [Address]: AddressTrim,
        }));

        if (AddressTrim.length <= MaxLength) {
            setAddressLimit(AddressTrim.length);
        };
    };

    return (<Form.Group className="mb-3" >
        <Form.Label>{Label}{isMendatory &&(<span className="mendatory_star">*</span>)}</Form.Label>
        <div className="commonAddress_Valid">
            <Form.Control 
                type="text" 
                as="textarea" 
                rows={3}
                value={MainState?.[Address]} 
                onChange={(e) => UpdateAddress(e.target.value)}
                onKeyUp={(e) => isMendatory && CheckValid(e.target.value, {type: Address, error: setErrorAddress})}
                onKeyDown={EmptySpaceFieldValid}
                placeholder={`Enter ${Address}`} 
            />
            <span className="address_limit">{addressLimit}/{MaxLength}</span>
        </div>
        {isMendatory && (<React.Fragment>
            {ErrorAddress !== "" &&<span className="error_medotory">{ErrorAddress}</span>}
        </React.Fragment>)}
    </Form.Group>);
}

export default CommonAddressTextField;
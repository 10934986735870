/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form, Badge, Image, Table, Collapse } from 'react-bootstrap';
import CorrectIcon from "../../assets/img/CorrectIcon.png"
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonDateTime from "./CommonDateTime";
import { StartMainCommonLoader, getOrderLogList } from "../../redux/actions/adminActions";
import CommonLoader from "./CommonLoader";
import { TimeSense } from "./TimeSense";
import moment from "moment";
import QuotationsStatus from "./QuotationsStatus";

const OrderStatusModel = (props) => {
    const { OrderNewModel, setOrderNewModel, ConfirmOrder } = props;
    const { device_id, AllOrderLogList, commonMainLoader } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();

    const handleClose = () => {
        Dispatch(getOrderLogList({}));
        setOrderNewModel({ ...setOrderNewModel, open: false });
    };

    const getOrderStatus = (status) => {
        if (status === "DELIVERED") {
            return {
                orderStatus: "Inward completed"
            }
        } if (status === "SO_APPROVED" || status === "SO_HALF_APPROVAL") {
            return {
                orderStatus: "SO Approved"
            }
        } if (status === "AC_APPROVED") {
            return {
                orderStatus: "Accounts Approved"
            }
        } if (status === "WH_APPROVED") {
            return {
                orderStatus: "Warehouse Approved"
            }
        } if (status === "ST_APPROVED") {
            return {
                orderStatus: "Pending for Dispatch"
            }
        } if (status === "OPEN") {
            return {
                orderStatus: "Order Created"
            }
        } if (status === "REPLACE") {
            return {
                orderStatus: "Order Replace"
            }
        } if (status === "CANCELLED") {
            return {
                orderStatus: "Cancelled"
            }
        } if (status === "RETURN") {
            return {
                orderStatus: "Order Returned"
            }
        } if(status === "REPLACE_REQUEST") {
            return {
                orderStatus: "Order Replace Request"
            }
        } if (status === "SO_APPROVED") {
            return {
                orderStatus: "SO Partially Approved"
            }
        } if (status === "WH_PENDING") {
            return {
                orderStatus: "Warehouse Pending"
            }
        } if (status === "DISPATCHED") {
            return {
                orderStatus: "Order Dispatched"
            }
        } if (status === "REJECTED") {
            return {
                orderStatus: "Rejected"
            }
        } if(status === "RETURN_IN_TRANSIT") {
            return {
                orderStatus: "Return In Transit"
            }
        } if(status === "CANCELLED_REQUEST") {
            return {
                orderStatus: "Cancelled Request"
            }
        } if(status === "RETURN_REQUEST") {
            return {
                orderStatus: "Return Request"
            }
        } if(status === "INVOICE_PENDING") {
            return {
                orderStatus: "Pending Invoicing"
            }
        } if(status === "DISPATCH_PENDING") {
            return {
                orderStatus: "Pending Dispatch"
            }
        }
    };

    // get user
    const GetUserData = (id) => {
        const user = AllOrderLogList?.users?.filter((elm) => elm?.id === id)[0];
        return {
            userName: user?.full_name,
        };
    };
    
    //get updateat date only
    const UpdatedAt=(updated_at)=>{
        const timestamp = new Date(updated_at);
        const dateOnly = timestamp.toISOString().split('T')[0];
        return {
            updated_at:dateOnly
        }
    }

    // get order_log list all
    useEffect(() => {
        if (OrderNewModel?.Data?.length > 0) {
            Dispatch(StartMainCommonLoader({
                url: OrderNewModel?.title === "AFS Order Log" ? "afs_order_logs" : OrderNewModel?.title === "AFS Quotation Log" ? "quotation_logs" : "order_logs",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": OrderNewModel?.title === "AFS Order Log" ? "afs_order_logs" : OrderNewModel?.title === "AFS Quotation Log" ? "quotation_logs" : "order_logs",
                "request": {},
                "DeviceId": device_id
            };
            if(OrderNewModel?.title === "AFS Order Log" || OrderNewModel?.title === "Order Log") {
                param.request.order_id = OrderNewModel?.Data
            } else {
                param.request.quotation_id = OrderNewModel?.Data
            };
            
            wsSend_request(websocket, param);
        }
    }, [OrderNewModel, device_id]);

    const LoadingLogs = (commonMainLoader?.url === "afs_order_logs" || commonMainLoader?.url === "order_logs" || commonMainLoader?.url === "quotation_logs") && commonMainLoader?.loader;

    return (<Modal
        className="order_logs_wrapper"
        show={OrderNewModel?.open}
        onHide={handleClose}
    >
        <Modal.Header closeButton>
            <Modal.Title style={{ width: OrderNewModel?.title === "Are you sure?" ? "100%" : "" }}>{OrderNewModel?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Scrollbars
                style={{ height: 300 }}
                renderView={props => <div {...props} className="view" />}
                className="ScrollbarsSidebar"
            >
                <div className="container-fluid ps-0" style={{ whiteSpace: 'nowrap' }}>
                    {(OrderNewModel?.title === "AFS Order Log" || OrderNewModel?.title === "Order Log") ? (<React.Fragment>
                        {Object?.keys(AllOrderLogList)?.length > 0 && AllOrderLogList?.order_logs?.slice().reverse().map((log, index, array) => (
                            <div className="row justify-content-around " key={log.id}>
                                <div className="col-12 d-flex">
                                    <div className="col-1 completed-icon">
                                        {log.order_status && (
                                            <span className="completed">
                                                <Image src={CorrectIcon} height={16} width={16} />
                                            </span>
                                        )}
                                        {index !== array.length - 1 && (
                                            <div className="vertical"></div>
                                        )}
                                    </div>
                                    <div className="col-4">
                                        <span style={{ display: "block", fontSize: '11px' }}>{getOrderStatus(log?.order_status)?.orderStatus}</span>
                                        <small style={{ display: "block", fontSize: '11px' }}>{CommonDateTime(log?.created_at, "LL", "show")}</small>
                                    </div>
                                    <div className="col-3">
                                        <div className="" style={{ display: "block", fontSize: '11px', wordBreak: "break-all", whiteSpace: "break-spaces", paddingRight: "10px" }}>
                                            {GetUserData(log.author)?.userName}
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <span style={{ display: "block", fontSize: '11px' }}>Updated At</span>
                                        <span className="" style={{ display: "block", fontSize: '11px' }}>{UpdatedAt(log?.updated_at)?.updated_at}</span>
                                    </div>
                                    <div className="col-2">
                                        <span style={{ display: "block", fontSize: '11px' }}>Time</span>
                                        <small style={{ display: "block", fontSize: '11px' }}>{moment(log?.updated_at).format('h:mm a')}</small>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {LoadingLogs && "Please Wait...."}
                        {AllOrderLogList?.order_logs?.length === 0 && ("Order log not found")}
                    </React.Fragment>) : (<React.Fragment>
                        {Object?.keys(AllOrderLogList)?.length > 0 && AllOrderLogList?.quotation_logs?.slice().reverse().map((log, index, array) => (
                            <div className="row justify-content-around " key={log.id}>
                                <div className="col-12 d-flex">
                                    <div className="col-1 completed-icon">
                                        {log.quotation_status && (
                                            <span className="completed">
                                                <Image src={CorrectIcon} height={16} width={16} />
                                            </span>
                                        )}
                                        {index !== array.length - 1 && (
                                            <div className="vertical"></div>
                                        )}
                                    </div>
                                    <div className="col-4">
                                        <span style={{ display: "block", fontSize: '11px' }}>
                                            {QuotationsStatus(log?.quotation_status, "no_badge")}
                                        </span>
                                        <small style={{ display: "block", fontSize: '11px' }}>{CommonDateTime(log?.created_at, "LL", "show")}</small>
                                    </div>
                                    <div className="col-3">
                                        <div className="" style={{ display: "block", fontSize: '11px', wordBreak: "break-all", whiteSpace: "break-spaces", paddingRight: "10px" }}>
                                            {GetUserData(log.author)?.userName}
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <span style={{ display: "block", fontSize: '11px' }}>Updated At</span>
                                        <span className="" style={{ display: "block", fontSize: '11px' }}>{UpdatedAt(log?.updated_at)?.updated_at}</span>
                                    </div>
                                    <div className="col-2">
                                        <span style={{ display: "block", fontSize: '11px' }}>Time</span>
                                        <small style={{ display: "block", fontSize: '11px' }}>{moment(log?.updated_at).format('h:mm a')}</small>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {LoadingLogs && "Please Wait...."}
                        {AllOrderLogList?.order_logs?.length === 0 && ("Order log not found")}
                    </React.Fragment>)}
                </div>
            </Scrollbars>
        </Modal.Body>
    </Modal>)
}

export default OrderStatusModel;
/* eslint-disable */
import React, { useContext, useState } from "react";
import GetCurrentTableSRNo from "../GetCurrentTableSRNo";
import { Button, Badge, Image, Collapse, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AFTER_SALES_ORDERS_URL, FILE_AUTH_URL, QUOTATIONS_REPORT_DETAILS_URL, PAYMENT_APPROVAL_QUOTATIONS_REPORT_DETAILS_URL } from "../../Shared/constant";
import CommonDateTime from "../CommonDateTime";
import { TimeSense } from "../TimeSense";
import { toast } from "react-toastify";
import ErpAfsRetryAPI from "../../../Api/ErpAfsRetryAPI";
import CommonLogout from "../CommonLogout";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import AvatarSet from "../AvatarSet";
import MoreIcon from '../../../assets/img/more_options.png';
import QuotationsStatus from "../QuotationsStatus";
import FileDownload from "../FileDownload";

const TableBody = (props) => {
    const { srNumber, tabName, OrderList, RowData, 
        Pagination, EditAccess, OrderNewModel, 
        setOrderNewModel, ActionColumn, setLoader,
        showModalNew, setShowModalNew, APIcalling, isCollapsable
    } = props;
    const { accessToken, device_id } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ CopyUrl, setCopyUrl ] = useState({
        id: "",
        label: "Copy Link"
    });
    const Dispatch = useDispatch();

    // VIEW MORE COLLAPOSABLE DETAILS BUTTON
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // VIEW LESS COLLAPOSABLE DETAILS BUTTON
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // ORDER LOGS
    const showOrder = (value, url) => {
        let current_url = url === "AFS_ORDERS" ? "AFS Order Log" : url === "AFS_QUOTATIONS" ? "AFS Quotation Log" : "Order Log";

        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: current_url,
            subtitle: "",
            Data: value
        });
    };

    // INVOICE DETAILS
    const callInvoiceModal = (e, code) => {
        e.preventDefault();
        let param = { 
            "transmit": "broadcast", 
            "url": "afs_order_detail",
            "request" : { 
                "order_id" : code,
                "is_weight" : true
            },
            "DeviceId" : device_id,
            modal: true
        };
        wsSend_request(websocket, param);
    };

    // GET FRANCHISE NAME
    const GetFranchiseName = (billing_id) => {
        const current = OrderList?.franchise?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    // Retry ERP order
    const RtryFunction = async (data, url) => {        
        if(url === "invoice") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Get ERP Invoice",
                subtitle: "Get ERP Invoice",
                modalType: "form",
                button: "Create",
                Data: data,
            });
        } else {
            CommonAPICall(data);
        };
    };

    // PAYMENT APPROVAL
    const PaymentApproval = (data, url) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: ActionColumn?.label === "quotations_payment" ? "Enter Payment Details" : "Update Payment Details",
            subtitle: ActionColumn?.label === "quotations_payment" ? "Enter Payment Details" : "Update Payment Details",
            modalType: "form",
            button: "OK",
            Data: data,
        });
    };

    // CREATE SALES ORDER FUNCTIONS
    const CreateSalesOrderFunction = (data) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Confirmation",
            subtitle: "SALE-CREATE-ORDER",
            description: "Are you sure to create sale Order?",
            modalType: "not form",
            button: "Yes",
            Data: data,
        });
    };

    // API CALLING FUCNTION
    const CommonAPICall = async (data) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", data?.id);
        const response = await ErpAfsRetryAPI(formData, accessToken);
        if(response?.status === 200) {
            setLoader(false);
            APIcalling();
            toast.success(response?.message);
        } else {
            setLoader(false);
            APIcalling();
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };


    // COMMON BUTTON FUNCTION
    const CommonButton = ({ data }) => {
        return(<td>
            {ActionColumn?.label === "retry" ? (<Button 
                className="btn edittable edittableretry btn btn-primary" 
                onClick={() => RtryFunction(data, (data?.erp_id === null || data?.erp_id === "") ? "retry" : "invoice")}
            >
                {(data?.erp_id === null || data?.erp_id === "") ? (<>
                    <i className="bi bi-arrow-repeat"></i> Retry
                </>) : (<>
                    <i className="bi bi-receipt"></i> Get Invoice
                </>)}
            </Button>) : (ActionColumn?.label === "quotations_payment" || ActionColumn?.label === "quotations_payment_approval") ? (<Button 
                className="btn donwload_btn" 
                onClick={() => PaymentApproval(data, ActionColumn?.label)}
            >
                {ActionColumn?.label === "quotations_payment" ? "Payment Details" : "Update Payment Details"}
            </Button>) : ActionColumn?.label === "create_so_from_quotation" ? (<React.Fragment>
                {(data?.erp_quotation_code !== null && data?.erp_quotation_code !== "" ) ? (<>
                    {!data?.is_afs_order_created && (<Button 
                        className="btn donwload_btn" 
                        onClick={() => CreateSalesOrderFunction(data)}
                    >
                        Create Sale Order
                    </Button>)}
                </>) : "-"}
            </React.Fragment>) : (<Link className="btn edittable" to={`${ActionColumn?.naviagte}${data?.id}`}>
                <i className="bi bi-pencil-square"></i>
            </Link>)}
        </td>)
    };

    let CurrentColspan = RowData?.length + 1;

    if(EditAccess && ActionColumn?.isShow){
        CurrentColspan += 1;
    };

    // get name
    const GetName = (id, url) => {
        return OrderList?.[url?.list]?.filter((elm) => id === elm?.id)[0]?.[url?.label];
    };

    // payment url copy
    const PaymentLinkCompy = (payment_id) => {
        var urlText = document.getElementById(payment_id).innerText; // Get the text from <p>
        navigator.clipboard.writeText(urlText).then(() => {
            setCopyUrl({
                ...CopyUrl,
                id: payment_id,
                label: "URL Copied !"
            });

            setTimeout(() => {
                setCopyUrl({
                    ...CopyUrl,
                    id: "",
                    label: "Copy Link"
                });
            }, 2000);
        }).catch(err => {
            setCopyUrl({
                ...CopyUrl,
                id: "",
                label: "Copy Link"
            });
        });
    };

    return(<tbody>
        {OrderList?.list?.map((item, rowIndex) => {
            return(<React.Fragment key={rowIndex}>
            <tr key={rowIndex}>
                {isCollapsable && (<td>
                    <Button 
                        className={activeTableDetails === item?.id ? "btn actionbtn active" : "btn actionbtn"}
                        onClick={() => activeTableDetails === item?.id ? ElseMoreDetails() : ViewMoreDetails(item?.id)}
                        aria-controls="example-collapse-text"
                        aria-expanded={activeTableDetails === item?.id ? true : false}
                    >
                        <Image src={MoreIcon} alt="more icon" />
                    </Button>
                </td>)}
                <td>
                    {srNumber?.clickable ? (
                        <Button 
                            className="btn-link btn-link-color text-decoration-none" 
                            onClick={() => showOrder(item?.id, srNumber?.events)}
                        >
                            {GetCurrentTableSRNo(rowIndex, Pagination?.current_page, Pagination?.record_limit)}
                        </Button>) 
                    : GetCurrentTableSRNo(rowIndex, Pagination?.current_page, Pagination?.record_limit)}
                </td>
                {RowData?.map((row, ind) => {
                    if(!row?.isCollapsable) {
                        if(row?.type === "navigate") {
                            return(<td key={ind}>
                                {EditAccess ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${AFTER_SALES_ORDERS_URL}/${item?.id}`}>
                                    <b className="tableBolanchor">{item[row?.key] || "-"}</b>
                                </Link>) : (<b className="tableBolanchor">{item[row?.key] || "-"}</b>)}
                            </td>)
                        } else if(row?.type === "navigate_quotations") {
                            return(<td key={ind}>
                                {EditAccess ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${QUOTATIONS_REPORT_DETAILS_URL}/${item?.id}`}>
                                    <b className="tableBolanchor">{item[row?.key] || "-"}</b>
                                </Link>) : (<b className="tableBolanchor">{item[row?.key] || "-"}</b>)}
                            </td>)
                        } else if(row?.type === "navigate_quotations_payment_approval") {
                            return(<td key={ind}>
                                {EditAccess ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${PAYMENT_APPROVAL_QUOTATIONS_REPORT_DETAILS_URL}/${item?.id}`}>
                                    <b className="tableBolanchor">{item[row?.key] || "-"}</b>
                                </Link>) : (<b className="tableBolanchor">{item[row?.key] || "-"}</b>)}
                            </td>)
                        } else if(row?.type === "date") {
                            return(<td key={ind}>
                                {CommonDateTime(item[row?.key], "LL", "show")}
                            </td>)
                        } else if(row?.type === "label_get_from_franchise") {
                            return(<td key={ind}>
                                {GetFranchiseName(item[row?.key])?.name}
                            </td>)
                        } else if(row?.type === "label_get_from_list") {
                            return(<td key={ind}>
                                {GetName(item[row?.key], row?.filterFrom)}
                            </td>)
                        } else if(row?.type === "image") {
                            return(<td key={ind}>
                                <AvatarSet
                                    type={"product"}
                                    url={item[row?.key]}
                                    alt={"tableimg"}
                                />
                            </td>)
                        } else if(row?.type === "invoice_modal_open_navigate") {
                            return(<td key={ind}>
                                {EditAccess ? (
                                    <b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, item?.id)}>{item[row?.key] || "-"}</b>
                                ) : (
                                    <b className="tableBolanchor" style={{ cursor: "default" }} >{item[row?.key] || "-"}</b>
                                )}
                            </td>)
                        } else if(row?.type === "pending_since") {
                            return(<td key={ind}>
                                {TimeSense(item[row?.key])}
                            </td>)
                        } else if(row?.type === "status") {
                            return(<td key={ind}>
                                {(item[row?.key] === null || item[row?.key] === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                            </td>)
                        } else if(row?.type === "status_active") {
                            return(<td key={ind}>
                                {item[row?.key] ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}
                            </td>)
                        } else if(row?.type === "hyper_link_shipment_url") {
                            return(<td key={ind}>{
                                item[row?.key] === null ?
                                    "-" :
                                    item?.shipment_url === null ? item[row?.key] : (<a href={item?.shipment_url} target="__blank">{item[row?.key] ? item[row?.key] : "-"}</a>)
                            } </td>)
                        } else if(row?.type === "quotation_status_type") {
                            return(<td key={ind}>
                                {QuotationsStatus(item[row?.key], "badge")}
                            </td>)
                        } else if(row?.type === "quotation_erp_status_type") {
                            return(<td key={ind}>
                                {(item[row?.key] === null || item[row?.key] === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                            </td>)
                        } else {
                            return(<td key={ind}>{item[row?.key] || "-"}</td>)
                        };
                    };
                })}
                {(EditAccess && ActionColumn?.isShow) && (<CommonButton data={item} />)}
            </tr>
            <Collapse in={activeTableDetails === item?.id ? true : false}>
                <tr className="tablecollapsewraps" id={"tablecollpase"+item?.id}>
                    <td colSpan="9" >
                        <Table className="table" bordered>
                            <tbody>
                                {RowData?.map((row, ind) => {
                                    if(row?.isCollapsable) {
                                        if(row?.type === "payment_link_label") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>
                                                    {EditAccess ? (<React.Fragment>
                                                        {item[row?.key] === null ? "-" : 
                                                        (<React.Fragment>
                                                            {row?.isDisable ? item[row?.key] : (<>
                                                                <a href={item[row?.key]} id={"payment_link" + item?.id} style={{ color: "#00f" }} target="__blank" >{item[row?.key]}</a>
                                                                <Button className={CopyUrl?.id === "payment_link" + item?.id ? "btn btn-copy-link active" : "btn btn-copy-link"} onClick={() => PaymentLinkCompy("payment_link" + item?.id)}>
                                                                    <i className="bi bi-copy"></i> {CopyUrl?.id === "payment_link" + item?.id ? CopyUrl?.label : "Copy Link"}
                                                                </Button>
                                                            </>)}
                                                        </React.Fragment>)}
                                                    </React.Fragment>) : (<React.Fragment style={{ color: "red" }}>Don't have permission to make a payment.</React.Fragment>)}
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "payment_Attachment_preview_download") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>
                                                    {item?.payment_slip === null ? "-" : (<React.Fragment>
                                                        <a href={FILE_AUTH_URL + "view/file/" + item?.payment_slip?.id} style={{ color: "#00f" }} target="__blank" >{item?.payment_slip?.original_name}</a>
                                                        <Button className="btn btn-copy-link" onClick={() => FileDownload(item?.payment_slip?.id, accessToken)}><i className="bi bi-download"></i> Download file</Button>
                                                    </React.Fragment>)}
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "date") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{CommonDateTime(item[row?.key], "LL", "show")}</td>
                                            </tr>)
                                        } else {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{item[row?.key] || "-"}</td>
                                            </tr>)
                                        };
                                    }
                                })}
                            </tbody>
                        </Table>
                    </td>
                </tr>
            </Collapse>
        </React.Fragment>)
        })}
        {OrderList?.list?.length === 0 && (<tr>
            <td style={{ textAlign: "center" }} colSpan={CurrentColspan}>List not found</td>
        </tr>)}
    </tbody>)
}

export default TableBody;
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import "react-datepicker/dist/react-datepicker.css";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import Select from 'react-select'
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { StartMainCommonLoader, GetReturnReplaceInvoiceOrderDetails, GetReturnReplaceFramesOrderDetails } from "../../../redux/actions/adminActions";
import { Link } from "react-router-dom";
import { RETURN_AND_REPLACE_REPORT_LIST } from "../../Shared/constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../Common/CommonLoader";

const AddReturnAndReplace = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, ClearFormSet, invoiceDetails, frameNumberList, OptionList, commonMainLoader, ErrorMessageFromReddux, ReturnReplaceOrderSingleList } = useSelector((state) => state.adminReducers);
    const [ selectProduct, setselectProduct ] = useState("");
    const [ selectProductAcc, setselectProductAcc ] = useState("");
    const [ AddQuantity, setAddQuantity ] = useState("");
    const [ AddQuantityAcc, setAddQuantityAcc ] = useState("");
    const [ optionsProducts, setoptionsProducts ] = useState([]);
    const [ optionsProductsAcces, setoptionsProductsAcces ] = useState([]);
    const [ Remarks, setRemarks ] = useState(""); 
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: "",
        Data: {}
    });
    const [ SearchInvoiceID, setSearchInvoiceID ] = useState("");
    const [ SearchFrameNumber, setSearchFrameNumber ] = useState("");
    const [ ReturnType, setReturnType ] = useState("return");
    const [ ReturnFrameType, setFrameReturnType ] = useState("return");
    const [ FrameNumber, setFrameNumber ] = useState("");
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(() => {
        if(invoiceDetails !== undefined) {
            setSearchInvoiceID(invoiceDetails?.order?.invoice_code === undefined ? "" : invoiceDetails?.order?.invoice_code);
            if(invoiceDetails?.order_items_list?.length > 0) {
                setoptionsProducts(invoiceDetails?.order_items_list?.filter((elm) => elm?.is_bike === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.product_name})));
                setoptionsProductsAcces(invoiceDetails?.order_items_list?.filter((elm) => elm?.is_bike === false)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.product_name})));
            };
        };
    }, [ invoiceDetails ]);

    // get all franchise list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);


    const [ FilterDisable, setFilterDisable ] = useState(false);
    // Product select
    const ProductSelect = (value) => {
        if(value !== null) {
            setselectProduct(value);
            setFilterDisable(true);
        } else {
            setselectProduct("");
            setFilterDisable(false);
        }
    };

    // Product select ACC
    const ProductSelectAcc = (value) => {
        if(value !== null) {
            setselectProductAcc(value);
            setFilterDisable(true);
        } else {
            setselectProductAcc("");
            setFilterDisable(false);
        }
    };

    

    const [ ProductListOrder, setProductListOrder ] = useState([]);
    var ProductMergAarray = [...ProductListOrder];


    const [ ProductListOrderAcc, setProductListOrderAcc ] = useState([]);
    var ProductMergAarrayAcc = [...ProductListOrderAcc];

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };
    
    // Function to calculate total quantity
    function calculateProductTotalQuantity(arrayOfObjects) {
        let productQuantities = {};
        
        arrayOfObjects.forEach(obj => {
            const { product, quantity } = obj;
            
            if (productQuantities[product?.id]) {
                // If it exists, add the quantity to the existing quantity
                productQuantities[product?.id] += quantity;
            } else {
                // If it doesn't exist, create a new entry with the quantity
                productQuantities[product?.id] = quantity;
            }
        });

        return productQuantities;
    };

    const PartialArrayTotalQuantity = calculateProductTotalQuantity(ProductListOrder);
    const PartialArrayTotalQuantityAcc = calculateProductTotalQuantity(ProductListOrderAcc);
    
    // add purchase order
    const PurchaseOrderAdd = () => {
        if(selectProduct !== "" && AddQuantity > 0) {
            const Checkqnty = optionsProducts?.filter((elm) => {
                if(selectProduct?.id === elm?.id) {
                    if(PartialArrayTotalQuantity[elm?.id] === undefined) {
                        return parseInt(AddQuantity) <= elm?.qty;    
                    } else {
                        return (parseInt(AddQuantity) + parseInt(PartialArrayTotalQuantity[elm?.id])) <= elm?.qty;
                    }
                }
            });
            
            if(Checkqnty?.length > 0) {
                let Obj = {
                    id: generateRandomId(),
                    order_items_id: selectProduct?.id,
                    is_bike: selectProduct?.is_bike,
                    product: selectProduct,
                    quantity: parseInt(AddQuantity),
                    type: ReturnType,
                    frames: []
                };
                function addFramesToObj(obj) {
                    for (let i = 1; i <= obj.quantity; i++) {
                        const frame = {
                            id: generateRandomId(), // This sets the frame id starting at 1 and increments for each frame
                            old: "",
                            new: "",
                        };
                        obj.frames.push(frame);
                    }
                }
                // Call the function to add frames to Obj
                addFramesToObj(Obj);

                ProductMergAarray?.push(Obj);
            
                let mergedArray = mergeAndUpdate(ProductMergAarray);
                // unique array convert
                setProductListOrder(mergedArray);
                PurchaseOrderClear();
            } else {
                return toast.error("The product quantity should not be greater than the actual product quantity.");
            }
        };
    };

    
    const PurchaseOrderAddAcc = () => {
        if(selectProductAcc !== "" && AddQuantityAcc > 0) {
            const Checkqnty = optionsProductsAcces?.filter((elm) => {
                if(selectProductAcc?.id === elm?.id) {
                    if(PartialArrayTotalQuantityAcc[elm?.id] === undefined) {
                        return parseInt(AddQuantityAcc) <= elm?.qty;    
                    } else {
                        return (parseInt(AddQuantityAcc) + parseInt(PartialArrayTotalQuantityAcc[elm?.id])) <= elm?.qty;
                    }
                }
            });
            if(Checkqnty?.length > 0) {
                let Obj = {
                    id: generateRandomId(),
                    order_items_id: selectProductAcc?.id,
                    is_bike: selectProductAcc?.is_bike,
                    product: selectProductAcc,
                    quantity: parseInt(AddQuantityAcc),
                    type: "return",
                };

                ProductMergAarrayAcc?.push(Obj);
            
                let mergedArray = mergeAndUpdateAcc(ProductMergAarrayAcc);
                // unique array convert
                setProductListOrderAcc(mergedArray);
                PurchaseOrderClearAcc();
            } else {
                return toast.error("The product quantity should not be greater than the actual product quantity.");
            }
        };
    };

    const [ optionsProductNew, setoptionsProductNew ] = useState([]);
    useEffect(() => {
        setoptionsProductNew(() => optionsProducts?.filter((elm) => {
            if(PartialArrayTotalQuantity[elm?.id] !== parseInt(elm?.qty)) {
                return elm;
            };
        }))
    }, [ optionsProducts, ProductListOrder ]);

    
    const [ optionsProductsAccesNew, setoptionsProductsAccesNew ] = useState([]);
    useEffect(() => {
        setoptionsProductsAccesNew(() => optionsProductsAcces?.filter((elm) => {
            if(PartialArrayTotalQuantityAcc[elm?.id] !== parseInt(elm?.qty)) {
                return elm;
            };
        }))
    }, [ optionsProductsAcces, ProductListOrderAcc ]);

    
    // merge array and add quantity
    function mergeAndUpdate(arr) {
        let mergedArray = [];
        arr.forEach(item => {
            // Check if the item already exists in the merged array
            const existingItemIndex = mergedArray.findIndex(
                mergedItem => mergedItem?.product?.value === item?.product?.value && mergedItem.is_demo === item.is_demo && mergedItem?.type === item?.type
            );
    
            if (existingItemIndex !== -1) {
                // If the item exists, update its quantity
                mergedArray[existingItemIndex].quantity += item.quantity;
                mergedArray[existingItemIndex].frames = mergedArray[existingItemIndex].frames.concat(item?.frames);
            } else {
                // If the item doesn't exist, add it to the merged array
                mergedArray.push({...item});
            }
        });
    
        return mergedArray;
    };

    // merge array and add quantity
    function mergeAndUpdateAcc(arr) {
        let mergedArray = [];
        arr.forEach(item => {
            // Check if the item already exists in the merged array
            const existingItemIndex = mergedArray.findIndex(
                mergedItem => mergedItem?.product?.value === item?.product?.value && mergedItem.is_demo === item.is_demo
            );
    
            if (existingItemIndex !== -1) {
                // If the item exists, update its quantity
                mergedArray[existingItemIndex].quantity += item.quantity;
            } else {
                // If the item doesn't exist, add it to the merged array
                mergedArray.push({...item});
            }
        });
    
        return mergedArray;
    };

    // remove order
    const DeleteOrder = (id) => {
        const RemoveOrder = [];
        ProductMergAarray?.map((elm) => {
            if(elm?.id !== id) {
                return RemoveOrder.push(elm);
            }
        });
        setProductListOrder(RemoveOrder);
    };

    // remove order
    const DeleteOrderAcc = (id) => {
        const RemoveOrder = [];
        ProductMergAarrayAcc?.map((elm) => {
            if(elm?.id !== id) {
                return RemoveOrder.push(elm);
            }
        });
        setProductListOrderAcc(RemoveOrder);
    };

    // old frames select
    const ProductOldFrameSelect = (value, id, frames_id) => {
        if(value !== null) {
            const UpdateTemparyArray = ProductListOrder?.map((elm) => {
                if(elm?.id === id) {
                    const UpdateFrames = elm?.frames?.map((elm__) => {
                        if(elm__?.id === frames_id) {
                            return{
                                ...elm__,
                                old: value?.frame_number,
                                new: "",
                            }
                        } else 
                         return elm__;
                    });
                    return{
                        ...elm,
                        frames: UpdateFrames
                    }
                } else  
                return elm;
            });
            setProductListOrder(UpdateTemparyArray);
        } else {
            const UpdateTemparyArray = ProductListOrder?.map((elm) => {
                if(elm?.id === id) {
                    const UpdateFrames = elm?.frames?.map((elm__) => {
                        if(elm__?.id === frames_id) {
                            return{
                                ...elm__,
                                old: "",
                                new: "",
                            }
                        } else 
                         return elm__;
                    })
                    return{
                        ...elm,
                        frames: UpdateFrames
                    }
                } else  
                return elm;
            });
            setProductListOrder(UpdateTemparyArray);
        };
    };

    // old frames select
    const ProductNewFrameSelect = (value, id, frames_id) => {
        if(value !== null) {
            const UpdateTemparyArray = ProductListOrder?.map((elm) => {
                if(elm?.id === id) {
                    const UpdateFrames = elm?.frames?.map((elm__) => {
                        if(elm__?.id === frames_id) {
                            return{
                                ...elm__,
                                new: value?.frame_number,
                            }
                        } else 
                         return elm__;
                    })
                    return{
                        ...elm,
                        frames: UpdateFrames
                    }
                } else  
                return elm;
            });
            setProductListOrder(UpdateTemparyArray);
        } else {
            const UpdateTemparyArray = ProductListOrder?.map((elm) => {
                if(elm?.id === id) {
                    const UpdateFrames = elm?.frames?.map((elm__) => {
                        if(elm__?.id === frames_id) {
                            return{
                                ...elm__,
                                new: "",
                            }
                        } else 
                         return elm__;
                    })
                    return{
                        ...elm,
                        frames: UpdateFrames
                    }
                } else  
                return elm;
            });
            setProductListOrder(UpdateTemparyArray);
        };
    };
    

    const onChange = (e) => {
        let value = e.target.value;
    
        // Remove leading zeros
        value = value.replace(/^0+/g, "");
    
        // Remove non-numeric characters
        value = value.replace(/\D/g, "");
    
        // Ensure the value is more than 1 (not negative number)
        if (value === "" || parseInt(value) < 0) {
            value = ""; // Set default value to 1
        }
    
        // Update the state with the modified value
        setAddQuantity(parseInt(value));
    }

    const onChangeAcc = (e) => {
        let value = e.target.value;
    
        // Remove leading zeros
        value = value.replace(/^0+/g, "");
    
        // Remove non-numeric characters
        value = value.replace(/\D/g, "");
    
        // Ensure the value is more than 1 (not negative number)
        if (value === "" || parseInt(value) < 0) {
            value = ""; // Set default value to 1
        }
    
        // Update the state with the modified value
        setAddQuantityAcc(parseInt(value));
    }


    // clear purchase order
    const PurchaseOrderClear = () => {
        setselectProduct("");
        setAddQuantity("");
        setFilterDisable(false);
    };

    // clear purchase order
    const PurchaseOrderClearAcc = () => {
        setselectProductAcc("");
        setAddQuantityAcc("");
        setFilterDisable(false);
    };

    // all clears data
    const PurchaseOrderMainClear = () => {
        setselectProduct("");
        setAddQuantity("");
        setFilterDisable(false);
    };

    function getGstAmt(price, gstRate) {
        return (parseFloat(price)  - ( parseFloat(price) / (1 + (parseFloat(gstRate) / 100))));
    };

    const DisableAddButton = selectProduct !== "" && AddQuantity > 0;
    const DisableAddButtonAcc = selectProductAcc !== "" && AddQuantityAcc > 0;

    const SearchInvoiceFunction = (e) => {
        setSearchInvoiceID(e.target.value);
        if(e.target.value === "") {
            ClearDetails("Invoice");
        };
    };

    const SearchFrameFunction = (e) => {
        setSearchFrameNumber(e.target.value);
        if(e.target.value === "") {
            ClearDetails("Frame");
        };
    };

    // search invoice id
    const onEnterInvoiceSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchOrderList();
        };
    };

    const onEnterFrameNumberSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchFrameNumberList();
        };
    };

    // search order api
    const SearchOrderList = () => {
        if(SearchInvoiceID?.trim() !== "") {
            Dispatch(StartMainCommonLoader({
                url: "order_detail_invoice",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "order_detail",
                "request" : {
                    "order_id" : SearchInvoiceID?.trim(),
                    "order_by" : "invoice"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            Dispatch(StartMainCommonLoader({
                url: "order_detail_invoice",
                loader: false
            }));
        };
    };

    // search frame number api
    const SearchFrameNumberList = () => {
        if(SearchFrameNumber?.trim() !== "") {
            Dispatch(StartMainCommonLoader({
                url: "order_detail_frame",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "order_detail",
                "request" : {
                    "order_id" : SearchFrameNumber?.trim(),
                    "order_by" : "frame"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            Dispatch(StartMainCommonLoader({
                url: "order_detail_frame",
                loader: false
            }));
        };
    };

    // clear search frame
    const ClearSearchFrame = () => {
        setSearchFrameNumber("");
        ClearDetails("frame");
        Dispatch(GetReturnReplaceInvoiceOrderDetails({}));
    };

    // clear invoice id
    const ClearInvoiceID = () => {
        setSearchInvoiceID("");
        ClearDetails("Invoice");
        PurchaseOrderMainClear();
        setProductListOrder([]);
        setProductListOrderAcc([]);
        setoptionsProductNew([]);
        setoptionsProductsAccesNew([]);
        setoptionsProducts([]);
        setoptionsProductsAcces([]);
    };

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    // clear details
    const ClearDetails = (url) => {
        if(url === "Invoice") {
            Dispatch(GetReturnReplaceInvoiceOrderDetails({}));
            setselectProduct("");
        } else {
            Dispatch(GetReturnReplaceFramesOrderDetails({}));
        };
    };

    const ClearReturnReplace = () => {
        Dispatch(GetReturnReplaceInvoiceOrderDetails({}));
        Dispatch(GetReturnReplaceFramesOrderDetails({}));
    };
    
    // submit return replace order function
    const ReturnReplaceOrderFunction = () => {
        Dispatch(StartMainCommonLoader({
            url: "order_rr",
            loader: true
        }));
        const MergeList = ProductListOrder?.concat(ProductListOrderAcc)
        let param = {
            "transmit": "broadcast",
            "url": "order_rr",
            "request": {
                "order_id": invoiceDetails?.order?.id,
                "items": MergeList?.map((elm) => {
                    return {
                        order_items_id: elm?.order_items_id,
                        qty: elm?.quantity?.toString(),
                        is_bike: elm?.is_bike,
                        type: elm?.type,
                        frames: elm?.frames?.map((data_) => {
                            return{
                                new: data_?.new,
                                old: data_?.old,
                            }
                        })
                    }
                })
            },
            "DeviceId" : device_id
        }
        wsSend_request(websocket, param);
        
    };

    // disable enable button
    function checkFramesValidity(array) {
        for (const obj of array) {
            if (obj.type === "replace") {
                for (const frame of obj.frames) {
                    if (frame.old === "" || frame.new === "") {
                        return false;
                    }
                }
            } else if (obj.type === "return") {
                for (const frame of obj.frames) {
                    if (frame.old === "") {
                        return false;
                    }
                }
            } else {
                // If the type is neither 'replace' nor 'return'
                return false;
            }
        }
        return true;
    }
    
    const isValid = checkFramesValidity(ProductListOrder);

    
    const FrameSelectionFields = (value) => {
        if(value !== "") {
            setFrameNumber(value);
        } else {
            setFrameNumber("");
        }
    };

    const ChangeFrameInvocie = () => {
        let param = {
            "transmit": "broadcast",
            "url": "order_rr",
            "request": {
                "order_id": frameNumberList?.order?.id,
                "items": [
                    {
                        "order_items_id": frameNumberList?.frame.order_item_id,
                        "qty": "1",
                        "is_bike": true,
                        "type": ReturnFrameType,
                        "frames": [{
                            "new": FrameNumber?.frame_number,
                            "old": frameNumberList?.frame.frame_number,    
                        }]
                    }
                ]
            },
            "DeviceId" : device_id
        }
        wsSend_request(websocket, param);
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url === "order_rr") {
            PurchaseOrderMainClear();
            Dispatch(GetReturnReplaceInvoiceOrderDetails({}));
            Dispatch(GetReturnReplaceFramesOrderDetails({}));
            setShowModalNew({
                ...showModalNew,
                open: false,
                Data: {}
            });
            setRemarks("");
            Navigate(RETURN_AND_REPLACE_REPORT_LIST);
            setFrameNumber("")
        };
    }, [ ClearFormSet ]);

    return(<section className="Main_section">
         <div className="MainHeader">
            <Link to={RETURN_AND_REPLACE_REPORT_LIST} className="btn btn-link backbtn" onClick={() => ClearReturnReplace()}><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
            <Scrollbars 
                style={{ 
                    height: "calc(100vh - 147px)"
                }} 
                renderView={props => <div {...props} className="view"/>}
                
                className="ScrollbarsSidebar"
            >
                <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                    <h6 className="subtitleForm PurchaseOrderCheckbox">
                    Search frame and replace it with a new frame:
                    </h6>
                    <div className="col-md-12">
                        <div className="filtr_Form Purchase_Order_Mendetory" style={{ borderBottom: "1px solid #eee" }}>
                            <div className="formloginset">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Form.Group className="mb-3" >
                                            <div className="serachBoxWIth serachBoxWIthNewMain">
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{ show: 250, hide: 100 }}
                                                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                                    Search by either hitting the enter button or clicking on the search icon.
                                                </Tooltip>}
                                                >
                                                    <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                                </OverlayTrigger>
                                                <Form.Control 
                                                    type="text" 
                                                    value={SearchFrameNumber} 
                                                    onChange={(e) => SearchFrameFunction(e)}
                                                    onKeyDown={(e) => {
                                                        EmptySpaceFieldValid(e);
                                                        onEnterFrameNumberSearch(e)
                                                    }}
                                                    disabled={(commonMainLoader?.url === "order_detail_frame" && commonMainLoader?.loader)}
                                                    placeholder="Search Frame Number" 
                                                />
                                                <Button 
                                                    className="searchbtn" 
                                                    onClick={() => SearchFrameNumberList()}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                                        <path d="M21 21l-6 -6"></path>
                                                    </svg>
                                                </Button>
                                                {SearchFrameNumber !== "" && (<div className="pincode_error" onClick={() => ClearSearchFrame()}>
                                                    <i className="bi bi-x-circle-fill"  style={{ color: "red" }}></i>
                                                </div>)}
                                                {(commonMainLoader?.url === "order_detail_frame" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>)}
                                            </div>
                                            {ErrorMessageFromReddux?.Error !== "" && (<span className="error_medotory">{ErrorMessageFromReddux?.Error}</span>)}
                                        </Form.Group>
                                    </div>
                                </div>
                                {Object?.keys(frameNumberList)?.length > 0 && (<div className="row">
                                    <div className="col-md-4">
                                        <Form.Group className="mb-3" >
                                            <select 
                                                className="form-control" 
                                                value={ReturnFrameType}
                                                onChange={(e) => {setFrameReturnType(e.target.value); setFrameNumber("")}}
                                            >
                                                <option value="return">Return</option>
                                                {invoiceDetails?.order?.order_type !== "WO" && (<option value="replace">Replace</option>)}
                                            </select>
                                        </Form.Group>
                                    </div>
                                    {ReturnFrameType === "replace" && (<div className="col-md-4">
                                        <Form.Group className="mb-3" >
                                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                                <Select 
                                                    onChange={(e) => FrameSelectionFields(e)} 
                                                    isClearable 
                                                    options={
                                                        OptionList[`${frameNumberList?.frame?.warehouse_id}_${frameNumberList?.frame?.product_id}`]?.filter((elxz) => elxz?.status === "UNSOLD")?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.frame_number}))
                                                    }
                                                    className="godown-select-container" 
                                                    classNamePrefix="godown-select"
                                                    placeholder="Select Frames"
                                                    styles={customStyles}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={document.body}
                                                />
                                        </div>
                                        </Form.Group>
                                    </div>)}
                                    <div className="col-md-3">
                                        <Button className="formbtn mt-0 me-2 btn btn-primary" id="submitframebtn" onClick={() => ChangeFrameInvocie()}>Submit</Button>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                    <h6 className="subtitleForm PurchaseOrderCheckbox">
                    Search Invoice Id:
                    </h6>
                    <div className="col-md-12">
                        <div className="filtr_Form Purchase_Order_Mendetory" style={{ borderBottom: "1px solid #eee" }}>
                            <div className="formloginset">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Form.Group className="mb-3" >
                                            <div className="serachBoxWIth serachBoxWIthNewMain">
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{ show: 250, hide: 100 }}
                                                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                                    Search by either hitting the enter button or clicking on the search icon.
                                                </Tooltip>}
                                                >
                                                    <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                                </OverlayTrigger>
                                                <Form.Control 
                                                    type="text" 
                                                    value={SearchInvoiceID} 
                                                    onChange={(e) => SearchInvoiceFunction(e)}
                                                    onKeyDown={(e) => {
                                                        EmptySpaceFieldValid(e);
                                                        onEnterInvoiceSearch(e)
                                                    }}
                                                    disabled={(commonMainLoader?.url === "order_detail_invoice" && commonMainLoader?.loader)}
                                                    placeholder="Search Invoice ID" 
                                                />
                                                <Button 
                                                    className="searchbtn" 
                                                    onClick={() => SearchOrderList()}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                                        <path d="M21 21l-6 -6"></path>
                                                    </svg>
                                                </Button>
                                                {SearchInvoiceID !== "" && (<div className="pincode_error" onClick={() => ClearInvoiceID()}>
                                                    <i className="bi bi-x-circle-fill"  style={{ color: "red" }}></i>
                                                </div>)}
                                                {(commonMainLoader?.url === "order_detail_invoice" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>)}
                                            </div>
                                            {ErrorMessageFromReddux?.Error !== "" && (<span className="error_medotory">{ErrorMessageFromReddux?.Error}</span>)}
                                        </Form.Group>
                                    </div>
                                </div>
                                {invoiceDetails?.order?.customer_name !== undefined && (<React.Fragment>
                                    <div className="customerdetails">
                                        <label>Customer Name: </label>
                                        {invoiceDetails?.order?.customer_name}
                                    </div>
                                </React.Fragment>)}
                            </div>
                        </div>
                        {(invoiceDetails?.length !== 0 && Object?.keys(invoiceDetails)?.length !== 0) && (<React.Fragment>
                            {(invoiceDetails?.order?.order_status === "DELIVERED" || invoiceDetails?.order?.order_status === "DISPATCHED") ? (<React.Fragment>
                                {invoiceDetails?.order?.order_type === "WO" ? (<span className="errorMessageIcon">
                                    <i className="bi bi-ban"></i>&nbsp; Returns for stock orders are not permitted.
                                </span>) : (<React.Fragment>
                                    {optionsProducts?.length > 0 && (<div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                                        <h6 className="subtitleForm PurchaseOrderCheckbox">
                                        Bike Items:
                                        </h6>
                                        <div className="formloginset">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Form.Group className="mb-3">
                                                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                                            <Select 
                                                                onChange={(e) => ProductSelect(e)} 
                                                                value={selectProduct}
                                                                isClearable 
                                                                options={optionsProductNew}
                                                                className="godown-select-container" 
                                                                classNamePrefix="godown-select"
                                                                placeholder="Select Products"
                                                                styles={customStyles}
                                                                menuPlacement="auto"
                                                                menuPortalTarget={document.body}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-3">
                                                    <Form.Group className="mb-3" >
                                                        <Form.Control 
                                                            type="number"
                                                            value={AddQuantity} 
                                                            className="inputBoxs"
                                                            onChange={onChange}
                                                            onKeyDown={(e) => {
                                                                EmptySpaceFieldValid(e)
                                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                            }}
                                                            placeholder="Enter Quantity"
                                                            maxLength={3}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group className="mb-3" >
                                                        <select className="form-control" value={ReturnType} onChange={(e) => setReturnType(e.target.value)}>
                                                            <option value="return">Return</option>
                                                            {invoiceDetails?.order?.order_type !== "WO" && (<option value="replace">Replace</option>)}
                                                        </select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn exportbtn" disabled={!DisableAddButton} id="PurchaseOrderbtnnew" onClick={() => PurchaseOrderAdd()}>
                                                        Add
                                                    </button>
                                                    <button className="btn exportbtn" style={{ marginLeft: "10px" }} id="PurchaseOrderClearbtnnew" onClick={() => PurchaseOrderClear()}>
                                                        <i className="bi bi-x-circle-fill"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tableView">
                                            {ProductListOrder?.length > 0 && (<>
                                                <div className="tableView">
                                                    <Table  bordered  >
                                                        <thead>
                                                            <tr>
                                                                <th>SNO</th>
                                                                <th>Product Name</th>
                                                                <th style={{ width: "200px" }}>Old Frames</th>
                                                                <th style={{ width: "200px" }}>Replace Frames</th>
                                                                <th>Return Quantity</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ProductListOrder?.map((elm, index) => {
                                                                function removeMatchingObjects(array1, array2) {
                                                                    // Extract old values from array1
                                                                    const oldValues = array1.map(obj => obj.old);
                                                                
                                                                    // Filter array2 to remove objects with frame_number matching old values
                                                                    const filteredArray2 = array2.filter(obj => !oldValues.includes(obj.frame_number));
                                                                
                                                                    return filteredArray2;
                                                                };
                                                                const filteredArray2 = removeMatchingObjects(elm?.frames === undefined ? [] : elm?.frames, elm?.product?.frames === undefined ? [] : elm?.product?.frames);
                                                                
                                                                return(<tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{elm?.product?.product_name}</td>
                                                                    <td>
                                                                        {elm?.frames?.map((frames, index) => {
                                                                            return(<div className={elm?.frames?.length > 1 ? "selectMultiselect selectMultiselectBrand mb-2" : "selectMultiselect selectMultiselectBrand"} key={index} id="selectMultiselectBrand">
                                                                            <Select 
                                                                                onChange={(e) => ProductOldFrameSelect(e, elm?.id, frames?.id)} 
                                                                                isClearable 
                                                                                options={filteredArray2?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.frame_number}))}
                                                                                className="godown-select-container" 
                                                                                classNamePrefix="godown-select"
                                                                                placeholder="Select Frames"
                                                                                styles={customStyles}
                                                                                menuPlacement="auto"
                                                                                menuPortalTarget={document.body}
                                                                            />
                                                                            </div>)
                                                                        })}
                                                                    </td>
                                                                    <td>
                                                                        {elm?.frames?.map((frames, index) => {
                                                                            return(<div className={elm?.frames?.length > 1 ? "selectMultiselect selectMultiselectBrand mb-2" : "selectMultiselect selectMultiselectBrand"} key={index} id="selectMultiselectBrand">
                                                                                <Select 
                                                                                    onChange={(e) => ProductNewFrameSelect(e, elm?.id, frames?.id)} 
                                                                                    isClearable 
                                                                                    options={
                                                                                        OptionList[`${invoiceDetails?.order?.warehouse_id}_${elm?.product?.product_id}`]?.filter((elmzz) => {
                                                                                            const oldValues = (elm?.product?.frames?.length === 0 || elm?.product?.frames?.length === undefined) ? [] : elm?.product?.frames.map(obj => obj.frame_number);
                                                                                            if(!oldValues.includes(elmzz?.frame_number)) {
                                                                                                const oldValuess__ = elm?.frames.map(obj => obj.new);
                                                                                                if(!oldValuess__.includes(elmzz?.frame_number)) {
                                                                                                    return elmzz;
                                                                                                }
                                                                                            };
                                                                                        })?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.frame_number}))
                                                                                    }
                                                                                    className="godown-select-container" 
                                                                                    classNamePrefix="godown-select"
                                                                                    placeholder="Select Frames"
                                                                                    styles={customStyles}
                                                                                    isDisabled={elm?.type === "return" || frames?.old === ""}
                                                                                    menuPlacement="auto"
                                                                                    menuPortalTarget={document.body}
                                                                                />
                                                                        </div>)})}
                                                                    </td>
                                                                    <td>{elm?.quantity}</td>
                                                                    <td>
                                                                        <Button className="btn edittable" onClick={() => DeleteOrder(elm?.id)} style={{ marginLeft: "7px" }}>
                                                                            <i className="bi bi-trash" style={{ color: "red" }}></i> 
                                                                        </Button>
                                                                    </td>
                                                                </tr>)
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>)}
                                        </div>
                                    </div>)}
                                    {optionsProductsAcces?.length > 0 && (<div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                                        <h6 className="subtitleForm PurchaseOrderCheckbox">
                                        Accessories Items:
                                        </h6>
                                        <div className="formloginset">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Form.Group className="mb-3">
                                                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                                            <Select 
                                                                onChange={(e) => ProductSelectAcc(e)} 
                                                                value={selectProductAcc}
                                                                isClearable 
                                                                options={optionsProductsAccesNew}
                                                                className="godown-select-container" 
                                                                classNamePrefix="godown-select"
                                                                placeholder="Select Products"
                                                                styles={customStyles}
                                                                menuPlacement="auto"
                                                                menuPortalTarget={document.body}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-3">
                                                    <Form.Group className="mb-3" >
                                                        <Form.Control 
                                                            type="number"
                                                            value={AddQuantityAcc} 
                                                            className="inputBoxs"
                                                            onChange={onChangeAcc}
                                                            onKeyDown={(e) => {
                                                                EmptySpaceFieldValid(e)
                                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                            }}
                                                            placeholder="Enter Quantity"
                                                            maxLength={3}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn exportbtn" disabled={!DisableAddButtonAcc} id="PurchaseOrderbtnnew" onClick={() => PurchaseOrderAddAcc()}>
                                                        Add
                                                    </button>
                                                    <button className="btn exportbtn" style={{ marginLeft: "10px" }} id="PurchaseOrderClearbtnnew" onClick={() => PurchaseOrderClearAcc()}>
                                                        <i className="bi bi-x-circle-fill"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tableView">
                                            {ProductListOrderAcc?.length > 0 && (<>
                                                <div className="tableView">
                                                    <Table  bordered  >
                                                        <thead>
                                                            <tr>
                                                                <th>SNO</th>
                                                                <th>Product Name</th>
                                                                <th>Return Quantity</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ProductListOrderAcc?.map((elm, index) => {
                                                                return(<tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{elm?.product?.product_name}</td>
                                                                    <td>{elm?.quantity}</td>
                                                                    <td>
                                                                        <Button className="btn edittable" onClick={() => DeleteOrderAcc(elm?.id)} style={{ marginLeft: "7px" }}>
                                                                            <i className="bi bi-trash" style={{ color: "red" }}></i> 
                                                                        </Button>
                                                                    </td>
                                                                </tr>)
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>)}
                                        </div>
                                    </div>)}
                                    <Button className="formbtn mt-0 me-2 btn btn-primary" disabled={!isValid} onClick={() => ReturnReplaceOrderFunction()} >
                                        Submit
                                    </Button>
                                </React.Fragment>)}
                            </React.Fragment>) : (<span className="errorMessageIcon"><i className="bi bi-ban"></i>&nbsp; Your order should be dispatched or delivered for return and replacement.</span>)}
                        </React.Fragment>)}
                    </div>
                </div>
        </Scrollbars>
        {(commonMainLoader?.url === "order_rr" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default AddReturnAndReplace;
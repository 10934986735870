/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image, Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector,useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { FRANCHISE_PENDING_DELIVERY_URL, FRANCHISE_DISPATCH_URL } from "../../Shared/constant";
import MoreIcon from '../../../assets/img/more_options.png';
import { useNavigate } from "react-router-dom";
import { TimeSense, getColor, getOrderTypeSource } from "../../Common/TimeSense";
import BrowserUniqueTabID from "../../Common/BrowserUniqueTabID";

const EWayBillReport = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ FromDate, setFromDate ] = useState("");
    const [ ToDate, setToDate ] = useState("");
    const [ status, setStatus ] = useState("SUCCESS");
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const today = new Date();
    const [ orderSource, setOrderSource ] = useState("FO");
    const { device_id, InvoiceList } = useSelector((state) => state.adminReducers);
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    // var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    var tabID = BrowserUniqueTabID();
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if(date === null) {
            setFromDate("");
        } else {
            setFromDate(date);
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if(date === null) {
            setToDate("");
        } else {
            setToDate(date);
        }
    };

    useEffect(() => {
        if(websocket){
            let param = {
                "transmit": "broadcast",
                "url": "invoice_list",
                "request" : {
                    status: "",
                    limit: userLimit,
                    page_no: currentPage,
                    search: userSearch.trim(),
                    invoice_status:"",
                    ewb_status: status,
                    order_by: "-updated_at",
                    is_cancel: "",
                    is_ewb_cancel: false,
                },
                "DeviceId" : device_id
            };
            // if(FromDate !== "" && ToDate !== ""){
            //     param.request.from_date =  CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            //     param.request.to_date =  CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
            // }
            wsSend_request(websocket, param);
        }
    }, [ websocket, userSearch, orderSource, userType, currentPage, userLimit, FromDate, ToDate, status ]);

    

    // open order details modal
    const OrderDetailsModal = (order_code) => {
        // navigate(`${FRANCHISE_PENDING_DELIVERY_URL}/${order_code}`);
        // let param = { 
        //     "transmit": "broadcast", 
        //     "url": "order_detail",
        //     "request" : { 
        //         "order_id" : order_code,
        //     },
        //     "DeviceId" : device_id
        // };
        // wsSend_request(websocket, param);
        // setOrderViewerModal({...OrderViewerModal, open: !OrderViewerModal?.open});
    };
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };
    const callInvoiceModal = (e, code) =>{
        e.preventDefault();
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request" : { 
                "order_id" : code,
            },
            "DeviceId" : device_id,
            "TabId": tabID,
            modal:true
        };
        wsSend_request(websocket, param);
    }
    return(<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row w-100">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <select onChange={(e)=> setStatus(e.target.value)} value={status} className="form-control activestatusselet" id="activestatusselet">
                                        {/* <option>Select Generation Type</option> */}
                                        <option value={"FAILURE"}>Pending</option>
                                        <option value={"SUCCESS"}>Generate</option>
                                    </select>
                                </Form.Group>
                            </div>
                            {/* <div className="col">
                                <button className="btn newsumbitbnt w-auto" style={{ marginTop: "0px" }}>Submit</button>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker 
                                    selected={FromDate} 
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)} 
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth 
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker 
                                    selected={ToDate} 
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={FromDate}
                                    onChange={(date) => DateEndGet(date)} 
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth 
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter 
            filterType={"EwayBill"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            callSelectSource={setOrderSource}
            orderSource={orderSource}
            searchType={"Order Id"}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: "calc(100vh - 260px)" }} 
                renderView={props => <div {...props} className="view"/>}
                
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th hidden></th>
                            <th>SNo</th>
                            <th>Order Id</th>
                            <th>Order Date</th>
                            <th>Invoice ID</th>
                            <th>Invoice date</th>
                            <th>EWB No.</th>
                            <th>EWB Date.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {InvoiceList?.data?.map((elm, index) => {
                            const get_color = getColor(elm);
                            return(  <React.Fragment key={index}>
                                <tr >
                                    <td hidden style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === elm?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>{GetCurrentTableSRNo(index, InvoiceList?.pagination?.current_page, InvoiceList?.pagination?.record_limit)}</td>
                                    <td>
                                        <span onClick={() => OrderDetailsModal(elm?.id)} >{elm?.order_code?elm?.order_code:"-"}</span>
                                    </td>
                                    <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                    <td>
                                        {!elm?.invoice_no ? "-" : <span className="" >{elm?.invoice_no}</span>}
                                    </td>
                                    <td>{CommonDateTime(elm?.updated_at, "LL", "show")}</td>
                                    <td>
                                        {!elm?.ack_no ? "-" : <b className="tableBolanchor" onClick={(e)=>callInvoiceModal(e, elm?.author)}>{elm?.ack_no}</b>}
                                    </td>
                                    <td>{CommonDateTime(elm?.ack_date, "LL", "show")}</td>
                                    <td hidden>
                                        {elm?.order_status === "DELIVERED" && (<Badge bg="danger">Inward completed</Badge>)}
                                    </td>
                                </tr>
                                <Collapse hidden in={activeTableDetails === elm?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                        <td colSpan="12" >
                                            <Table bordered  className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Quantity</th>
                                                        <td>{elm?.qty}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Order Value</th>
                                                        <td>{AmountNumberFormat(elm?.total)}</td>

                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>SKU Type</th>
                                                        <td>{elm?.category}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Pending Since</th>
                                                        {/* <td>{elm?.updated_at?.split(" ")[0] === elm?.created_at?.split(" ")[0] ? TimeSense(elm?.updated_at) : TimeSense(elm?.created_at)}</td> */}
                                                        <td>{TimeSense(elm?.updated_at)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Invoice Date</th>
                                                        <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Criticality</th>
                                                        <td>
                                                            <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                        })}
                        {InvoiceList?.data?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">E-Way Bill list not found</td></tr>}
                    </tbody>
                </Table>
                {InvoiceList?.pagination?.total_records > 10 && (<CommonPagination 
                    currentPage={currentPage}
                    paginationLength={InvoiceList?.pagination}
                    currentFunction={currentFunction}
                />)}
            </Scrollbars>
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal} 
            setOrderViewerModal={setOrderViewerModal} 
        />
    </section>)
}

export default EWayBillReport;
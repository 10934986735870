/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Table } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { SPARES_CAMPATIBILITY_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios, errorCreateSparesCampatibilityList } from "../../../redux/actions/adminActions";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars-2';

const CreateSparesCompatibility = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ CategoryState, setCategoryState ] = useState({
        PrimaryItemCode: "",
        CompatibleItemCode: "",
        CompatibleItemName: "",
        CategoryType: ""
    });
    const { productlistall, ClearFormSet, sparescampatibiityList, errorSparesCampatibiityList,device_id } = useSelector((state) => state.adminReducers);
    const PrimaryItemCodeOptions = productlistall?.filter((elm) => elm?.is_spare === true && (elm?.erp_id !== null && elm?.erp_id !== ""))?.map((item, index)=>({...item, key:item.id, value:item?.id , label: `${item?.product_name} - (${item?.erp_id})` }));
    const CompatibleItemCodeOptions = productlistall?.filter((elm) => elm?.is_bike === true && (elm?.erp_id !== null && elm?.erp_id !== ""))?.map((item, index)=>({...item, key:item.id, value:item?.id , label: `${item?.product_name} - (${item?.erp_id})` }));

    // ERROR STATE
    const [ errorPrimaryItemCode, seterrorPrimaryItemCode ] = useState("");
    const [ errorCompatibleItemCode, seterrorCompatibleItemCode ] = useState("");
    const [ errorCompatibleItemName, seterrorCompatibleItemName ] = useState("");
    const [ errorCategoryType, seterrorCategoryType ] = useState("");
    const { roomId } = useParams();
    const CurrentData_ = sparescampatibiityList?.list?.filter((elm) => elm?.id === roomId)[0];

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(() => {
        if(LocationRoute === "edit-spares-compatibility") {
            setTimeout(() => {
                const UpdatePrimaryItemCodeOptions = PrimaryItemCodeOptions?.filter((elm) => elm?.erp_id === CurrentData_?.primary_item_code)[0];
                const UpdateCompatibleItemCodeOptions = CompatibleItemCodeOptions?.filter((elm) => elm?.erp_id === CurrentData_?.compatible_item_code)[0];

                setCategoryState({
                    ...CategoryState,
                    PrimaryItemCode: UpdatePrimaryItemCodeOptions === undefined ? "" : UpdatePrimaryItemCodeOptions,
                    CompatibleItemCode: UpdateCompatibleItemCodeOptions === undefined ? "" : UpdateCompatibleItemCodeOptions,
                    CategoryType: CurrentData_?.type,
                });
            }, 500);
        };
    }, [ CurrentData_ ]);


    // SELECT PRIMARY ITEMS CODE
    const PrimaryItemCodeSelect = (value) => {
        if(value !== null) {
            setCategoryState({...CategoryState, PrimaryItemCode: value});
            CheckValid(value, {type: 'PrimaryItemCode', error: seterrorPrimaryItemCode});
        } else {
            setCategoryState({...CategoryState, PrimaryItemCode: "", CompatibleItemName: ""});
            CheckValid(value === null ? "" : value, {type: 'PrimaryItemCode', error: seterrorPrimaryItemCode});
            CheckValid(value === null ? "" : value, {type: 'CompatibleItemName', error: seterrorCompatibleItemName});
        }
    };

    // SELECT COMPATIBLE ITEM CODES
    const CompatibleItemCodeSelect = (value) => {
        if(value !== null) {
            setCategoryState({...CategoryState, CompatibleItemCode: value});
            CheckValid(value, {type: 'CompatibleItemCode', error: seterrorCompatibleItemCode});
        } else {
            setCategoryState({...CategoryState, CompatibleItemCode: "", CompatibleItemName: ""});
            CheckValid(value === null ? "" : value, {type: 'CompatibleItemCode', error: seterrorCompatibleItemCode});
            CheckValid(value === null ? "" : value, {type: 'CompatibleItemName', error: seterrorCompatibleItemName});
        }
    };

    // SELECT CATEGORY TYPE
    const SelectCategoryType = (value) => {
        if(value !== "") {
            setCategoryState({...CategoryState, CategoryType: value});
            CheckValid(value === null ? "" : value, {type: 'CategoryType', error: seterrorCategoryType});
        } else {
            setCategoryState({...CategoryState, CategoryType: value});
            CheckValid(value === null ? "" : value, {type: 'CategoryType', error: seterrorCategoryType});
        };
    };

    useEffect(() => {
        if(CategoryState?.PrimaryItemCode !== "" && CategoryState?.CompatibleItemCode !== "") {
            let FinalCompatibleItemName;
            if(CategoryState?.CompatibleItemCode?.product_name?.includes("-")) {
                let CurrentCompatibaleName = CategoryState?.CompatibleItemCode?.product_name?.split("-");
                CurrentCompatibaleName?.pop();
                CurrentCompatibaleName[CurrentCompatibaleName.length - 1] = CurrentCompatibaleName[CurrentCompatibaleName.length - 1].trim();
                FinalCompatibleItemName = CurrentCompatibaleName?.toString()?.replaceAll(" ,","") + "-" +CategoryState?.PrimaryItemCode?.product_name; 
            } else {
                FinalCompatibleItemName = CategoryState?.CompatibleItemCode?.product_name + "-" +CategoryState?.PrimaryItemCode?.product_name; 
            };
            setCategoryState({...CategoryState, CompatibleItemName: FinalCompatibleItemName});
            seterrorCompatibleItemName("");
        };
    }, [ CategoryState?.PrimaryItemCode, CategoryState?.CompatibleItemCode ]);


    const [ InitialSpareItems, setInitialSpareItems ] = useState([]);
    let varInitialSpareItems = [...InitialSpareItems];

    useEffect(() => {
        if(errorSparesCampatibiityList?.length > 0) {
            setInitialSpareItems(errorSparesCampatibiityList);
        }
    }, [ errorSparesCampatibiityList ]);

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    // CREATE COMAPTIBILITY
    const createCompatibility = () => {
        if(CategoryState?.PrimaryItemCode !== "" &&  CategoryState?.CompatibleItemCode !== "" && 
            CategoryState?.CompatibleItemName.trim() !== "" && CategoryState?.CategoryType !== ""
        ) {
            if(LocationRoute === "edit-spares-compatibility") {
                CallAPISparesCompatibilty("edit");
            } else {
                let obj = {
                    ui_id: generateRandomId(),
                    product_id: CategoryState?.PrimaryItemCode?.id,
                    primary_item_code : CategoryState?.PrimaryItemCode?.erp_id, 
                    primary_item_name : CategoryState?.PrimaryItemCode?.product_name,
                    primary_item_id : CategoryState?.PrimaryItemCode?.id,
                    compatible_item_code : CategoryState?.CompatibleItemCode?.erp_id,
                    compatible_item_name : CategoryState?.CompatibleItemName,
                    compatible_item_id : CategoryState?.CompatibleItemCode?.id, 
                    category_type : CategoryState?.CategoryType
                };
                varInitialSpareItems?.push(obj);
                setInitialSpareItems(varInitialSpareItems);
                FormClear();
            };
        } else {
            CheckValid(CategoryState?.PrimaryItemCode, {type: 'PrimaryItemCode', error: seterrorPrimaryItemCode});
            CheckValid(CategoryState?.CompatibleItemCode, {type: 'CompatibleItemCode', error: seterrorCompatibleItemCode});
            CheckValid(CategoryState?.CompatibleItemName.trim(), {type: 'CompatibleItemName', error: seterrorCompatibleItemName});
            CheckValid(CategoryState?.CategoryType, {type: 'CategoryType', error: seterrorCategoryType});
        };
    };

    // MERGE WITH PRIMARY ITEM CODE
    const mergeSpareItems = InitialSpareItems?.reduce((acc, item) => {
        const key = item.primary_item_code;
        const existing = acc.find(obj => obj.primary_item_code === key);
        
        if (existing) {
            existing.items.push(item);
        } else {
            acc.push({
                primary_item_code: key,
                items: [item]
            });
        }
        return acc;
    }, []);

    // FORM CLEAR FUNCTION
    const FormClear = () => {
        setCategoryState({
            ...CategoryState,
            PrimaryItemCode: "",
            CompatibleItemCode: "",
            CompatibleItemName: "",
            CategoryType: ""
        });
    };
        
    // CALLING ADD & UPDATE API
    const CallAPISparesCompatibilty = (url) => {
        if(url === "edit") {
            Dispatch(ClearFormSetFutios({
                url: "spare_item_edit",
                action: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "spare_item_edit",
                "request" : { 
                    "spare_id": CurrentData_?.id,
                    "product_id": CategoryState?.PrimaryItemCode?.id,
                    "primary_item_code" : CategoryState?.PrimaryItemCode?.erp_id, 
                    "primary_item_name" : CategoryState?.PrimaryItemCode?.product_name,
                    "compatible_item_code" : CategoryState?.CompatibleItemCode?.erp_id,
                    "compatible_item_name" : CategoryState?.CompatibleItemName, 
                    "category_type" : CategoryState?.CategoryType
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            let mergedItems = mergeSpareItems.flatMap(obj => obj.items);
            const UpdatemergedItems = mergedItems?.map((elm) => {
                return{
                    ui_id: elm?.ui_id,
                    product_id: elm?.product_id,
                    primary_item_code: elm?.primary_item_code,
                    primary_item_name: elm?.primary_item_name,
                    compatible_item_code: elm?.compatible_item_code,
                    compatible_item_name: elm?.compatible_item_name,
                    compatible_item_id: elm?.compatible_item_id,
                    category_type: elm?.category_type,
                }
            });
            

            Dispatch(ClearFormSetFutios({
                url: "spare_item_add",
                action: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "spare_item_add",
                "request" : { 
                    "spare_items": UpdatemergedItems,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "spare_item_add" || ClearFormSet?.url === "spare_item_edit")) {
            Navigate(SPARES_CAMPATIBILITY_URL);
        } else {
            // setInitialSpareItems([]);
        };
    }, [ ClearFormSet ]);

    // OPTIONS PRIMARY ITEM CODE & COMPATABLE ITEM CODE UPDATE WHEN SELECTED
    const optionSchemeListUpdate = (data, url) => {
        const schemeCodeExit = InitialSpareItems?.map((elm) => elm?.[url]);
        const schemeList = data?.filter((elmz) => !schemeCodeExit.includes(elmz?.id));
        return schemeList;
    };

    // DELETE FUNCTION
    const DeleteCompatible = (delete_id) => {
        const DeleteCompList = InitialSpareItems?.map((elm) => {
            if(elm?.ui_id !== delete_id) {
                return elm;
            }
        })?.filter((elm) => elm !== undefined);
        setInitialSpareItems(DeleteCompList);
    };

    // DELETE ALL SPARES COMPATIBILITY
    const delete_existed_items = () => {
        Dispatch(errorCreateSparesCampatibilityList([]));
    };

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={SPARES_CAMPATIBILITY_URL} className="btn btn-link backbtn" onClick={() => delete_existed_items()}><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <Scrollbars 
            style={{ height: "calc(100vh - 148px)"}} 
            renderView={props => <div {...props} className="view"/>}
            className="ScrollbarsSidebar"
        >
            <div className={LocationRoute === "edit-spares-compatibility" ? "formloginset tabsWrappers" : "formloginset"}>
                <div className="row">
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Primary Item Code<span className="mendatory_star">*</span></Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={CategoryState?.PrimaryItemCode}
                                    onChange={(e) => PrimaryItemCodeSelect(e)} 
                                    isClearable 
                                    // options={optionSchemeListUpdate(PrimaryItemCodeOptions, "primary_item_id")}
                                    options={PrimaryItemCodeOptions}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    placeholder="Select Primary Item Code"
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                />
                                {errorPrimaryItemCode !== "" && <span className="error_medotory">{errorPrimaryItemCode}</span>}
                            </div>
                        </Form.Group>
                    </div>
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Compatible Item Code<span className="mendatory_star">*</span></Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={CategoryState?.CompatibleItemCode}
                                    onChange={(e) => CompatibleItemCodeSelect(e)} 
                                    isClearable 
                                    options={optionSchemeListUpdate(CompatibleItemCodeOptions, "compatible_item_id")}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    placeholder="Select Compatible Item Code"
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                />
                                {errorCompatibleItemCode !== "" && <span className="error_medotory">{errorCompatibleItemCode}</span>}
                            </div>
                        </Form.Group>
                    </div>
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Compatible Item Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={CategoryState?.CompatibleItemName} 
                                onChange={(e) => setCategoryState({...CategoryState, CompatibleItemName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'CompatibleItemName', error: seterrorCompatibleItemName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Compatible Item Name"
                                disabled={true}
                            />
                            {errorCompatibleItemName !== "" && <span className="error_medotory">{errorCompatibleItemName}</span>}
                        </Form.Group>
                    </div>
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Category Type<span className="mendatory_star">*</span></Form.Label>
                            <select 
                                className="form-control"
                                value={CategoryState?.CategoryType}
                                onChange={(e) => SelectCategoryType(e.target.value)}
                            >
                                <option value={""}>Select Category Type</option>
                                <option value={"A"}>A</option>
                                <option value={"B"}>B</option>
                                <option value={"C"}>C</option>
                                <option value={"D"}>D</option>
                            </select>
                            {errorCategoryType !== "" && <span className="error_medotory">{errorCategoryType}</span>}
                        </Form.Group>
                    </div>
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <Form.Group className="mb-3" >
                            {LocationRoute === "create-spares-compatibility" && (<Form.Label>&nbsp;</Form.Label>)}
                            <Button 
                                id="createSpares"
                                className="formbtn" 
                                disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "spare_item_add" || ClearFormSet?.url === "spare_item_edit")}
                                onClick={() => createCompatibility()} 
                                variant="primary" 
                            >
                                {LocationRoute === "edit-spares-compatibility" ? "Update" : "Add"}
                            </Button>
                            <Button 
                                id="createSparesClear"
                                className="formbtn" 
                                onClick={() => FormClear()} 
                                variant="primary" 
                            >
                                Clear
                            </Button>
                        </Form.Group>
                    </div>
                    {mergeSpareItems?.length > 0 && (<div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <Form.Label>&nbsp;</Form.Label>
                            <ul className="statusof_spare_Items">
                                <li><span className="error"></span> Already Exits</li>
                                <li><span className="success"></span> New</li>
                            </ul>
                        </Form.Group>
                    </div>)}
                </div>
            </div>
            {errorSparesCampatibiityList?.length > 0 && (<div className="reassign_info" style={{ marginBottom: "15px" }}>
                <i className="bi bi-info-circle-fill"></i> If you want to add that spare, you should delete the existing spare item data row from the table below, then click the create button.
            </div>)}
            <div className="tableView gstreporttableadded">
                {mergeSpareItems?.length > 0  && (<React.Fragment>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th rowSpan={2}>SNO.</th>
                                <th rowSpan={2}>Primary Item Code</th>
                                <th colSpan={3} style={{ textAlign: "center" }}>Compatible Items</th>
                                <th rowSpan={2}>Action</th>
                            </tr>
                            <tr>
                                <th>Compatible Item Code</th>
                                <th>Compatible Item Name</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mergeSpareItems?.map((elm, index) => {
                                return (<React.Fragment key={index}>
                                    {elm?.items?.map((elz, subIndex) => {
                                        return(<tr key={`${index}-${subIndex}`}>
                                            {subIndex === 0 ? (
                                                <>
                                                    <td rowSpan={elm?.items?.length}>{index + 1}</td>
                                                    <td rowSpan={elm?.items?.length}>{elm?.primary_item_code}</td>
                                                </>
                                            ) : null}
                                            <td className={elz?.error ? "error_spare_item" : ""}>{elz?.compatible_item_code}</td>
                                            <td className={elz?.error ? "error_spare_item" : ""}>{elz?.compatible_item_name}</td>
                                            <td className={elz?.error ? "error_spare_item" : ""}>{elz?.category_type}</td>
                                            <td>
                                                <Button className="btn edittable" onClick={() => DeleteCompatible(elz?.ui_id)} style={{ marginLeft: "7px" }}>
                                                    <i className="bi bi-trash" style={{ color: "red" }}></i> 
                                                </Button>
                                            </td>
                                        </tr>)
                                    })}
                                </React.Fragment>);
                            })}
                        </tbody>
                    </Table>
                    <div className="bottom_button_design">
                        <Button 
                            className="formbtn" 
                            onClick={() => CallAPISparesCompatibilty("add")} 
                            variant="primary" 
                        >
                            Create
                        </Button>
                    </div>
                </React.Fragment>)}
            </div>
        </Scrollbars>
    </section>)
}

export default CreateSparesCompatibility;
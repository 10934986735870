/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form, Badge, Image, Table, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonLogout from "./CommonLogout";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import CommonToggle from "../Common/CommonToggle";
import { useParams, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import CommonDateTime from "./CommonDateTime";
import { CREATE_SO_FROM_QUOTATIONS_API_URL, DEBIT_CREDIT_NOTE_REPORT } from "../Shared/constant";
import Scrollbars from "react-custom-scrollbars-2";
import GetCurrentTableSRNo from "./GetCurrentTableSRNo";
import AmountNumberFormat from "./AmountNumberFormat";
import CommonPagination from "./CommonPagination";
import DataTableFilter from "./DataTableFilter";
import { StoredDetailLogReport, StartMainCommonLoader, getRelatedCourierList, getFranchiseOrderLatestThreeList, getErpAccountLeadgerList, StoredLevelAllReport, TicketsVerfiyAPI, ClearFormSetFutios } from "../../redux/actions/adminActions";
import UploadFileAPI from "../../Api/UploadFileAPI";
import CommonStatus from "./CommonStatus";
import GetErpInvoiceAPI from "../../Api/GetErpInvoiceAPI";
import { toast } from "react-toastify";
import ErpAFSOrderSyncRetryAPI from "../../Api/ErpAFSOrderSyncRetryAPI";
import CommonLoader from "./CommonLoader";

const CommonewModel = (props) => {
    const [userType, setUserType] = useState("all");
    const today = new Date();
    const Dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [ showLoader, setshowLoader ] = useState(false);
    const { websocket } = useContext(WebSocketContext);
    const { showModalNew, setShowModalNew, ConfirmOrder, UpdateInwardFunctions, formData, setFormData, tabName, setModuleCurrentPage, APIcalling } = props;
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const Navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [userLimit, setUserLimit] = useState(10);
    const [userSearch, setUserSearch] = useState("");
    const [allRecordLength, setAllRecordLength] = useState(0)
    const { accessToken, device_id, boxList, RelatedCourierList, AllDealerFranchiseTypeListAll, wareHouselistall, ClearFormSet, DebitCreditNoteReportList, storedLevelAllReportList, selfInfo, storedDetailsLogReportList, commonMainLoader, franchiseOrderLatestThreeListl, ErpLedgerReportList, ErpGeneralLedgerReportList, SoApprovalstoredLevelReportList } = useSelector((state) => state.adminReducers);
    const [CreateBoxState, setCreateBoxState] = useState({
        box_name: "",
        box_height: "",
        box_width: "",
        box_length: "",
        is_active: true
    })
    const [ invoiceNumber, setinvoiceNumber ] = useState("");
    const [ errorInvoiceNumber, errorSetinvoiceNumber ] = useState(""); 
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    const exceptThisCostSymbols = ["e", "E", "+", "-"];
    const boxData = boxList?.list?.filter((elm) => elm?.id === showModalNew?.Data?.id)[0];
    const [ApplicationState, setApplicationState] = useState({
        State: "",
        remark: "",
        amount: "",
        deposit_bank: "",
        transaction_number: ""
    });
    const [PaymentApprovalState, setPaymentApprovalState] = useState({
        transaction_number: "",
        payment_date: null,
        upload_attachment: {},
        payment_mode: "",
    });
    const [LoaderFile, setLoaderFile] = useState(false);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // error state
    const [errorbox_name, setErrorbox_name] = useState("");
    const [errorbox_height, setErrorbox_height] = useState("");
    const [errorbox_width, setErrorbox_width] = useState("");
    const [errorbox_length, setErrorbox_length] = useState("");
    const [errorBillingNumber, setErrorBillingNumber] = useState("");
    const [errorPaymentMode, setErrorPaymentMode] = useState("");
    const [errorPaymentDate, setErrorPaymentDate] = useState("");
    const [ errorUploadAttachment, setErrorUploadAttachment ] = useState("");
    
    //pament
    const [remark, setRemarkError] = useState("");
    const [franchise_name, setErrorfranchise_name] = useState("");
    const [amount, setErroramount] = useState("");
    const [deposit_bank, setErrordeposit_bank] = useState("");
    const [transaction_number, setErrortransaction_number] = useState("");

    const { roomId } = useParams();
    const [franchise_code, setFranchise_code] = useState("")
    // close modal
    const [optionsState, SetOptionsState] = useState([]);

    const handleClose = () => {
        setUserLimit(10)
        setCurrentPage(1)
        setShowModalNew({ ...showModalNew, open: false });
        Dispatch(StoredDetailLogReport({}));
        Dispatch(getFranchiseOrderLatestThreeList([]));
        Dispatch(getErpAccountLeadgerList({}));
        Dispatch(StoredLevelAllReport({}));
        Dispatch(getRelatedCourierList({}));
    };

    const handleCloseTickets = () => {
        setUserLimit(10)
        setCurrentPage(1)
        setShowModalNew({ ...showModalNew, open: false });
        Dispatch(StoredDetailLogReport({}));
        Dispatch(getFranchiseOrderLatestThreeList([]));
        Dispatch(getErpAccountLeadgerList({}));
        Dispatch(StoredLevelAllReport({}));
        Dispatch(getRelatedCourierList({}));
        Dispatch(TicketsVerfiyAPI({}));
    };

    const GetFranchiseData = (id, array_data) => {
        const franchise = array_data?.franchise?.filter((elm) => elm?.id === id)[0];
        return {
            FranchiseCode: franchise?.franchise_code,
            FranchiseName: franchise?.customer_name,
            franchise_id: franchise?.id
        };
    };

    const GetUserData = (id) => {
        const user = DebitCreditNoteReportList?.user?.filter((elm) => elm?.id === id)[0];
        return {
            UserName: user?.full_name,
        };
    };
    

    var DebitCreditNoteReport = [];
    DebitCreditNoteReport = DebitCreditNoteReportList?.list?.filter((elm) => elm?.franchise_id === showModalNew?.Data?.franchise_id).map((item) => item);
    // const totalLength = DebitCreditNoteReport = DebitCreditNoteReportList?.list?.filter((elm) => elm?.franchise_id === showModalNew?.Data?.franchise_id);

    // total table count
    const TotalTableCount = (list, url) => {
        if (list?.length > 0) {
            if (url === "credit_amt") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.credit_amt);
                }
                return sum;
            } else if (url === "debit_amt") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.debit_amt);
                }
                return sum;
            } else if (url === "credit_note") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.credit_note);
                }
                return sum;
            } else if (url === "outstanding_amt") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.outstanding_amt);
                }
                return sum;
            } else if (url === "credit") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.credit);
                }
                return sum;
            } else if (url === "debit") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.debit);
                }
                return sum;
            } else if (url === "balance") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.balance);
                }
                return sum;
            };
        } else {
            return 0;
        };
    };

    useEffect(() => {
        callFilterData();
    }, [currentPage, userLimit, userSearch, FromDate, ToDate, showModalNew?.Data]);

    const callFilterData = () => {
        if(showModalNew?.subtitle === "Table") {
            let param = {
                "transmit": "broadcast",
                "url": "dncn_list",
                "request": {
                    "limit": userLimit,
                    "page_no": currentPage,
                    "from_date": null,
                    "to_date": null,
                    "franchise_id": showModalNew?.Data?.franchise_id
                },
                "DeviceId": device_id
            };
            if (FromDate !== "" && ToDate !== "") {
                param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
            }
            wsSend_request(websocket, param);
        } else if(showModalNew?.subtitle === "Table-ERP") {
            let param = {
                "transmit": "broadcast",
                "url": "erp_account_leadger_list",
                "request": {
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch?.trim(),
                    "order_by": "-updated_at",
                    "erp_id": showModalNew?.Data?.erp_id
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        } else if(showModalNew?.subtitle === "Table-ERP-General") {
            let param = {
                "transmit": "broadcast",
                "url": "erp_general_leadger_list",
                "request": {
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch?.trim(),
                    "order_by": "-updated_at",
                    "erp_id": showModalNew?.Data?.erp_id
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    };

    useEffect(() => {
        if(showModalNew?.subtitle === "Check Stock Level") {
            let param = {
                "transmit": "broadcast",
                "url": "store_level_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": "",
                    "from_date": null,
                    "to_date": null,
                    "warehouse_id": null,
                    "product_id": showModalNew?.Data,
                    "category_id": null,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        } else if(showModalNew?.subtitle === "Check Serviceable") {
            let param = {
                "transmit": "broadcast",
                "url": "related_courier_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": "",
                    "warehouse_id": showModalNew?.Data?.warehouse_id,
                    "pin_code": showModalNew?.Data?.pin_code,
                    "exclude_pin_codes": showModalNew?.Data?.before_load_couriers_ids?.toString(),
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, [ showModalNew ]);

    useEffect(() => {
        if (showModalNew?.subtitle === "Stock Inward Log Details") {
            if(showModalNew?.Data?.stock_action === "ERP_CONSUMPTION") {
                if(showModalNew?.Data?.stock_id) {
                    let param = {
                        "transmit": "broadcast",
                        "url": "store_frame_list",
                        "request": {
                            "status": userType,
                            "limit": userLimit,
                            "page_no": currentPage,
                            "search": userSearch.trim(),
                            "stock_id": showModalNew?.Data?.stock_id,
                            "product_id": null,
                            "warehouse_id": null,
                        },
                        "DeviceId": device_id,
                        "stock_url": "StockInwardLogDetails"
                    };
                    wsSend_request(websocket, param);
                };
            } else {
                if(showModalNew?.stock_log_id) {
                    let param = {
                        "transmit": "broadcast",
                        "url": "store_frame_list",
                        "request": {
                            "status": userType,
                            "limit": userLimit,
                            "page_no": currentPage,
                            "search": userSearch.trim(),
                            "stock_log_id": showModalNew?.stock_log_id,
                            "product_id": null,
                            "warehouse_id": null,
                        },
                        "DeviceId": device_id,
                        "stock_url": "StockInwardLogDetails"
                    };
                    wsSend_request(websocket, param);
                };
            };
        };
    }, [currentPage, userLimit, FromDate, ToDate, showModalNew?.stock_log_id, userSearch]);

    useEffect(() => {
        if (showModalNew?.subtitle === "Update New Box") {
            setCreateBoxState({
                ...CreateBoxState,
                box_name: boxData?.box_name,
                box_height: boxData?.box_height,
                box_width: boxData?.box_width,
                box_length: boxData?.box_length,
                is_active: boxData?.is_active,
            });
        };
    }, [boxData]);

    useEffect(() => {
        if(showModalNew?.title === "Update Payment Details") {
            setPaymentApprovalState({
                ...PaymentApprovalState,
                transaction_number: showModalNew?.Data?.txn_id,
                payment_date: new Date(showModalNew?.Data?.payment_date),
                upload_attachment: showModalNew?.Data?.payment_slip,
                payment_mode: showModalNew?.Data?.payment_mode,
            });
        };
    }, [ showModalNew?.Data ]);

    const CommonFunction = async () => {
        if (showModalNew?.subtitle === "Active Product Application") {
            let param = {
                "transmit": "broadcast",
                "url": "brand_edit",
                "request": {
                    "brand_image": showModalNew?.Data?.brand_image,
                    "brand_name": showModalNew?.Data?.brand_name,
                    "brand_id": showModalNew?.Data?.id,
                    "is_active": showModalNew?.Data?.is_active ? false : true,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
            handleClose();
        } else if (showModalNew?.subtitle === "Active Category") {
            let param = {
                "transmit": "broadcast",
                "url": "category_edit",
                "request": {
                    "category_id": showModalNew?.Data?.id,
                    "category_image": showModalNew?.Data?.category_image,
                    "brand_id": showModalNew?.Data?.brand_id,
                    "category_name": showModalNew?.Data?.category_name,
                    "is_active": showModalNew?.Data?.is_active ? false : true,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
            handleClose();
        } else if (showModalNew?.subtitle === "Order-Purchase") {
            setLoader(true);
            await ConfirmOrder();
            setTimeout(() => setLoader(false), [500])
        } else if (showModalNew?.subtitle === "Create New Box") {
            if (CreateBoxState?.box_name !== "" && CreateBoxState?.box_height !== "" && CreateBoxState?.box_width !== "" &&
                CreateBoxState?.box_length !== "") {
                let param = {
                    "transmit": "broadcast",
                    "url": "box_add",
                    "request": {
                        "box_name": CreateBoxState?.box_name,
                        "box_height": CreateBoxState?.box_height,
                        "box_width": CreateBoxState?.box_width,
                        "box_length": CreateBoxState?.box_length,
                        "is_active": CreateBoxState?.is_active
                    },
                    "DeviceId": device_id
                };
                wsSend_request(websocket, param);
                OnSuccesListCall("box_list");
                handleClose();
            } else {
                CheckValid(CreateBoxState?.box_name, { type: 'box_name', error: setErrorbox_name });
                CheckValid(CreateBoxState?.box_height, { type: 'box_height', error: setErrorbox_height });
                CheckValid(CreateBoxState?.box_width, { type: 'box_width', error: setErrorbox_width });
                CheckValid(CreateBoxState?.box_length, { type: 'box_length', error: setErrorbox_length });
            };
        } else if (showModalNew?.subtitle === "Update New Box") {
            if (CreateBoxState?.box_name !== "" && CreateBoxState?.box_height !== "" && CreateBoxState?.box_width !== "" &&
                CreateBoxState?.box_length !== "") {
                let param = {
                    "transmit": "broadcast",
                    "url": "box_edit",
                    "request": {
                        "box_id": boxData?.id,
                        "box_name": CreateBoxState?.box_name,
                        "box_height": CreateBoxState?.box_height,
                        "box_width": CreateBoxState?.box_width,
                        "box_length": CreateBoxState?.box_length,
                        "is_active": CreateBoxState?.is_active
                    },
                    "DeviceId": device_id
                };
                wsSend_request(websocket, param);
                OnSuccesListCall("box_list");
                handleClose();
            } else {
                CheckValid(CreateBoxState?.box_name, { type: 'box_name', error: setErrorbox_name });
                CheckValid(CreateBoxState?.box_height, { type: 'box_height', error: setErrorbox_height });
                CheckValid(CreateBoxState?.box_width, { type: 'box_width', error: setErrorbox_width });
                CheckValid(CreateBoxState?.box_length, { type: 'box_length', error: setErrorbox_length });
            };
        } else if (showModalNew?.subtitle === "Order Warehouse Approve") {
            let param = {
                "transmit": "broadcast",
                "url": "wh_order_approval",
                "request": {
                    "order_id": roomId,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        } else if (showModalNew?.subtitle === "Order Admin Approve") {
            Dispatch(StartMainCommonLoader({
                url: "wh_approval",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "wh_approval",
                "request": {
                    "order_id": showModalNew?.Data?.order_id,
                    "warehouse_id": showModalNew?.Data?.warehouse_id,
                    "warehouse_remark": showModalNew?.Data?.warehouse_remark,
                    "dispatch_id": showModalNew?.Data?.dispatch_id,
                    "attachments": showModalNew?.Data?.attachments,
                    "order_priority": showModalNew?.Data?.order_priority,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        }  else if (showModalNew?.subtitle === "Order Warehouse Resign Approve") {
            Dispatch(StartMainCommonLoader({
                url: "wh_reassign",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "wh_reassign",
                "request": {
                    "order_id": showModalNew?.Data?.order_id,
                    "warehouse_id": showModalNew?.Data?.warehouse_id,
                    "warehouse_remark": showModalNew?.Data?.warehouse_remark,
                    "dispatch_id": showModalNew?.Data?.dispatch_id,
                    "attachments": showModalNew?.Data?.attachments,
                    "order_priority": showModalNew?.Data?.order_priority,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        } else if (showModalNew?.subtitle === "Order Note Submit") {
            let param = {
                "transmit": "broadcast",
                "url": "dncn_add",
                "request": {
                    "franchise_id": showModalNew?.Data?.franchise_id,
                    "reason_id": showModalNew?.Data?.reason_id,
                    "reason_type_id": showModalNew?.Data?.reason_type_id,
                    "reason_type": showModalNew?.Data?.reason_type,
                    "reason": showModalNew?.Data?.reason,
                    "adj_type": showModalNew?.Data?.adj_type,
                    "adj_amt": showModalNew?.Data?.adj_amt,
                    "remarks": showModalNew?.Data?.remarks,
                },
                "DeviceId": device_id
            };
            OnSuccesListCall("debit-credit-note-report")
            wsSend_request(websocket, param);
            // OnSuccesListCall("box_list");
        } else if (showModalNew?.subtitle === "Create New Payment") {
            if (ApplicationState?.State !== "" && ApplicationState?.amount !== "" && ApplicationState?.transaction_number !== "") {
                let param = {
                    "transmit": "broadcast",
                    "url": "dncn_payment",
                    "request": {
                        "franchise_id": ApplicationState?.State?.value,
                        "deposit_date": FromDate === "" ? null : CommonDateTime(FromDate, "YYYY-MM-DD", "hide"),
                        "deposit_bank": ApplicationState?.deposit_bank,
                        "txn_no": ApplicationState?.transaction_number,
                        "adj_amt": ApplicationState?.amount,
                        "remarks": ApplicationState?.remark,
                    },
                    "DeviceId": device_id
                };
                wsSend_request(websocket, param);
                handleClose();
            } else {
                CheckValid(ApplicationState?.State, { type: 'franchise_name', error: setErrorfranchise_name });
                CheckValid(ApplicationState?.amount, { type: 'amount', error: setErroramount });
                // CheckValid(ApplicationState?.deposit_bank, { type: 'deposit_bank', error: setErrordeposit_bank });
                CheckValid(ApplicationState?.transaction_number, { type: 'transaction_number', error: setErrortransaction_number });
                // CheckValid(ApplicationState?.remark, { type: 'Remark', error: setRemarkError });
            };
        } else if (showModalNew?.subtitle === "Ticket-Verify") {
            handleClose();
        } else if (showModalNew?.subtitle === "Logout") {
            CommonLogout(accessToken, Dispatch);
        } else if (showModalNew?.subtitle === "Order-Cancel") {
            props?.callBack()
        } else if (showModalNew?.subtitle === "Update Stock") {
            if(formData?.billing_number !== "") {
                UpdateInwardFunctions();
            } else {
                CheckValid(formData?.billing_number, { type: 'billing_number', error: setErrorBillingNumber });
            };
        } else if (showModalNew?.subtitle === "AFS-Order-Cancel" || showModalNew?.subtitle === "AFS-Order-Return") {
            Dispatch(StartMainCommonLoader({
                url: "afs_order_cancelled_reject_return",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "afs_order_cancelled_reject_return",
                "request": {
                    "order_id": showModalNew?.Data?.id,
                    "order_status": showModalNew?.subtitle === "AFS-Order-Cancel" ? "CANCELLED" : "RETURN",
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        } else if(showModalNew?.subtitle === "Get ERP Invoice") {
            if(invoiceNumber !== "") {
                CommonAPICall(invoiceNumber, showModalNew?.Data?.id)
            } else {
                CheckValid(invoiceNumber, { type: 'invoiceNumber', error: errorSetinvoiceNumber })
            };
        } else if(showModalNew?.subtitle === "Payment Approval") {
            Dispatch(StartMainCommonLoader({
                url: "update_payment_receipt",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "update_payment_receipt",
                "request": {
                    "payment_id": showModalNew?.Data?.id,
                    "status": "approved",
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
            APIcalling();
        } else if(showModalNew?.subtitle === "SALE-CREATE-ORDER") {
            setLoader(true);
            setshowLoader(true);
            const formData = new FormData();
            formData.append("quotation_id", showModalNew?.Data?.id);
            const Response = await ErpAFSOrderSyncRetryAPI(CREATE_SO_FROM_QUOTATIONS_API_URL, formData, accessToken);
            if(Response?.status === 200) {
                setLoader(false);
                setshowLoader(false);
                toast.success(Response?.message);
                APIcalling();
                handleClose();
            } else {
                setLoader(false);
                setshowLoader(false);
                APIcalling();
                if(Response?.error) {
                    toast.error(Response?.error);
                } else {
                    toast.error(Response?.message);
                };
                if(Response?.message === "User is not authorized." && Response?.status === 400) {
                    CommonLogout(accessToken, Dispatch);
                    localStorage?.removeItem("access_token");
                };
            };
        } else if(showModalNew?.subtitle === "Enter Payment Details" || showModalNew?.title === "Update Payment Details") {
            if(selfInfo?.user?.user_type === 'super_admin') {
                QuotationsPaymentUpdateApiCall();
            } else {
                const checkPermissions = selfInfo?.user?.permissions_access?.AFSOrderManagement[5].API?.includes("add_payment_detail");
                if(tabName === "payment_approval_pending_edits") {
                    if(selfInfo?.user?.user_type === 'super_admin' || checkPermissions) {
                        QuotationsPaymentUpdateApiCall();
                    } else {
                        toast.error("User is not authorized.");
                        CommonLogout(accessToken, Dispatch);
                    };
                } else {
                    QuotationsPaymentUpdateApiCall();
                };
            };
        };
    };

    const QuotationsPaymentUpdateApiCall = () => {
        if(PaymentApprovalState?.transaction_number !== "" && (PaymentApprovalState?.payment_date !== "" && PaymentApprovalState?.payment_date !== null) && Object.keys(PaymentApprovalState?.upload_attachment)?.length > 0 && PaymentApprovalState?.payment_mode !== "") {
            Dispatch(StartMainCommonLoader({
                url: "add_payment_detail",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "add_payment_detail",
                "request": {
                    "quotation_id": showModalNew?.Data?.id,
                    "txn_id": PaymentApprovalState?.transaction_number,
                    "payment_date": CommonDateTime(PaymentApprovalState?.payment_date, "YYYY-MM-DD", "hide"),
                    "payment_mode": PaymentApprovalState?.payment_mode,
                    "payment_slip": PaymentApprovalState?.upload_attachment?.id,
                    "quotation_status": showModalNew?.subtitle === "Enter Payment Details" ? "PAYMENT_APPROVAL_PENDING" : "PAID"
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
            APIcalling();
        } else {
            CheckValid(PaymentApprovalState?.transaction_number, { type: 'transaction_number', error: setErrortransaction_number });
            CheckValid(PaymentApprovalState?.payment_date === null ? "" : PaymentApprovalState?.payment_date, { type: 'payment_date', error: setErrorPaymentDate });
            CheckValid(PaymentApprovalState?.upload_attachment, { type: 'upload_attachment', error: setErrorUploadAttachment });
            CheckValid(PaymentApprovalState?.payment_mode, { type: 'payment_mode', error: setErrorPaymentMode });
        };
    };

    // API CALLING FUCNTION
    const CommonAPICall = async (invoiceNumber, order_id) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", order_id);
        formData.append("invoice_code", invoiceNumber);
        const response = await GetErpInvoiceAPI(formData, accessToken);
        if(response?.status === 200) {
            setLoader(false);
            handleClose();
            OnSuccesListCall("afs_order_list");
            toast.success(response?.message);
        } else {
            setLoader(false);
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    // get warehouse code
    const GetWareHouseCode = (warehouse_id) => {
        return wareHouselistall?.filter((elm) => elm?.id === warehouse_id)[0]?.warehouse_name;
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setFromDate("");
        } else {
            setFromDate(date);
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            setToDate("");
        } else {
            setToDate(date);
        }
    };
    const OnSuccesListCall = (url) => {
        if (url === "box_list") {
            let param = {
                "transmit": "broadcast",
                "url": url,
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        } else if(url === "afs_order_list") {
            let param = {
                "transmit": "broadcast",
                "url": "afs_order_list",
                "request": {
                    "status": "OPEN,INVOICE_PENDING",
                    "limit": 10,
                    "page_no": 1,
                    "search": "",
                    "from_date": null,
                    "to_date": null,
                    "from_inv": null,
                    "to_inv": null,
                    "region_id": "",
                    "order_by": "-"
                },
                "DeviceId": device_id
            }
            wsSend_request(websocket, param);
        } else if(url === "quotation_list") {
            let param = {
                "transmit": "broadcast",
                "url": "quotation_list",
                "request": {
                    "quotation_status": "CREATED,PAYMENT_PENDING",
                    "quotation_type": "",
                    "limit": 10,
                    "page_no": 1,
                    "search": "",
                    "from_date": null,
                    "to_date": null,
                    "region_id": "",
                    "order_by": ""
                },
                "quotation_list_type": "created_payment_pending_quotations_listing",
                "DeviceId": device_id
            }
            wsSend_request(websocket, param);
        } else if(url === "quotation_payment_approval_pending_list") {
            let param = {
                "transmit": "broadcast",
                "url": "quotation_list",
                "request": {
                    "quotation_status": "PAYMENT_APPROVAL_PENDING",
                    "quotation_type": "",
                    "limit": 10,
                    "page_no": 1,
                    "search": "",
                    "from_date": null,
                    "to_date": null,
                    "region_id": "",
                    "order_by": ""
                },
                "quotation_list_type": false,
                "DeviceId": device_id
            }
            wsSend_request(websocket, param);
        };
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });
    
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(() => {
        SetOptionsState(AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.parent_id === null )?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.customer_name} (${item?.franchise_code})` })))
    }, [AllDealerFranchiseTypeListAll])

    useEffect(() => {
        if (ApplicationState?.State) {
            const franchise = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.customer_name === ApplicationState?.State?.label)?.map((item) => item);
            setFranchise_code(franchise[0]?.franchise_code);
        }
    }, [ApplicationState?.State, AllDealerFranchiseTypeListAll]);


    const StateValueSelect = (value) => {
        if (value !== null) {
            setApplicationState({ ...ApplicationState, State: value });
            CheckValid(value, { type: 'franchise_name', error: setErrorfranchise_name });
        } else {
            setApplicationState({ ...ApplicationState, State: "" });
            CheckValid(value === null ? "" : value, { type: 'franchise_name', error: setErrorfranchise_name });
        }
    };

    // onchange payment date
    const onChangePaymentDate = (date) => {
        if(date !== "" && date !== null) {
            setPaymentApprovalState({...PaymentApprovalState, payment_date: date});
            CheckValid(date, { type: 'payment_date', error: setErrorPaymentDate });
        } else {
            setPaymentApprovalState({...PaymentApprovalState, payment_date: null});
            CheckValid(date, { type: 'payment_date', error: setErrorPaymentDate });
        };
    };

    // upload attachments
    const UploadImage = async (e, url) => {
        const files = e.target.files[0];
        if (files) {
            setLoaderFile(true);
            const formDatas = new FormData();
            formDatas.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formDatas);
            if (fileresponse?.status === 200) {
                if(url === "Update Stock") {
                    setFormData({
                        ...formData,
                        attachment_file: {
                            ...formData?.attachment_file,
                            file_name: fileresponse?.name,
                            file_id: fileresponse?.id,
                        },
                    });
                } else {
                    setPaymentApprovalState({
                        ...PaymentApprovalState,
                        upload_attachment: fileresponse,
                    });
                    CheckValid(fileresponse, { type: 'upload_attachment', error: setErrorUploadAttachment });
                };
                
                setTimeout(() => {
                    setLoaderFile(false);
                }, 1000);
                document.getElementById('fileInput').value = null;
            } else {
                setLoaderFile(false);
                if(url === "Update Stock") {
                    setFormData({
                        ...formData,
                        attachment_file: {
                            ...formData?.attachment_file,
                            file_name: "",
                            file_id: "",
                        },
                    });
                } else {
                    setPaymentApprovalState({
                        ...PaymentApprovalState,
                        upload_attachment: {},
                    });
                    CheckValid({}, { type: 'upload_attachment', error: setErrorUploadAttachment });
                };
            };
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "dncn_add") {
            handleClose();
            Navigate(DEBIT_CREDIT_NOTE_REPORT);
        } else if(ClearFormSet?.action === 200 && ClearFormSet?.url === "stock_inward_add") {
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
            handleClose();
        } else if(ClearFormSet?.action === 200 && ClearFormSet?.url === "afs_order_cancelled_reject_return") {
            handleClose();
        } else if(ClearFormSet?.action === 200 && ClearFormSet?.url === "update_payment_receipt") {
            handleClose();
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        } else if(ClearFormSet?.action === 200 && ClearFormSet?.url === "add_payment_detail") {
            handleClose();
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
            setPaymentApprovalState({
                ...PaymentApprovalState,
                transaction_number: "",
                payment_date: null,
                upload_attachment: {},
                payment_mode: "",
            });
        };
    }, [ClearFormSet]);

    useEffect(() => {
        if(showModalNew?.subtitle === "Franchise Orders Tables") {
            if(showModalNew?.Data) {
                Dispatch(StartMainCommonLoader({
                    url: "get_franchise_order",
                    loader: true
                }));
                let param = {
                    "transmit": "broadcast",
                    "url": "get_franchise_order",
                    "request": {
                       "franchise_id": showModalNew?.Data,
                    },
                    "DeviceId": device_id
                };
                wsSend_request(websocket, param);
            } else {
                Dispatch(StartMainCommonLoader({
                    url: "get_franchise_order",
                    loader: false
                }));
            };
        };
    }, [ showModalNew?.Data ]);

    // order items
    const GetOrderProductName = (order_items) => {
        return(<Table style={{ marginBottom: "0px" }} bordered >
            <thead>
                <tr>
                    <th style={{ padding: "5px 10px", fontSize: "12px" }}>SNo</th>
                    <th style={{ padding: "5px 10px", fontSize: "12px" }}>Product Name</th>
                    <th style={{ padding: "5px 10px", fontSize: "12px" }}>Qty</th>
                </tr>
            </thead>
            <tbody>
                {order_items?.map((elm, index) => {
                    return (<tr key={index}>
                        <td style={{ padding: "5px 10px", fontSize: "12px" }}>{index + 1}</td>
                        <td style={{ padding: "5px 10px", fontSize: "12px" }}>{elm?.product_name}</td>
                        <td style={{ padding: "5px 10px", fontSize: "12px" }}>{elm?.qty}</td>
                    </tr>)
                })}
            </tbody>
        </Table>)
    };

    function displayZeroIfNegative(number) {
        if(!isNaN(number)) {
            if (number < 0) {
                return 0;
            } else {
                return number;
            }
        } else {
            return 0;
        };
    };

    // get current warehouse name
    const GetWarehouseName = (warehouse_id, url) => {
        if(url === "Check Stock Level") {
            const currentwarehouse = storedLevelAllReportList?.warehouse?.filter((elm) => elm?.id === warehouse_id)[0];
            return currentwarehouse?.warehouse_name;
        } else if(url === "Check Serviceable") {
            const currentwarehouse = RelatedCourierList?.warehouses?.filter((elm) => elm?.id === warehouse_id)[0];
            return currentwarehouse?.display_name;
        };
    };

    // Select payment mode
    const selectPaymentMode = (value) => {
        if(value === "") {
            setPaymentApprovalState({...PaymentApprovalState, payment_mode: value});
            CheckValid(value, { type: 'payment_mode', error: setErrorPaymentMode })
        } else {
            setPaymentApprovalState({...PaymentApprovalState, payment_mode: value});
            CheckValid(value, { type: 'payment_mode', error: setErrorPaymentMode })
        };
    };
    
    return (<Modal className={
        showModalNew?.title === 'Create Supplier'
            ? 'commonModal addSupplierModel'
            : (showModalNew?.title === 'Create New Box' || showModalNew?.title === 'Update New Box')
                ? 'commonModal addBoxModalNew'
                : showModalNew?.subtitle === 'Table'
                    ? 'commonModal addTableModal' : showModalNew?.subtitle === "Stock Inward Log Details" ?
                    'commonModal StockInwardLogDetailsTable': showModalNew?.subtitle === "Franchise Orders Tables" ? 'commonModal FranchiseOrdersTablesDetails' : showModalNew?.subtitle === "Table-ERP" ? 'commonModal FranchiseOrdersTablesDetailsTableERP' : showModalNew?.subtitle === "Table-ERP-General" ? "commonModal FranchiseOrdersTablesDetailsTableGeneralERP" : (showModalNew?.subtitle === "Check Stock Level" || showModalNew?.subtitle === "Check Serviceable") ? 'commonModal stocklevel_so_approval_modal' :
                    (showModalNew?.title === "Enter Payment Details" || showModalNew?.title === "Update Payment Details") ? 'commonModal QuotationPaymentApprovalcommonModal' : 'commonModal'
    }
        show={showModalNew?.open}
        onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title style={{ width: showModalNew?.title === "Are you sure?" ? "100%" : "" }}>{showModalNew?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {showModalNew?.modalType === "form" ? (
                <div className="formloginset">
                    {(showModalNew?.title === "Create New Box" || showModalNew?.title === "Update New Box") && (<>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Box Name<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={CreateBoxState?.box_name}
                                        onChange={(e) => setCreateBoxState({ ...CreateBoxState, box_name: e.target.value })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'box_name', error: setErrorbox_name })}
                                        onKeyDown={EmptySpaceFieldValid}
                                        placeholder="Enter Box Name"
                                    />
                                    {errorbox_name !== "" && <span className="error_medotory">{errorbox_name}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Box Length (cm)<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="inputBoxs"
                                        value={CreateBoxState?.box_length}
                                        onChange={(e) => setCreateBoxState({ ...CreateBoxState, box_length: e.target.value.replace(/^0/, "") })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'box_length', error: setErrorbox_length })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        placeholder="Enter Box Length"
                                    />
                                    {errorbox_length !== "" && <span className="error_medotory">{errorbox_length}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Box Width (cm)<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="inputBoxs"
                                        value={CreateBoxState?.box_width}
                                        onChange={(e) => setCreateBoxState({ ...CreateBoxState, box_width: e.target.value.replace(/^0/, "") })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'box_width', error: setErrorbox_width })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        placeholder="Enter Box Width"
                                    />
                                    {errorbox_width !== "" && <span className="error_medotory">{errorbox_width}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Box Height (cm)<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="inputBoxs"
                                        value={CreateBoxState?.box_height}
                                        onChange={(e) => setCreateBoxState({ ...CreateBoxState, box_height: e.target.value.replace(/^0/, "") })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'box_height', error: setErrorbox_height })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        placeholder="Enter Box Height"
                                    />
                                    {errorbox_height !== "" && <span className="error_medotory">{errorbox_height}</span>}
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Status</Form.Label>
                                    <div className="ToggleMainWrap">
                                        <CommonToggle
                                            valueToggle={CreateBoxState?.is_active}
                                            setValueToggle={() => setCreateBoxState({ ...CreateBoxState, is_active: !CreateBoxState?.is_active })}
                                            name={""}
                                        />
                                        {CreateBoxState?.is_active ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </>)}
                    {showModalNew?.title === "Create New Payment" && (<>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group >
                                    <Form.Label>Franchise Name<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Group className="mb-3">
                                        <div className="selectMultiselect" id="selectMultiselectBrand">
                                            <Select
                                                value={ApplicationState?.State}
                                                onChange={(e) => StateValueSelect(e)}
                                                isClearable
                                                options={optionsState}
                                                className="godown-select-container"
                                                classNamePrefix="godown-select"
                                                styles={customStyles}
                                                placeholder="Select Franchise"
                                            />
                                        </div>
                                        {franchise_name !== "" && <span className="error_medotory">{franchise_name}</span>}
                                    </Form.Group>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group >
                                    <Form.Label>Deposit Date</Form.Label>
                                    <div className="datepickField">
                                        <DatePicker
                                            selected={FromDate}
                                            startDate={FromDate}
                                            endDate={ToDate}
                                            onChange={(date) => DateStartGet(date)}
                                            dateFormat="yyyy/MM/dd"
                                            maxDate={today}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="Enter From Date"
                                            isClearable={FromDate !== "" ? true : false}
                                            autoComplete="off"
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Amount<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="inputBoxs"
                                        value={ApplicationState?.amount}
                                        onChange={(e) => setApplicationState({ ...ApplicationState, amount: e.target.value.replace(/^0/, "") })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'amount', error: setErroramount })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        placeholder="Enter Amount"
                                    />
                                    {amount !== "" && <span className="error_medotory">{amount}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Deposit Bank</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={ApplicationState?.deposit_bank}
                                        onChange={(e) => setApplicationState({ ...ApplicationState, deposit_bank: e.target.value })}
                                        // onKeyUp={(e) => CheckValid(e.target.value, { type: 'deposit_bank', error: setErrordeposit_bank })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e);
                                        }}
                                        placeholder="Enter Deposit Bank"
                                    />
                                    {/* {deposit_bank !== "" && <span className="error_medotory">{deposit_bank}</span>} */}
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Transaction Number<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="inputBoxs"
                                        value={ApplicationState?.transaction_number}
                                        onChange={(e) => setApplicationState({ ...ApplicationState, transaction_number: e.target.value})}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'transaction_number', error: setErrortransaction_number })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                        }}
                                        min={0}
                                        placeholder="Enter Transaction Number"
                                    />
                                    {transaction_number !== "" && <span className="error_medotory">{transaction_number}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-6 ">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Remarks</Form.Label>
                                    <Form.Control
                                        type="text-area"
                                        placeholder="Remark"
                                        // as='textarea'
                                        onChange={(e) => setApplicationState({ ...ApplicationState, remark: e.target.value })}
                                        onKeyDown={EmptySpaceFieldValid}
                                        // onKeyUp={(e) => CheckValid(e.target.value, { type: 'Remark', error: setRemarkError })}
                                    />
                                    {/* {remark !== "" && <span className="error_medotory">{remark}</span>} */}
                                </Form.Group>
                            </div>

                        </div>
                    </>)}

                    {(showModalNew?.title === "Enter Payment Details" || showModalNew?.title === "Update Payment Details") && (<>
                        <Form.Group className="mb-3" >
                            <Form.Label>TXN ID/Ref No<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                className="inputBoxs"
                                value={PaymentApprovalState?.transaction_number}
                                onChange={(e) => setPaymentApprovalState({...PaymentApprovalState, transaction_number: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'transaction_number', error: setErrortransaction_number })}
                                onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                placeholder="Enter TXN ID/Ref No."
                            />
                            {transaction_number !== "" && <span className="error_medotory">{transaction_number}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Payment Date<span className="mendatory_star">*</span></Form.Label>
                            <div className="datepickField">
                                <DatePicker 
                                    selected={PaymentApprovalState?.payment_date} 
                                    onChange={(date) => onChangePaymentDate(date)} 
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth 
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter Payment Date."
                                    autoComplete="off"
                                    isClearable={true}
                                />
                            </div>
                            {errorPaymentDate !== "" && <span className="error_medotory">{errorPaymentDate}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Upload Attachment<span className="mendatory_star">*</span></Form.Label>
                            {Object?.keys(PaymentApprovalState?.upload_attachment)?.length === 0 ? (<label id="UploadCmnBTNnew" className="btn head_cmn_btn w-100 UploadCmnBTN UploadCmnAttchmentBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                <i className="bi bi-upload"></i>
                                <input type="file" name={"Attachments"} id="fileInput" onChange={(e) => UploadImage(e, "Enter Payment Details")} accept=".png, .jpg, .pdf" />
                                Upload
                                {LoaderFile && (<Spinner />)}
                            </label>) : (<div className="uplaodedFilename w-100">
                                <i className="bi bi-file-earmark-text"></i>
                                {PaymentApprovalState?.upload_attachment?.original_name === undefined ? PaymentApprovalState?.upload_attachment?.name : PaymentApprovalState?.upload_attachment?.original_name}
                                <i className="bi bi-x-circle-fill"
                                    onClick={() => {
                                        setPaymentApprovalState({
                                            ...PaymentApprovalState,
                                            upload_attachment: {},
                                        });
                                    }}
                                ></i>
                            </div>)}
                            {errorUploadAttachment !== "" && <span className="error_medotory">{errorUploadAttachment}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Payment Mode<span className="mendatory_star">*</span></Form.Label>
                            <select className="form-control" value={PaymentApprovalState?.payment_mode} onChange={(e) => selectPaymentMode(e.target.value)} >
                                <option value={""}>Select Payment Mode</option>
                                <option value={"Upi"}>UPI</option>
                                <option value={"Netbanking"}>Netbanking</option>
                                <option value={"Wallet"}>Wallet</option>
                                <option value={"Paylater"}>Paylater</option>
                                <option value={"Card"}>Card</option>
                                <option value={"NEFT"}>NEFT</option>
                                <option value={"RTGS"}>RTGS</option>
                                <option value={"Cash"}>Cash</option>
                            </select>
                            {errorPaymentMode !== "" && <span className="error_medotory">{errorPaymentMode}</span>}
                        </Form.Group>
                    </>)}

                    {showModalNew?.title === "Update Stock" && (<>
                        <Form.Group className="mb-3" >
                            <Form.Label>Billing Number<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                className="inputBoxs"
                                value={formData?.billing_number}
                                onChange={(e) => setFormData({ ...formData, billing_number: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'billing_number', error: setErrorBillingNumber })}
                                onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                placeholder="Enter Billing Number"
                            />
                            {errorBillingNumber !== "" && <span className="error_medotory">{errorBillingNumber}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Attachment Proof</Form.Label>
                            {formData?.attachment_file?.file_id === "" ? (<label id="UploadCmnBTN" className="btn head_cmn_btn w-100 UploadCmnBTN UploadCmnAttchmentBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                <i className="bi bi-upload"></i>
                                <input type="file" name={"Attachments"} id="fileInput" onChange={(e) => UploadImage(e, "Update Stock")} accept=".png, .jpg, .pdf" />
                                Upload Attachment Proof
                                {LoaderFile && (<Spinner />)}
                            </label>) : (<div className="uplaodedFilename w-100">
                                <i className="bi bi-file-earmark-text"></i>
                                {formData?.attachment_file?.file_name}
                                <i className="bi bi-x-circle-fill"
                                    onClick={() => {
                                        setFormData({
                                            ...formData,
                                            attachment_file: {
                                                ...formData?.attachment_file,
                                                file_name: "",
                                                file_id: "",
                                            },
                                        });
                                    }}
                                ></i>
                            </div>)}
                        </Form.Group>
                    </>)}

                    {showModalNew?.title === "Get ERP Invoice" && (<>
                        <Form.Group className="mb-3" >
                            <Form.Label>Invoice Number<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                className="inputBoxs"
                                value={invoiceNumber}
                                onChange={(e) => setinvoiceNumber(e.target.value.trim().toLocaleUpperCase())}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'invoiceNumber', error: errorSetinvoiceNumber })}
                                onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                placeholder="Enter Invoice Number"
                            />
                            {errorInvoiceNumber !== "" && <span className="error_medotory">{errorInvoiceNumber}</span>}
                        </Form.Group>
                    </>)}
                    
                    {showModalNew?.subtitle === "Table" && (<>
                        <DataTableFilter
                            searchType={"Franchise Code"}
                            filterType={"SummaryDetails"}
                            userSearch={userSearch}
                            setUserSearch={setUserSearch}
                            userType={userType}
                            setUserType={setUserType}
                            userLimit={userLimit}
                            setUserLimit={setUserLimit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            SelectUserTypeFnct={SelectUserTypeFnct}
                            buttonvisible={false}
                            searchdisable={false}
                        />
                        <div className="tableView">
                            <Scrollbars
                                style={{ height: parseInt(DebitCreditNoteReport.length) === DebitCreditNoteReport.length ? "calc(100vh - 260px)" : DebitCreditNoteReport.length > 10 ? "calc(100vh - 260px)" : "calc(100vh - 206px)", padding: "50px" }}
                                renderView={props => <div {...props} className="view" />}
                                
                                className="ScrollbarsSidebar"
                            >
                                <Table id="SummaryDetails" bordered  style={{ display: "none" }} >
                                    <thead>
                                        <tr>
                                            <th>SNo</th>
                                            <th>Franchise Code</th>
                                            <th>Name</th>
                                            <th>Credit Amount</th>
                                            <th>Debit  Amount</th>
                                            <th>Credit/Debit Date</th>
                                            <th>Adjusted By</th>
                                            <th>Reasons</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DebitCreditNoteReport?.map((item, index) => {
                                            return (<React.Fragment key={index}>
                                                <tr key={index}>
                                                    <td>
                                                        {GetCurrentTableSRNo(index, DebitCreditNoteReportList?.pagination?.current_page, DebitCreditNoteReportList?.pagination?.record_limit)}
                                                    </td>
                                                    <td>{GetFranchiseData(item?.franchise_id, DebitCreditNoteReportList)?.FranchiseCode}</td>
                                                    <td>{GetFranchiseData(item?.franchise_id, DebitCreditNoteReportList)?.FranchiseName}</td>
                                                    <td>{AmountNumberFormat(item.credit_amt)}</td>
                                                    <td>{AmountNumberFormat(item.debit_amt)}</td>
                                                    <td>{CommonDateTime(item.deposit_date, "LL", "show")}</td>
                                                    <td>-</td>
                                                    <td>{item.reason === null ? "-" : item.reason}</td>
                                                    <td>{item.remarks === null ? "-" : item.remarks}</td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                        {DebitCreditNoteReport?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Debit/Credit Notes Report list not found</td></tr>}
                                    </tbody>
                                </Table>
                                <Table bordered  >
                                    <thead>
                                        <tr>
                                            <th>SNo</th>
                                            <th>Franchise Code</th>
                                            <th>Name</th>
                                            <th>Credit Amount</th>
                                            <th>Debit  Amount</th>
                                            <th>Credit/Debit Date</th>
                                            <th>Adjusted By</th>
                                            <th>Reasons</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DebitCreditNoteReport?.map((item, index) => {
                                            return (<React.Fragment key={index}>
                                                <tr key={index}>
                                                    <td>
                                                        {GetCurrentTableSRNo(index, DebitCreditNoteReportList?.pagination?.current_page, DebitCreditNoteReportList?.pagination?.record_limit)}
                                                    </td>
                                                    <td>{GetFranchiseData(item?.franchise_id, DebitCreditNoteReportList)?.FranchiseCode}</td>
                                                    <td>{GetFranchiseData(item?.franchise_id, DebitCreditNoteReportList)?.FranchiseName}</td>
                                                    <td>{AmountNumberFormat(item.credit_amt)}</td>
                                                    <td>{AmountNumberFormat(item.debit_amt)}</td>
                                                    <td>{CommonDateTime(item.deposit_date, "LL", "show")}</td>
                                                    <td>{GetUserData(item?.author)?.UserName}</td>
                                                    <td>{item.reason === null ? "-" : item.reason}</td>
                                                    <td>{item.remarks === null ? "-" : item.remarks}</td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                        {DebitCreditNoteReport?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Debit/Credit Notes Report list not found</td></tr>}
                                        <tr className="totaltd border">
                                            <th colSpan={3} className="text-center">Page total</th>
                                            <td>{AmountNumberFormat(TotalTableCount(DebitCreditNoteReport, "credit_amt"))}</td>
                                            <td>{AmountNumberFormat(TotalTableCount(DebitCreditNoteReport, "debit_amt"))}</td>
                                        </tr>
                                        <tr className="totaltd border">
                                            <th colSpan={3} className="text-center">Grand total</th>
                                            <td>{AmountNumberFormat(DebitCreditNoteReportList?.grand_credit)}</td>
                                            <td>{AmountNumberFormat(DebitCreditNoteReportList?.grand_debit)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Scrollbars>
                            {DebitCreditNoteReportList?.pagination?.total_records > 10 && (<CommonPagination
                                currentPage={currentPage}
                                paginationLength={DebitCreditNoteReportList?.pagination}
                                currentFunction={currentFunction}
                            />)}
                        </div>
                    </>)}

                    {showModalNew?.subtitle === "Table-ERP" && (<>
                        <DataTableFilter
                            searchType={"Invoice Number"}
                            filterType={"ErpSummaryDetails"}
                            userSearch={userSearch}
                            setUserSearch={setUserSearch}
                            userType={userType}
                            setUserType={setUserType}
                            userLimit={userLimit}
                            setUserLimit={setUserLimit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            SelectUserTypeFnct={SelectUserTypeFnct}
                            buttonvisible={false}
                            searchdisable={true}
                        />
                        <div className="tableView">
                            <Scrollbars
                                style={{ height: parseInt(ErpLedgerReportList?.list.length) === ErpLedgerReportList?.list.length ? "calc(100vh - 260px)" : ErpLedgerReportList?.list.length > 10 ? "calc(100vh - 260px)" : "calc(100vh - 206px)", padding: "50px" }}
                                renderView={props => <div {...props} className="view" />}
                                
                                className="ScrollbarsSidebar"
                            >
                                <Table id="ErpSummaryDetails" bordered  >
                                    <thead>
                                        <tr>
                                            <th>SNo</th>
                                            <th>Franchise Code</th>
                                            <th>Name</th>
                                            <th>Invoice Number</th>
                                            <th>Credit Amount</th>
                                            <th>Outstanding Amount</th>
                                            <th>Posting Date</th>
                                            <th>Credit/Debit Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ErpLedgerReportList?.list?.map((item, index) => {
                                            return (<React.Fragment key={index}>
                                                <tr key={index}>
                                                    <td>
                                                        {GetCurrentTableSRNo(index, ErpLedgerReportList?.pagination?.current_page, ErpLedgerReportList?.pagination?.record_limit)}
                                                    </td>
                                                    <td>{GetFranchiseData(item?.franchise_id, ErpLedgerReportList)?.FranchiseCode}</td>
                                                    <td>{GetFranchiseData(item?.franchise_id, ErpLedgerReportList)?.FranchiseName}</td>
                                                    <td>{item.invoice_num}</td>
                                                    <td>{AmountNumberFormat(item.credit_note)}</td>
                                                    <td>{AmountNumberFormat(item.outstanding_amt)}</td>
                                                    <td>{CommonDateTime(item.posting_date, "LL", "show")}</td>
                                                    <td>{CommonDateTime(item.deposit_date, "LL", "show")}</td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                        {ErpLedgerReportList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">ERP ledger report list not found</td></tr>}
                                        <tr className="totaltd border">
                                            <th colSpan={4} className="text-center">Page total</th>
                                            <td>{AmountNumberFormat(TotalTableCount(ErpLedgerReportList?.list, "credit_note"))}</td>
                                            <td>{AmountNumberFormat(TotalTableCount(ErpLedgerReportList?.list, "outstanding_amt"))}</td>
                                        </tr>
                                        <tr className="totaltd border">
                                            <th colSpan={4} className="text-center">Grand total</th>
                                            <td>{AmountNumberFormat(ErpLedgerReportList?.grand_credit)}</td>
                                            <td>{AmountNumberFormat(ErpLedgerReportList?.grand_debit)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Scrollbars>
                            {ErpLedgerReportList?.pagination?.total_records > 10 && (<CommonPagination
                                currentPage={currentPage}
                                paginationLength={ErpLedgerReportList?.pagination}
                                currentFunction={currentFunction}
                            />)}
                        </div>
                    </>)}

                    {showModalNew?.subtitle === "Table-ERP-General" && (<>
                        <DataTableFilter
                            searchType={"Invoice Number"}
                            filterType={"ErpSummaryGeneralDetails"}
                            userSearch={userSearch}
                            setUserSearch={setUserSearch}
                            userType={userType}
                            setUserType={setUserType}
                            userLimit={userLimit}
                            setUserLimit={setUserLimit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            SelectUserTypeFnct={SelectUserTypeFnct}
                            buttonvisible={false}
                            searchdisable={true}
                            downloadPdf={true}
                        />
                        <div className="date_time_stamp previous_year_opening_wrapper">
                            <label>Previous Year Opening :</label> {AmountNumberFormat(showModalNew?.Data?.previous_year_opening)}
                        </div>
                        <div className="tableView">
                            <Scrollbars
                                style={{ height: parseInt(ErpGeneralLedgerReportList?.list.length) === ErpGeneralLedgerReportList?.list.length ? "calc(100vh - 260px)" : ErpGeneralLedgerReportList?.list.length > 10 ? "calc(100vh - 260px)" : "calc(100vh - 206px)", padding: "50px" }}
                                renderView={props => <div {...props} className="view" />}
                                
                                className="ScrollbarsSidebar"
                            >
                                <Table id="ErpSummaryGeneralDetails" bordered  >
                                    <thead>
                                        <tr>
                                            <th>SNo</th>
                                            <th>Franchise Code</th>
                                            <th>Name</th>
                                            <th>Invoice Number</th>
                                            <th>Credit Amount</th>
                                            <th>Debit Amount</th>
                                            <th>Outstanding Amount</th>
                                            <th>Voucher Type</th>
                                            <th>Voucher Subtype</th>
                                            <th>Posting Date</th>
                                            <th>Credit/Debit Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ErpGeneralLedgerReportList?.list?.map((item, index) => {
                                            return (<React.Fragment key={index}>
                                                <tr key={index}>
                                                    <td>
                                                        {GetCurrentTableSRNo(index, ErpGeneralLedgerReportList?.pagination?.current_page, ErpGeneralLedgerReportList?.pagination?.record_limit)}
                                                    </td>
                                                    <td>{GetFranchiseData(item?.franchise_id, ErpGeneralLedgerReportList)?.FranchiseCode}</td>
                                                    <td>{GetFranchiseData(item?.franchise_id, ErpGeneralLedgerReportList)?.FranchiseName}</td>
                                                    <td>{item.invoice_num}</td>
                                                    <td>{AmountNumberFormat(item.credit)}</td>
                                                    <td>{AmountNumberFormat(item.debit)}</td>
                                                    <td>{AmountNumberFormat(item.balance)}</td>
                                                    <td>{item?.voucher_type}</td>
                                                    <td>{item?.voucher_subtype}</td>
                                                    <td>{CommonDateTime(item.posting_date, "LL", "show")}</td>
                                                    <td>
                                                        {/* {(item.deposit_date === null || item.deposit_date === undefined) ? "-" : CommonDateTime(item.deposit_date, "LL", "show")} */}
                                                        {CommonDateTime(showModalNew?.last_update_on_date, "LL", "show")}
                                                    </td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                        {ErpGeneralLedgerReportList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">ERP ledger report list not found</td></tr>}
                                        <tr className="totaltd border">
                                            <td colSpan={4} style={{ backgroundColor: "#f6f6f6" }} className="text-center">Page total</td>
                                            <td>{AmountNumberFormat(TotalTableCount(ErpGeneralLedgerReportList?.list, "credit"))}</td>
                                            <td>{AmountNumberFormat(TotalTableCount(ErpGeneralLedgerReportList?.list, "debit"))}</td>
                                            <td>{AmountNumberFormat(ErpGeneralLedgerReportList?.list[ErpGeneralLedgerReportList?.list?.length - 1]?.balance)}</td>
                                        </tr>
                                        <tr className="totaltd border">
                                            <td colSpan={4} style={{ backgroundColor: "#f6f6f6" }} className="text-center">Grand total</td>
                                            <td>{AmountNumberFormat(ErpGeneralLedgerReportList?.grand_credit)}</td>
                                            <td>{AmountNumberFormat(ErpGeneralLedgerReportList?.grand_debit)}</td>
                                            <td>{AmountNumberFormat(ErpGeneralLedgerReportList?.list[ErpGeneralLedgerReportList?.list?.length - 1]?.balance)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Scrollbars>
                            {ErpGeneralLedgerReportList?.pagination?.total_records > 10 && (<CommonPagination
                                currentPage={currentPage}
                                paginationLength={ErpGeneralLedgerReportList?.pagination}
                                currentFunction={currentFunction}
                            />)}
                        </div>
                    </>)}

                    {showModalNew?.subtitle === "Stock Inward Log Details" && (<>
                        <DataTableFilter
                            searchType={"Frame Number"}
                            filterType={"Stock Inward Log Details"}
                            userSearch={userSearch}
                            setUserSearch={setUserSearch}
                            userType={userType}
                            setUserType={setUserType}
                            userLimit={userLimit}
                            setUserLimit={setUserLimit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            SelectUserTypeFnct={SelectUserTypeFnct}
                            buttonvisible={false}
                            searchdisable={true}
                        />
                        <div className="tableView">
                            <Scrollbars
                                style={{ height: parseInt(DebitCreditNoteReport.length) === DebitCreditNoteReport.length ? "calc(100vh - 260px)" : DebitCreditNoteReport.length > 10 ? "calc(100vh - 260px)" : "calc(100vh - 206px)", padding: "50px" }}
                                renderView={props => <div {...props} className="view" />}
                                
                                className="ScrollbarsSidebar"
                            >
                                <Table id="StockInwardLogDetails" bordered  style={{ display: "none" }} >
                                    <thead>
                                        <tr>
                                            <th>SNo</th>
                                            <th>Txn ID</th>
                                            <th>Frame Number</th>
                                            <th>Warehouse Name</th>
                                            <th>Added On</th>
                                            <th>Sold On</th>
                                            <th>Order No</th>
                                            <th>Billing No</th>
                                            <th>Status</th>
                                            <th>User Type</th>
                                            <th>Battery Number</th>
                                            <th>Motor Number</th>
                                            <th>Charger Number</th>
                                            <th>Type Of Transaction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {storedDetailsLogReportList?.list?.map((item, index) => {
                                            return (<React.Fragment key={index}>
                                                <tr key={index}>
                                                    <td>
                                                        {GetCurrentTableSRNo(index, storedDetailsLogReportList?.pagination?.current_page, storedDetailsLogReportList?.pagination?.record_limit)}
                                                    </td>
                                                    <td>{showModalNew?.Txn_ID}</td>
                                                    <td>{item?.frame_number}</td>
                                                    <td>{GetWareHouseCode(item?.warehouse_id)}</td>
                                                    <td>{CommonDateTime(item.created_at, "LL", "show")}</td>
                                                    <td>{CommonDateTime(item.order_at, "LL", "show")}</td>
                                                    <td>
                                                        {item?.order_code === null ? "-" : item?.order_code}
                                                    </td>
                                                    <td>{(item?.bill_no === null || item?.bill_no === "None") ? "-" : item?.bill_no}</td>
                                                    <td>{item?.status === "UNSOLD" ? <Badge bg="danger">Un-Sold</Badge> : <Badge bg="success">Sold</Badge>}</td>
                                                    <td>{item?.order_type === null ? "-" : item?.order_type}</td>
                                                    <td>{(item?.battery_number === null || item?.battery_number === "") ? "-" : item?.battery_number}</td>
                                                    <td>{(item?.motor_number === null || item?.motor_number === "") ? "-" : item?.motor_number}</td>
                                                    <td>{(item?.charger_number === null || item?.charger_number === "") ? "-" : item?.charger_number}</td>
                                                    <td>{item?.type_of_transaction === null ? "-" : item?.type_of_transaction}</td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                    </tbody>
                                </Table>
                                <Table bordered  >
                                    <thead>
                                        <tr>
                                            <th>SNo</th>
                                            <th>Txn ID</th>
                                            <th>Frame Number</th>
                                            <th>Warehouse Name</th>
                                            <th>Added On</th>
                                            <th>Sold On</th>
                                            <th>Order No</th>
                                            <th>Billing No</th>
                                            <th>Status</th>
                                            <th>User Type</th>
                                            <th>Battery Number</th>
                                            <th>Motor Number</th>
                                            <th>Charger Number</th>
                                            <th>Type Of Transaction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {storedDetailsLogReportList?.list?.map((item, index) => {
                                            return (<React.Fragment key={index}>
                                                <tr key={index}>
                                                    <td>
                                                        {GetCurrentTableSRNo(index, storedDetailsLogReportList?.pagination?.current_page, storedDetailsLogReportList?.pagination?.record_limit)}
                                                    </td>
                                                    <td>{showModalNew?.Txn_ID}</td>
                                                    <td>{item?.frame_number}</td>
                                                    <td>{GetWareHouseCode(item?.warehouse_id)}</td>
                                                    <td>{CommonDateTime(item.created_at, "LL", "show")}</td>
                                                    <td>{CommonDateTime(item.order_at, "LL", "show")}</td>
                                                    <td>
                                                        {item?.order_code === null ? "-" : item?.order_code}
                                                    </td>
                                                    <td>{(item?.bill_no === null || item?.bill_no === "None") ? "-" : item?.bill_no}</td>
                                                    <td>{item?.status === "UNSOLD" ? <Badge bg="danger">Un-Sold</Badge> : <Badge bg="success">Sold</Badge>}</td>
                                                    <td>{item?.order_type === null ? "-" : item?.order_type}</td>
                                                    <td>{(item?.battery_number === null || item?.battery_number === "") ? "-" : item?.battery_number}</td>
                                                    <td>{(item?.motor_number === null || item?.motor_number === "") ? "-" : item?.motor_number}</td>
                                                    <td>{(item?.charger_number === null || item?.charger_number === "") ? "-" : item?.charger_number}</td>
                                                    <td>{item?.type_of_transaction === null ? "-" : item?.type_of_transaction}</td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                        {storedDetailsLogReportList?.list?.length === 0 && (<tr><td colSpan={14} style={{ textAlign: "center" }}>Stock Inward Log Details list not found</td></tr>)}
                                    </tbody>
                                </Table>
                            </Scrollbars>
                            {storedDetailsLogReportList?.pagination?.total_records > 10 && (<CommonPagination
                                currentPage={currentPage}
                                paginationLength={storedDetailsLogReportList?.pagination}
                                currentFunction={currentFunction}
                            />)}
                        </div>
                    </>)}

                    {showModalNew?.subtitle === "Franchise Orders Tables" && (<div className="tableView">
                        {(commonMainLoader?.url === "get_franchise_order" && commonMainLoader?.loader) ? (<div>Please Wait...</div>) : (
                            <Table bordered  >
                                <thead>
                                    <tr>
                                        <th>SNo</th>
                                        <th>Order Code</th>
                                        <th>Invoice No</th>
                                        <th>Products</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {franchiseOrderLatestThreeListl?.orders?.length > 0 ? franchiseOrderLatestThreeListl?.orders?.map((item, index) => {
                                        return (<tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item?.order_code}</td>
                                            <td>{item?.invoice_code === null ? "-" : item?.invoice_code}</td>
                                            <td>{GetOrderProductName(item?.order_items)}</td>
                                            <td>{CommonDateTime((item?.order_status === "AC_APPROVED" || item?.order_status === "ST_APPROVED") ? item?.invoice_at : item?.order_status === "DISPATCHED" ? item?.dispatch_date : item?.order_status === "DELIVERED" ? item?.delivered_date : item?.created_at, "LL", "show")}</td>
                                            <td>{CommonStatus(item?.order_status)}</td>
                                        </tr>)
                                    }) : (<tr>
                                        <td colSpan={6} style={{ textAlign: "center" }}>No Records Found</td>
                                    </tr>)}
                                </tbody>
                        </Table>)}
                    </div>)}

                    {showModalNew?.subtitle === "Check Stock Level" && (<div className="tableView">
                        <Table bordered  >
                            <thead>
                                <tr>
                                    <th>SNo</th>
                                    <th>Warehouse Name</th>
                                    <th>Invoiceable Qty</th>
                                    <th>Balance Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storedLevelAllReportList?.list?.length > 0 ? storedLevelAllReportList?.list?.map((item, index) => {
                                    return (<tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>{item?.warehouse_name}</td>
                                        <td>{displayZeroIfNegative(item?.inward_qty - item?.transfer_qty - item?.invoiceable_qty)}</td>
                                        <td>{item?.balance_qty}</td>
                                    </tr>)
                                }) : (<tr>
                                    <td colSpan={6} style={{ textAlign: "center" }}>No Records Found</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>)}


                    {showModalNew?.subtitle === "Check Serviceable" && (<div className="tableView Check_Serviceable_tableView">
                        <Table bordered  >
                            <thead>
                                <tr>
                                    <th style={{ width: "50%", textAlign: "center" }}>ODA</th>
                                    <th style={{ width: "50%", textAlign: "center" }}>NORMAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {RelatedCourierList?.list?.filter((elm) => {
                                            if(elm?.is_odi) {
                                                return elm;
                                            }
                                        })?.length > 0 ? (<Table bordered  >
                                            <thead>
                                                <tr>
                                                    <th>Warehouse</th>
                                                    <th>Courier</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {RelatedCourierList?.list?.filter((elm) => {
                                                    if(elm?.is_odi) {
                                                        return elm;
                                                    }
                                                }).map((elm, index) => {
                                                    return(<tr key={index}>
                                                        <td>{GetWarehouseName(elm?.warehouse_id, "Check Serviceable")}</td>
                                                        <td>{elm?.courier_name}</td>
                                                    </tr>)
                                                })} 
                                            </tbody>
                                        </Table>) : (<div  style={{ textAlign: "center" }}>
                                            No Records Found
                                        </div>)}
                                    </td>
                                    <td>
                                        {RelatedCourierList?.list?.filter((elm) => {
                                            if(!elm?.is_odi) {
                                                return elm;
                                            }
                                        })?.length > 0 ? (<Table bordered  >
                                            <thead>
                                                <tr>
                                                    <th>Warehouse</th>
                                                    <th>Courier</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {RelatedCourierList?.list?.filter((elm) => {
                                                    if(!elm?.is_odi) {
                                                        return elm;
                                                    }
                                                }).map((elm, index) => {
                                                    return(<tr key={index}>
                                                        <td>{GetWarehouseName(elm?.warehouse_id, "Check Serviceable")}</td>
                                                        <td>{elm?.courier_name}</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </Table>) : (<div style={{ textAlign: "center" }}>
                                            No Records Found
                                        </div>)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>)}
                </div>
            ) : (<>
                {showModalNew?.modalType === "not form store details" ? (
                    <div className="notformsection">
                        <p>Do you really want to add the <b>product</b> to the product details report?</p>
                    </div>
                ) : showModalNew?.modalType === "not form FAQ" ? (
                    <div className="notformsection">
                        <div dangerouslySetInnerHTML={{ __html: showModalNew?.description }}></div>
                    </div>
                ) : showModalNew?.modalType === "Ticket-Verify" ? (
                    <div className="tickets_verifications_wraps">
                        {showModalNew?.title === "Ticket Verification Successful !" ? (<ul>
                            <li><label>Customer Name</label>: {showModalNew?.Data?.data?.cf?.cf_customer_name === null ? ((showModalNew?.Data?.data?.contact?.firstName === null ? "" : showModalNew?.Data?.data?.contact?.firstName) + " " + (showModalNew?.Data?.data?.contact?.lastName === null ? "" : showModalNew?.Data?.data?.contact?.lastName)) : showModalNew?.Data?.data?.cf?.cf_customer_name}</li>                            
                            <li><label>Contact Number</label>: {showModalNew?.Data?.data?.cf?.cf_customer_contact_no === null ? (showModalNew?.Data?.data?.contact?.mobile || showModalNew?.Data?.data?.contact?.phone) : showModalNew?.Data?.data?.cf?.cf_customer_contact_no}</li>
                            <li><label>Ticket Creation Date & Time</label>: {CommonDateTime(new Date(showModalNew?.Data?.data?.createdTime), "YYYY-MM-DD, HH:MM a ", "hide")}</li>
                        </ul>) : (<p>Could you please verify the ticket number once again? Do you wish to proceed with these ticket number?</p>)}
                    </div>
                ) : (
                    <div className="notformsection">
                        {showModalNew?.subtitle === "Logout" && (<i className="bi bi-box-arrow-right logoutIconbtn"></i>)}
                        <p dangerouslySetInnerHTML={{ __html: showModalNew?.description }}></p>
                    </div>
                )}
            </>)}
        </Modal.Body>
        {(showModalNew?.subtitle !== "Table" && showModalNew?.subtitle !== "Table-ERP" && showModalNew?.subtitle !== "Table-ERP-General" && showModalNew?.subtitle !== "Stock Inward Log Details") && <Modal.Footer>
            <Button variant="secondary" onClick={(showModalNew?.subtitle === "Ticket-Verify" && showModalNew?.title === "Ticket Verification Fail") ? handleCloseTickets : handleClose}>
                Close
            </Button>
            {showModalNew?.button !== "" &&(<Button disabled={loader || LoaderFile} variant="primary" className="addbtncmn" onClick={CommonFunction}>
                {showModalNew?.button}
            </Button>)}
        </Modal.Footer>}
        {showLoader && (<CommonLoader />)}
    </Modal>)
}

export default CommonewModel;